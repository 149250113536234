import { formatarSelect } from "@func/components";
import { msgs } from "@func/jsx";
import api from 'axios';
import { config, validarRetorno } from '../axios';
import { apiBerths } from "./berths";

const apiSelect = {
	states: {
		get: async () => {
			return await api.get(`/select/states`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	countries: {
		get: async () => {
			return await api.get(`/select/countries`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	vesselStatus: {
		get: async () => {
			return await api.get(`/select/vessel-status`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	operationsType: {
		get: async () => {
			return await api.get(`/select/operations-type`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	companies: {
		get: async () => {
			return await api.get(`/select/companies`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	ports: {
		get: async () => {
			return await api.get(`/select/ports`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	vessels: {
		get: async () => {
			return await api.get(`/select/vessels`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	cargos: {
		get: async () => {
			return await api.get(`/select/cargos`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	berths: {
		get: async () => {
			return await api.get(`/select/berths`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
};

export { apiSelect };

/*
** Estados
** ================================================== */
export const apiStatesGeral = async ({setLoadingGetEstados, setStateEstados, showMsg = true}) => {
	setLoadingGetEstados(true);
	const ret = await apiSelect.states.get();
	if(ret.status === 200) {
		setStateEstados(formatarSelect({dados: ret.data, keyValue: 'code'}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetEstados(false);
	return ret;
}

/*
** Paises
** ================================================== */
export const apiCountriesGeral = async ({setLoadingGetPaises, setStatePaises, showMsg = true}) => {
	setLoadingGetPaises(true);
	const ret = await apiSelect.countries.get();
	if(ret.status === 200) {
		setStatePaises(formatarSelect({dados: ret.data}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetPaises(false);
	return ret;
}

/*
** Tipos de operações
** ================================================== */
export const apiOperationsTypeGeral = async ({setLoadingGetTiposOperacoes, setStateTiposOperacoes, showMsg = true}) => {
	setLoadingGetTiposOperacoes(true);
	const ret = await apiSelect.operationsType.get();
	if(ret.status === 200) {
		setStateTiposOperacoes(formatarSelect({dados: ret.data, keyValue: 'code'}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetTiposOperacoes(false);
	return ret;
}

/*
** Empresas
** ================================================== */
export const apiCompaniesGeral = async ({setLoadingGetEmpresas, setStateEmpresas, showMsg = true}) => {
	setLoadingGetEmpresas(true);
	const ret = await apiSelect.companies.get();
	if(ret.status === 200) {
		setStateEmpresas(formatarSelect({dados: ret.data}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetEmpresas(false);
	return ret;
}

/*
** Portos
** ================================================== */
export const apiPortsGeral = async ({setLoadingGetPortos, setStatePortos, showMsg = true}) => {
	setLoadingGetPortos(true);
	const ret = await apiSelect.ports.get();
	if(ret.status === 200) {
		setStatePortos(formatarSelect({dados: ret.data}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetPortos(false);
	return ret;
}

/*
** Navios
** ================================================== */
export const apiVesselsGeral = async ({setLoadingGetNavios, setStateNavios, showMsg = true}) => {
	setLoadingGetNavios(true);
	const ret = await apiSelect.vessels.get();
	if(ret.status === 200) {
		setStateNavios(formatarSelect({dados: ret.data}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetNavios(false);
	return ret;
}

/*
** Cargos
** ================================================== */
export const apiCargosGeral = async ({setLoadingGetCargos, setStateCargos, showMsg = true}) => {
	setLoadingGetCargos(true);
	const ret = await apiSelect.cargos.get();
	if(ret.status === 200) {
		setStateCargos(formatarSelect({dados: ret.data}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetCargos(false);
	return ret;
}

/*
** Cargos
** ================================================== */
export const apiBerthsGeral = async ({idPorto, setLoadingGetBercos, setStateBercos, showMsg = true}) => {
	setLoadingGetBercos(true);
	const ret = await apiBerths.all.get({id_port: idPorto});
	if(ret.status === 200) {
		setStateBercos(formatarSelect({dados: ret.data}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetBercos(false);
	return ret;
}
