import React from 'react';

// css
import './BemVindo.css';

// Componentes
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";

// Imagens
import imgLogo from '@img/logo-texto-cargonavegroup.png';
import { EMAIL_CONTATO, URL } from "@config/config";

const BemVindo = props => {

	return (
		<>
			<div className={`pg-Berco`}>
				<div>
					<div className="base">
						<div className="base-content">
							<h1>Bem Vindo</h1>
							<div><img src={imgLogo} alt="logo-cargonave" /></div>
							<h3>Links úteis</h3>
							<ul className="lista">
								<li><a href="https://www.cargonavegroup.com/" target="_blank" rel="noreferrer">Site Cargonave Group</a></li>
								<li><a href="https://www.cargonavegroup.com/contacts" target="_blank" rel="noreferrer">Contato</a></li>
								<li><a href="https://www.cargonavegroup.com/services" target="_blank" rel="noreferrer">Serviços</a></li>
							</ul>

							<h3>Aplicativos</h3>
							<ul className="lista">
								<li><a href="https://play.google.com/store/apps/details?id=com.cargonavegroup.app" target="_blank" rel="noreferrer">Aplicativo para iOS</a></li>
								<li><a href="https://apps.apple.com/us/app/cargonave/id6472299037" target="_blank" rel="noreferrer">Aplicativo para Android</a></li>
							</ul>

							<h3>Suporte</h3>
							<ul className="lista">
								<li><a href={`${URL}/personal-data-deletion-request`} target="_blank" rel="noreferrer">Deletar minha conta</a></li>
								<li><a href={`mailto:${EMAIL_CONTATO}`} target="_blank" rel="noreferrer">{EMAIL_CONTATO}</a></li>
							</ul>

						</div>
					</div>
				</div>

			</div>
		</>
	);
};

export { BemVindo };
