import { NOMES_CAMPOS } from "@config/objetos";
import React from 'react';
import { toast as msgToast } from 'react-toastify';
import passwordValidator from 'password-validator';

/*
** Funções de validação
** ================================================== */

export const nomeCampo = campo => {
	var campos = NOMES_CAMPOS;

	if(campos[campo]) {
		return campos[campo];
	}

	return campo;
}

export const camposInvalidos = (keysCamposObj, arrCamposValidar = '*', nomesNovosObj = {}, mensagem = 'Preencha todos os campos obrigatórios:', exibirCampos = true) => {
	if(mensagem === null) {
		mensagem = 'Preencha todos os campos obrigatórios:';
	}
	var retorno = {};
	var msgsAgrupadas = [];

	if(typeof(keysCamposObj) === 'object') {
		if(arrCamposValidar === '*') {
			Object.keys(keysCamposObj).map((val, key) => {
				var campo = keysCamposObj[val];
				if(!campo || campo === '') {
					retorno[val] = true;
					var label = '';
					if(nomesNovosObj[val]) {
						label = nomesNovosObj[val];
					} else {
						label = nomeCampo(val)
					}
					msgsAgrupadas.push(<div key={key} className={'msgs-lista-item msgs-lista-item-error'}>{label}</div>);
				}
			});
		} else {

			arrCamposValidar.map((val, key) => {
				var campo = keysCamposObj[val];
				if(!campo || campo === '') {
					retorno[val] = true;
					var label = '';
					if(nomesNovosObj[val]) {
						label = nomesNovosObj[val];
					} else {
						label = nomeCampo(val)
					}
					msgsAgrupadas.push(<div key={key} className={'msgs-lista-item msgs-lista-item-error'}>{label}</div>);
				}
			});
		}
	}

	if(Object.keys(retorno).length > 0) {
		if(mensagem !== false) {
			if(exibirCampos) {
				msgToast.error(<div><div>{mensagem}</div><div className="msgs-lista-box msgs-lista">{msgsAgrupadas}</div></div>);
			} else {
				msgToast.error(mensagem);
			}
		}
		return retorno;
	}
	return false;
}

export const validarEmail = (email, mensagem = 'O e-mail inserido é inválido.') => {
	if(!email || email === '') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	var valido = re.test(email);
	if(!valido) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
	}
	return valido;
};

export const validarCpf = (cpf, mensagem = 'O CPF inserido é inválido.') => {
	if(!cpf || cpf === '') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	cpf = cpf.trim();
	cpf = cpf.replace(/\./g, '');
	cpf = cpf.replace('-', '');
	cpf = cpf.replace('/', '');
	cpf = cpf.split('');

	let v1 = 0;
	let v2 = 0;
	let aux = false;

	for (let i = 1; cpf.length > i; i++) {
		if (cpf[i - 1] != cpf[i]) {
			aux = true;
		}
	}

	if (aux == false) {
		return false;
	}

	for (let i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
		v1 += cpf[i] * p;
	}

	v1 = ((v1 * 10) % 11);

	if (v1 == 10) {
		v1 = 0;
	}

	if (v1 != cpf[9]) {
		return false;
	}

	for (let i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
		v2 += cpf[i] * p;
	}

	v2 = ((v2 * 10) % 11);

	if (v2 == 10) {
		v2 = 0;
	}

	if (v2 != cpf[10]) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	} else {
		return true;
	}
}

export const validarCnpj = (cnpj, mensagem = 'O CNPJ inserido é inválido.') => {
	if(!cnpj || cnpj === '') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	cnpj = cnpj.trim();
	cnpj = cnpj.replace(/\./g, '');
	cnpj = cnpj.replace('-', '');
	cnpj = cnpj.replace('/', '');
	cnpj = cnpj.split('');

	let v1 = 0;
	let v2 = 0;
	let aux = false;

	for (let i = 1; cnpj.length > i; i++) {
		if (cnpj[i - 1] != cnpj[i]) {
			aux = true;
		}
	}

	if (aux == false) {
		return false;
	}

	for (let i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
		if (p1 >= 2) {
			v1 += cnpj[i] * p1;
		} else {
			v1 += cnpj[i] * p2;
		}
	}

	v1 = (v1 % 11);

	if (v1 < 2) {
		v1 = 0;
	} else {
		v1 = (11 - v1);
	}

	if (v1 != cnpj[12]) {
		return false;
	}

	for (let i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) {
		if (p1 >= 2) {
			v2 += cnpj[i] * p1;
		} else {
			v2 += cnpj[i] * p2;
		}
	}

	v2 = (v2 % 11);

	if (v2 < 2) {
		v2 = 0;
	} else {
		v2 = (11 - v2);
	}

	if (v2 != cnpj[13]) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	} else {
		return true;
	}
}

export const validarTelefone = (telefone, mensagem = 'O telefone inserido é inválido.') => {
	if(!telefone) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	if(typeof(telefone) !== 'string') {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}

	if(telefone.length === 14) {
		return true;
	} else if(telefone.length === 15) {
		return true;
	}

	if(mensagem) {
		msgToast.error(mensagem);
	}

	return false;
}

export const validarCep = (cep, mensagem = 'CEP Inválido') => {
	if(typeof(cep) === 'string' && cep.length === 9) {
		return true;
	}
	if(mensagem) {
		msgToast.error(mensagem);
	}
	return false;
}

export const validarUrl = (url, mensagem = 'A URL inserida é inválida.') => {
	var res = false;
	if(url && typeof(url) === 'string') {
		res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
	}
	if(url.trim().indexOf(' ') > -1) {
		res = false;
	}
	if(!res) {
		if(mensagem) {
			msgToast.error(mensagem);
		}
		return false;
	}
	return true;
}
