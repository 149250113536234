import React from 'react';
import _ from 'lodash';

export const tipoSessao = props => {
	return localStorage.getItem('@cargonavegroup/tipoSessao');
}

export const salvarSessaoLocal = props => {
	var sucesso = false;

	try {
		localStorage.setItem('@cargonavegroup/token', props.token);
		localStorage.setItem('@cargonavegroup/user', JSON.stringify(props.user));
		sucesso = true;
	} catch (error) {
		sucesso = false;
	}
	return sucesso;
};

export const apagarSessaoLocal = () => {
	var sucesso = false;
	try {
		localStorage.removeItem('@cargonavegroup/tipoSessao');
		localStorage.removeItem('@cargonavegroup/user');
		localStorage.removeItem('@cargonavegroup/token');

		sessionStorage.removeItem('@cargonavegroup/tipoSessao');
		sessionStorage.removeItem('@cargonavegroup/user');
		sessionStorage.removeItem('@cargonavegroup/token');
		sucesso = true;
	} catch (error) {
		sucesso = false;
	}
	return sucesso;
};

const isLogado = () => {

	const token = localStorage.getItem('@cargonavegroup/token');
	const user = localStorage.getItem('@cargonavegroup/user');

	var dadosObj = {};

	if(user !== null && user !== '' && user !== undefined) {
		try {
			var dadosJson = JSON.parse(user);
			dadosObj = dadosJson;
		} catch (error) {
		}
	}

	return {
		token: token,
		user: dadosObj,
	}
}

export default isLogado;
