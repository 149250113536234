import React from 'react';

// css
import './Placeholder.css';

// Imagens
import imgIconeVazio from './filter-list.svg';

// Funções

// Componentes
import Info2 from '@cpnt/Info2/Info2';

// Placeholders
import { PlaceholderEmpresas } from '@cpnt/Placeholders/PlaceholderEmpresas/PlaceholderEmpresas';
import { isNumeric } from "@func/numbers";
import { PlaceholderUsuarios } from "@cpnt/Placeholders/PlaceholderUsuarios/PlaceholderUsuarios";
import { PlaceholderPortos } from "@cpnt/Placeholders/PlaceholderPortos/PlaceholderPortos";
import { PlaceholderCargos } from "@cpnt/Placeholders/PlaceholderCargos/PlaceholderCargos";
import { PlaceholderNavios } from "@cpnt/Placeholders/PlaceholderNavios/PlaceholderNavios";
import { PlaceholderPDAs } from "@cpnt/Placeholders/PlaceholderPDAs/PlaceholderPDAs";
import { PlaceholderBercos } from "@cpnt/Placeholders/PlaceholderBercos/PlaceholderBercos";
import { PlaceholderEnderecos } from "@cpnt/Placeholders/PlaceholderEnderecos/PlaceholderEnderecos";
import { PlaceholderNews } from "@cpnt/Placeholders/PlaceholderNews/PlaceholderNews";
import { PlaceholderAcompanhamentos } from "@cpnt/Placeholders/PlaceholderAcompanhamentos/PlaceholderAcompanhamentos";

const Placeholder = props => {
	const {
		className = '',
		wrap = '',
		tipo = 'linha',
		width = 'default',
		height = 'default',
		style = {}
	} = props;

	const renderTipo = () => {
		var widthTipo = '';
		var heightTipo = '';

		if(tipo === 'linha') {
			widthTipo = width === 'default' ? '100%' : width;
			heightTipo = height === 'default' ? '20px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-linha ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'linha-min') {
			widthTipo = width === 'default' ? '100%' : width;
			heightTipo = height === 'default' ? '14px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-linha-min ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'linha-min-2') {
			widthTipo = width === 'default' ? '100%' : width;
			heightTipo = height === 'default' ? '10px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-linha-min ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'circulo') {
			widthTipo = width === 'default' ? '38px' : width;
			heightTipo = height === 'default' ? '38px' : width;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-circulo ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'quadrado') {
			widthTipo = width === 'default' ? '38px' : width;
			heightTipo = height === 'default' ? '38px' : width;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-quadrado ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'retangulo') {
			widthTipo = width === 'default' ? '38px' : width;
			heightTipo = height === 'default' ? '100px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-retangulo ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'retangulo-alt') {
			widthTipo = width === 'default' ? '20px' : width;
			heightTipo = height === 'default' ? '34px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-retangulo-alt ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'custom') {
			widthTipo = width === 'default' ? '38px' : width;
			heightTipo = height === 'default' ? '38px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-custom ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		if(tipo === 'min') {
			widthTipo = width === 'default' ? '26px' : width;
			heightTipo = height === 'default' ? '20px' : height;
			return (
				<div className={`cpnt-placeholder ${wrap}`}>
					<div className={`cpnt-placeholder-animation cpnt-placeholder-min ${className}`} style={{minWidth: widthTipo, maxWidth: widthTipo, minHeight: heightTipo, maxHeight: heightTipo, ...style}}></div>
				</div>
			)
		}
		return <div>...</div>;
	}

	return renderTipo();
};

export const PlaceholderRender = (props) => {
	const {
		qtd = 10,
		qtdColunas = 0,
		children,
		loading,
		itens,
		request,
		cpnt,
		conteudoSemResultado,
		classNameContainerSemResultado = 'flex flex-center w-100p border-box',
		table = true,
	} = props;


	var componente = PLACEHOLDER_ITENS[cpnt];

	if(!componente) {
		return <>{children}</>;
	}

	var retorno = [];
	var _request = false;

	if(request && Object.keys(request).length > 0) {
		_request = true;
	}

	if(!_request || (_request && typeof(_request) === 'object' && !Object.keys(_request).length <= 0)) {
		if(isNumeric(qtd)) {
			var qtdLoop = Number(qtd);
			for (let i = 0; i < qtdLoop; i++) {
				retorno.push(componente({...props, key: `placeholder-${i}`}));
			}
		}
		return <>{retorno}</>;
	}

	if(loading) {
		var count = itens ? itens.length : 0;
		if(count <= 0) {
			count = 1;
		}
		for (let i = 0; i < count; i++) {
			retorno.push(componente({...props, key: `placeholder-loading-${i}`}));
		}
		return <>{retorno}</>;
	}

	if((!loading && itens.length <= 0) || (request.status >= 400 && request.status <= 499)) {
		if(table) {
			retorno.push(
				<tr key="sem-resultado-tr" className="tr-disabled">
					<td colSpan={qtdColunas}>
						{conteudoSemResultado ? conteudoSemResultado : (
							<div className="padding-30 flex flex-center">
								<Info2
									icone={imgIconeVazio}
									titulo={request.msg || "Nenhum conteúdo encontrado"}
									desc="Tente utilizar filtros diferentes na sua busca"
								/>
							</div>
						)}
					</td>
				</tr>
			);
		} else {
			retorno.push(
				<div key="sem-resultado-div" className={classNameContainerSemResultado}>
					{conteudoSemResultado ? conteudoSemResultado : (
						<div className="padding-30 flex flex-center">
							<Info2
								icone={imgIconeVazio}
								titulo={request.msg || "Nenhum conteúdo encontrado"}
								desc="Tente utilizar filtros diferentes na sua busca"
							/>
						</div>
					)}
				</div>
			);
		}
		return <>{retorno}</>;
	} else if(request.status >= 500) {
		retorno.push(
			<tr key="sem-resultado-tr" className="tr-disabled">
				<td colSpan={qtdColunas}>
					<div className="padding-30 flex flex-center">
						<Info2
							icone={imgIconeVazio}
							titulo={request.msg || "Não foi possível fazer a requisição"}
							desc="Pode ser uma instabilidade no servidor, neste caso, tente novamente após alguns instantes."
						/>
					</div>
				</td>
			</tr>
		);
		return <>{retorno}</>;
	}

	return <>{children}</>;
}

export const PLACEHOLDER_ITENS = {
	PlaceholderEmpresas: e => <PlaceholderEmpresas {...e} />,
	PlaceholderUsuarios: e => <PlaceholderUsuarios {...e} />,
	PlaceholderPortos: e => <PlaceholderPortos {...e} />,
	PlaceholderCargos: e => <PlaceholderCargos {...e} />,
	PlaceholderNavios: e => <PlaceholderNavios {...e} />,
	PlaceholderPDAs: e => <PlaceholderPDAs {...e} />,
	PlaceholderBercos: e => <PlaceholderBercos {...e} />,
	PlaceholderEnderecos: e => <PlaceholderEnderecos {...e} />,
	PlaceholderNews: e => <PlaceholderNews {...e} />,
	PlaceholderAcompanhamentos: e => <PlaceholderAcompanhamentos {...e} />,
};

export default Placeholder;