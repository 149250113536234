// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SolicitarExclusaoDados_container__sI5vC {\n\twidth: 100%;\n\tmax-width: 500px;\n}\n.SolicitarExclusaoDados_lista__\\+Lkrt li + li {\n\tmargin-top: 10px;\n}\n.SolicitarExclusaoDados_info-email__HIhM8 {\n\tpadding: 15px;\n\tborder: 1px solid #CCC;\n\tborder-radius: 10px;\n\tmargin-top: 20px;\n\tbackground-color: #FAFAFA;\n}", "",{"version":3,"sources":["webpack://./src/pages/General/SolicitarExclusaoDados/SolicitarExclusaoDados.module.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,gBAAgB;AACjB;AACA;CACC,gBAAgB;AACjB;AACA;CACC,aAAa;CACb,sBAAsB;CACtB,mBAAmB;CACnB,gBAAgB;CAChB,yBAAyB;AAC1B","sourcesContent":[".container {\n\twidth: 100%;\n\tmax-width: 500px;\n}\n.lista li + li {\n\tmargin-top: 10px;\n}\n.info-email {\n\tpadding: 15px;\n\tborder: 1px solid #CCC;\n\tborder-radius: 10px;\n\tmargin-top: 20px;\n\tbackground-color: #FAFAFA;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SolicitarExclusaoDados_container__sI5vC",
	"lista": "SolicitarExclusaoDados_lista__+Lkrt",
	"info-email": "SolicitarExclusaoDados_info-email__HIhM8"
};
export default ___CSS_LOADER_EXPORT___;
