import React, { useEffect, useState } from 'react';

// css
import './PortalEnderecoDetalhes.css';

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import { api } from "@api/api";
import { DetalhesBerco } from "@page/Logged/Bercos/DetalhesBerco/DetalhesBerco";
import { msgStatus } from "@func/requests";
import { msgs } from "@func/jsx";
import Button from "@cpnt/Button/Button";
import { DetalhesEndereco } from "@page/Logged/Enderecos/DetalhesEndereco/DetalhesEndereco";

const PortalEnderecoDetalhes = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
	} = props;

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados({});
			setLoading(true);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await api.address.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			_setStateDados(msgStatus(retGetItem));
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	return (
		<Portal
			titulo="Detalhes do Endereço"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<DetalhesEndereco
								stateDados={stateDados}
								after={
									<div className="form-bottom">
										<Button
											_={{
												value: 'Fechar',
												type: 'button',
												onClick: onClose,
											}}
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}
		</Portal>
	);
};

export default PortalEnderecoDetalhes;