import moment from 'moment/min/moment-with-locales';

/*
** Funções de datas
** ================================================== */
export const validarDataAPI = e => {
	if(e && e !== null && e !== undefined && e !== '' && e.length > 0 && (e.length === 10 || e.length === 19 || e.length === 24) && e !== '0000-00-00 00:00:00') {
		return true;
	}
	return false;
}

export const dataParaApi = () => {
	return moment().utc().format('YYYY-DD-MM');
}

export const dataHoraMinSegApiParaDataApi = (data) => {
	return moment(data).utc().format('YYYY-DD-MM');
}

export const dataApiParaHoraMinSeg = (date) => {
	if(validarDataAPI(date)) {
		return moment(date).utc().format('H:mm:ss');
	}
	return null;
}

export const dataApiParaHoraMin = (date) => {
	if(validarDataAPI(date)) {
		return moment(date).utc().format('H:mm');
	}
	return null;
}

export const dataApiParaDataHoraMinSeg = (date) => {
	if(validarDataAPI(date)) {
		return moment(date).utc().format('DD/MM/YYYY à\\s H:mm:ss');
	}
	return '(dava inválida)';
}

export const dataApiParaDataMesDiaAno = (date) => {
	if(validarDataAPI(date)) {
		return moment(date).utc().format('DD/MM/YYYY');
	}
	return '(dava inválida)';
}