import React, { useContext } from 'react';

// Libs
import { useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// css
import './Topbar.css';

// Componentes
import Select3, { Select3Lista } from "@cpnt/Select3/Select3";
import { CirculoLetras } from "@cpnt/CirculoLetras/CirculoLetras";

// Imagens
import imgLogoCargonave from '@img/logo-cargonavegroup.svg';
import Tip from "@cpnt/Tip/Tip";
import Icone from "@cpnt/Icone/Icone";

const Topbar = props => {
	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();

	return (
		<>
			<div className={`cpnt-topbar`}>
				<div className="base">
					<div className="base-content">
						<div className="cpnt-topbar-inner">
							<div className="cpnt-topbar-imgbox"><img src={imgLogoCargonave} alt="logo-cargonave" /></div>
							<div className="cpnt-topbar-dir">

								<Tip
									tip={
										<CirculoLetras
											texto={store.user.name}
											classNameWrap="cursor-pointer"
										/>
									}
									click={true}
									_defaults={{placement: 'bottom-center'}}
								>
									<Select3Lista
										titulo={
											<div>
												<h3 className="margin-0">{store.user.name}</h3>
												<div><span>ID de cadastro:</span> {store.user.id}</div>
											</div>
										}
										options={[
											{value: 1, label: 'Logout'},
										]}
										posicao=""
										classNameDefault=""
										onClick={e => navigate('/logout')}
									/>
								</Tip>

							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { Topbar };
