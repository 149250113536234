import React, { useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalEmpresasFormCadastro.css';

// API
import { api } from "@api/api";

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormEmpresa from "@page/Logged/Empresas/FormEmpresa/FormEmpresa";

const PortalEmpresasFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		name: '',
		email: '',
		phone: '',
		active: 1,
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			name: stateDados.name,
			phone: stateDados.phone,
			email: stateDados.email,
			active: stateDados.active,
		};

		if(camposInvalidos(dadosEnviar, [
			'name',
			'phone',
			'email',
		])) return;

		setLoadingCadastrar(true);

		const ret = await api.companies.insert.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Empresa cadastrada com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Cadastrar empresa"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormEmpresa
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Cadastrar empresa',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalEmpresasFormCadastro;