import React, { useEffect, useState } from 'react';

// css

// Routes
import { api } from "@api/api";
import { Select } from "@cpnt/Select/Select";
import { formatarSelect } from "@func/components";
import { msgs } from "@func/jsx";

const FormEmpresas = props => {

	const {
		onSubmit,
		stateDados,
		setStateDados,
		after,
	} = props;

	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [stateEmpresas, setStateEmpresas] = useState([]);

	useEffect(() => {
		getEmpresas();
	}, []);

	const getEmpresas = async () => {
		setLoadingGetEmpresas(true);
		const ret = await api.companies.all.get({
			limit: 999,
		});
		if(ret.status === 200) {
			var dadosEmpresas = formatarSelect({
				dados: ret.data,
				keyValue: 'name',
				keyName: 'name',
			});
			setStateEmpresas(dadosEmpresas);
		} else {
			msgs([ret]);
		}
		setLoadingGetEmpresas(false);
	}

	const dadosSelect = dados => {
		var dadosArr = [];
		if(dados && dados.length) {
			dadosArr = dados.map(item => item.value);
		}
		return dadosArr;
	}

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="box-txt">
						<div className="flex gap-20">
							<div className="flex-1">
								<Select
									label="Empresas com acesso"
									_={{
										value: dadosSelect(stateDados.dados),
										onChange: e => {
											setStateDados({
												...stateDados,
												dados: e,
											})
										},
										options: stateEmpresas,
										isMulti: true,
										closeMenuOnSelect: false,
									}}
									loading={loadingGetEmpresas}
								/>
							</div>
						</div>
					</div>
				</div>

				{after}
			</form>
		</>
	);
};

export default FormEmpresas;