/*
** Funções de strings
** ================================================== */

import { isNumeric } from "./numbers";

export const primeiroNome = nomeCompleto => {
	if(nomeCompleto && nomeCompleto !== '' && nomeCompleto.indexOf(' ') >= 0) {
		return nomeCompleto.split(' ')[0];
	}
	return nomeCompleto;
}

export const ultimoNome = (nomeCompleto, retornoDefault = '') => {
	if(nomeCompleto && nomeCompleto !== '' && nomeCompleto.indexOf(' ') >= 0) {
		return nomeCompleto.split(' ').slice(-1)[0];
	}
	return retornoDefault;
}


export const trim = (texto, caracterTrim = ' ') => {
    var start = 0,
        end = texto.length;

    while(start < end && texto[start] === caracterTrim)
        ++start;

    while(end > start && texto[end - 1] === caracterTrim)
        --end;

    return (start > 0 || end < texto.length) ? texto.substring(start, end) : texto;
}

export const trimRight = (texto, remover) => {
    for(;;) {
        var pos = texto.lastIndexOf(remover);
        if(pos === texto.length -1) {
            var result  = texto.slice(0,pos);
            texto = result;
        } else {
            break;
        }
    }
    return texto;
}

export const singularPlural = (qtd, singular = 'item', plural = 'itens', nenhum = 'Nenhum item') => {
	if(isNumeric(qtd)) {
		if(Number(qtd) > 1) {
			return plural;
		}
		if(Number(qtd) > 0) {
			return singular;
		}
	}
	return nenhum;
}
