import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';
import { DEV } from '@config/config';

const PlaceholderNews = props => {
	const {
		itensQtd,
	} = props;

	return (
		<tr>
			<td className="col-id">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<div className="flex flex-center-v flex-1">
						<Placeholder tipo="quadrado" width={80} height={80} />
					</div>
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
		</tr>
	)
}

export { PlaceholderNews };
