import { formatarDinheiro } from "@func/format";
import React from 'react';

// css
import './PDALinhaDespesas.css';

const PDALinhaDespesas = props => {
	const {
		classNameWrap = '',
		className = '',
		header,
		titulo,
		dolar,
		real,
	} = props;

	return (
		<>
			<div className={`cpnt-pdalinhadesp ${classNameWrap} ${header ? 'cpnt-padlinhaesp-header' : ''}`}>
				<div className={`cpnt-pdalinhadesp-box ${className}`}>
					<div className="cpnt-padlinhaesp-col cpnt-pdalinhadesp-titulo strong">{titulo}</div>
					<div className="cpnt-padlinhaesp-col cpnt-pdalinhadesp-dolar">
						<strong className="cpnt-padlinhaesp-res">Dólar: </strong>{header ? <strong>{dolar}</strong> : <>$ {dolar}</>}
					</div>
					<div className="cpnt-padlinhaesp-col cpnt-pdalinhadesp-real">
						<strong className="cpnt-padlinhaesp-res">Real: </strong>{header ? <strong>{real}</strong> : <>R$ {formatarDinheiro(real)}</>}
					</div>
				</div>
			</div>
		</>
	);
};

export { PDALinhaDespesas };