import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';
import { DEV } from '@config/config';

const PlaceholderAcompanhamentos = props => {
	const {
		itensQtd,
	} = props;

	return (
		<tr>
			<td className="col-id">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>

			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>

			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>

			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>

			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
		</tr>
	)
}

export { PlaceholderAcompanhamentos };
