import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalAcompanhamentosEmpresasFormCadastro.module.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { removerKeysVazias } from "@func/arrsObjs";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormEmpresas from "@page/Logged/Acompanhamentos/FormEmpresas/FormEmpresas";
import { formatarSelect } from "@func/components";

const PortalAcompanhamentosEmpresasFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
		onSuccess = () => null,
		onError = () => null,
		stateEmpresas,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		tracking_verssels_id: stateEmpresas.id,
		dados: formatarSelect({
			dados: stateEmpresas.extra_companies,
			keyValue: 'company_name' ,
			keyName: 'company_name',
		}),
	};

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			tracking_verssels_id: stateDados.tracking_verssels_id,
			dados: stateDados.dados.map(item => {
				return {
					company: item.value,
				};
			}),
		}

		if(camposInvalidos(dadosEnviar, [
			'tracking_verssels_id',
		], {
			tracking_verssels_id: 'Tracking Verssels ID',
		})) return;

		setLoadingCadastrar(true);

		const ret = await api.trackingVessels.companies.insertMany.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Empresas atualizadas com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Editar empresas"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormEmpresas
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Salvar empresas',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalAcompanhamentosEmpresasFormCadastro;