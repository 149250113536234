import React from 'react';
import { Link } from 'react-router-dom';

// CSS
import './Erro404Deslogado.css';

// Imagens

const Erro404Deslogado = props => {

	return (
		<div>
			<h1 className="erro-404-titulo">Erro 404</h1>
			<p className="erro-404-desc">A página que você está procurando não existe.</p>
			<p><Link to="/">Ir para página inicial</Link></p>
		</div>
	)
}

export default Erro404Deslogado;