import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiVessels = {
	all: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/vessels/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	getOne: {
		get: async id => {
			return await api.get(`/vessels/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	insert: {
		post: async dadosObj => {
			return await api.post(`/vessels/insert`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	update: {
		put: async dadosObj => {
			return await api.put(`/vessels/update`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	delete: async id => {
		return await api.delete(`/vessels/${id}`, config())
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	},
};

export { apiVessels };