import React from 'react';

// CSS
import './Loading.css';

// Imagens
import imgLoading from './loading.svg';

const Loading = props => {

	const {
		texto = 'Carregando, aguarde...',
		imagem = imgLoading,
		imagemTamanho,
		wrap = '',
		className = 'flex flex-center w-100p border-box',
		classNameTexto = '',
		alt,
		input,
		absolute,
		bgAlt,
		bgAlt2,
		bgAlt3,
		textoWrap,
		classNameImg = '',
		min,
	} = props;

	return (
		// <div className={`cpnt-loading-wrap ${wrap}`}>
			<div className={`cpnt-loading ${wrap} ${className} ${bgAlt ? 'cpnt-loading-bgalt' : ''} ${bgAlt2 ? 'cpnt-loading-bgalt2' : ''} ${bgAlt3 ? 'cpnt-loading-bgalt3' : ''} ${absolute ? 'cpnt-loading-absolute' : ''} ${input ? 'cpnt-loading-absolute-input' : ''}`}>
				<div className="cpnt-loading-wrap">

					<div className={`cpnt-loading-inner ${alt ? 'cpnt-loading-alt' : ''} ${min ? 'cpnt-loading-min' : ''}`}>
						{imagem ? (
							<div className="cpnt-loading-icone-wrap" style={!texto || texto === '' ? {paddingRight: 0} : null}>
								<div className="cpnt-loading-icone">
									<img
										src={imagem}
										alt="icone"
										className={`cpnt-loading-img block margin-auto ${classNameImg}`}
										style={{
											...(imagemTamanho ? {width: imagemTamanho, height: imagemTamanho} : {}),
										}}
									/>
								</div>
							</div>
						) : null}

						{texto && texto !== '' ? (
							<div className={`cpnt-loading-texto ${classNameTexto} ${!absolute ? 'cpnt-loading-texto-default' : ''} ${textoWrap ? 'cpnt-loading-texto-wrap' : ''}`}>
								{texto}
							</div>
						) : null}

					</div>

				</div>
			</div>
		// </div>
	)
}

export default Loading;