import React, { useEffect, useRef, useState } from 'react';

// css
import './PDAs.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Button from "@cpnt/Button/Button";
import { CirculoLetras } from "@cpnt/CirculoLetras/CirculoLetras";
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import Input from "@cpnt/Input/Input";
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";
import PortalPDAsDetalhes from "@cpnt/Portais/PortaisPDAs/PortalPDAsDetalhes/PortalPDAsDetalhes";
import PortalPDAsFormCadastro from "@cpnt/Portais/PortaisPDAs/PortalPDAsFormCadastro/PortalPDAsFormCadastro";
import PortalPDAsFormEdicao from "@cpnt/Portais/PortaisPDAs/PortalPDAsFormEdicao/PortalPDAsFormEdicao";
import { Select } from "@cpnt/Select/Select";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";

// Objetos
import { FILTROS_RESULTADOS, STATUS_FILTRO } from "@config/objetos";

// Func
import { statusDefault } from "@func/components";
import { msgs } from "@func/jsx";
import { msgStatus, tratarFiltrosApi } from "@func/requests";
import { initSetFiltros, setFiltrosURL } from "@func/routeRequest";
import { msg } from "@func/utils";
import { LabelNavio } from "@cpnt/LabelNavio/LabelNavio";
import { dataApiParaDataMesDiaAno, dataApiParaHoraMin } from "@func/date";
import PortalPDAsFormDespesas from "@cpnt/Portais/PortaisPDAs/PortalPDAsFormDespesas/PortalPDAsFormDespesas";
import { removerKeysVazias } from "@func/arrsObjs";

const PDAs = props => {

	/*
	** Filtros
	** ================================================== */
	const filtrosDefault = {
		limit: 20,
		page: 1,
		id_company: '',
		id_vessel: '',
		id_port: '',
	};
	const [stateFiltros, setStateFiltros] = useState(filtrosDefault);

	// Ref filtros
	const refRequestFeito = useRef(false);
	const refFiltrosAtuais = useRef(false);
	const refGetBackup = useRef(window.location.search);
	const refIniciado = useRef(false);

	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastro: {},
		edicao: {},
		detalhes: {},
		despesas: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	/*
	** States
	** ================================================== */
	const [statePdas, setStatePdas] = useState([]);
	const [_statePdas, _setStatePdas] = useState({});

	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [stateNavios, setStateNavios] = useState([]);
	const [statePortos, setStatePortos] = useState([]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetPdas, setLoadingGetPdas] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetNavios, setLoadingGetNavios] = useState(false);
	const [loadingGetPortos, setLoadingGetPortos] = useState(false);

	/*
	** Init - define filtros
	** ================================================== */
	useEffect(() => {
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
		apiGeral.companies({setLoadingGetEmpresas, setStateEmpresas});
		apiGeral.vessels({setLoadingGetNavios, setStateNavios});
		apiGeral.ports({setLoadingGetPortos, setStatePortos});
	}, []);

	useEffect(() => {
		if (!refGetBackup.current !== window.location.search) {
			getPdas();
		}
		refIniciado.current = true;
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, [window.location.search]);

	/*
	** API - Get PDAs
	** ================================================== */
	const getPdas = async () => {
		if(loadingGetPdas) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = tratarFiltrosApi({
			limit: refFiltrosAtuais.current.limit,
			id_company: refFiltrosAtuais.current.id_company,
			id_vessel: refFiltrosAtuais.current.id_vessel,
			id_port: refFiltrosAtuais.current.id_port,
			page: refFiltrosAtuais.current.page,
		});

		const dadosEnviarSemKeysVazias = removerKeysVazias(dadosEnviar);

		setLoadingGetPdas(true);

		const ret = await api.pdas.all.get(dadosEnviarSemKeysVazias);

		_setStatePdas(msgStatus(ret));

		refRequestFeito.current = true;

		if(ret.status === 200) {
			setStatePdas(ret.data);
		} else {
			msgs([ret]);
		}

		setLoadingGetPdas(false);
	}

	const changeFiltro = (filterName, filterValue) => {
		setStateFiltros({ ...stateFiltros, [filterName]: filterValue });
		refFiltrosAtuais.current = {...stateFiltros, [filterName]: filterValue};
		setFiltrosURL(refFiltrosAtuais);
		refRequestFeito.current = false;
	};

	return (
		<>
			<div className={`pg-pdas`}>
				<div>
					<FiltrosContainer>
						<div className="titulo cpnt-baract-titulo color-1">
							PDAs
						</div>
						<FiltrosWrap>
							<form
								onSubmit={e => e.preventDefault()}
								className="cpnt-baract-filtros"
							>
								<div className="input-box flex-1 box-1-980">
									<Select
										label="Empresa"
										_={{
											value: stateFiltros.id_company,
        									onChange: e => changeFiltro('id_company', e ? e.value : ''),
											options: stateEmpresas,
											isClearable: true,
										}}
										loading={loadingGetEmpresas}
									/>
								</div>
								<div className="input-box flex-1 box-1-980">
									<Select
										label="Navio"
										_={{
											value: stateFiltros.id_vessel,
        									onChange: e => changeFiltro('id_vessel', e ? e.value : ''),
											options: stateNavios,
											isClearable: true,
										}}
										loading={loadingGetNavios}
									/>
								</div>
								<div className="input-box flex-1 box-1-980">
									<Select
										label="Porto"
										_={{
											value: stateFiltros.id_port,
        									onChange: e => changeFiltro('id_port', e ? e.value : ''),
											options: statePortos,
											isClearable: true,
										}}
									/>
								</div>
								<div className="input-box flex-1 box-1-980 input-box-btnlimpar">
									<Select
										label="Resultados"
										_={{
											value: stateFiltros.limit,
        									onChange: e => changeFiltro('limit', e.value),
											options: FILTROS_RESULTADOS,
										}}
										wrap="flex-1"
									/>
								</div>
							</form>
						</FiltrosWrap>
						<Button
							_={{
								value: 'Adicionar PDA',
								className: 'btn btn-default-alt',
								onClick: e => {
									setPortais({
										...portais,
										cadastro: {
											show: true,
										}
									})
								},
							}}
						/>
					</FiltrosContainer>

					<div className="base">
						<div className="base-content">
							<div id="tabela-container">
								<TotalPaginacao metadata={_statePdas} paginacao={stateFiltros} loading={loadingGetPdas} changeFiltro={changeFiltro} />
								<div className="table-wrap">
									<table className="tabela">
										<thead>
											<tr>
												<th className="col-id">
													ID
												</th>
												<th className="">
													Empresa
												</th>
												<th>
													Navio
												</th>
												<th>
													Porto
												</th>
												<th>
													Tipo de operação
												</th>
												<th className="col-data">
													Data
												</th>
												<th className="col-btn">
													Despesas
												</th>
												<th className="col-btn">
													Editar
												</th>
												<th className="col-btn">
													Visualizar
												</th>
											</tr>
										</thead>
										<tbody>
											<PlaceholderRender
												loading={loadingGetPdas}
												itens={statePdas}
												request={_statePdas}
												cpnt="PlaceholderPDAs"
												qtdColunas={9}
											>
												{statePdas.map((val, key) => {
													return (
														<tr key={key}>
															<td data-titulo="ID" className="col-id">
																<div className="td-wrap">
																	{val.id}
																</div>
															</td>
															<td data-titulo="Empresa">
																<div className="td-wrap">
																	<div className="inline-flex flex-center fs-18">
																		<CirculoLetras
																			texto={val.company_name}
																			alt
																		/>
																		<span className="margin-l-15">{val.company_name}</span>
																	</div>
																</div>
															</td>
															<td data-titulo="Navio">
																<div className="td-wrap">
																	{val.vessel_name || '-'}
																</div>
															</td>
															<td data-titulo="Porto">
																<div className="td-wrap">
																	{val.id_port ? val.port_name : '-'}
																</div>
															</td>
															<td data-titulo="Tipo de operação">
																<div className="td-wrap">
																	{val.operation_type || '-'}
																</div>
															</td>
															<td data-titulo="Data">
																<div className="td-wrap">
																	{dataApiParaDataMesDiaAno(val.created_at)}
																</div>
															</td>
															<td data-titulo="Despesas">
																<Button
																	_={{
																		value: 'Despesas',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				despesas: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
															<td data-titulo="Editar">
																<Button
																	_={{
																		value: 'Editar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				edicao: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
															<td data-titulo="Visualizar">
																<Button
																	_={{
																		value: 'Visualizar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				detalhes: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
														</tr>
													)
												})}
											</PlaceholderRender>
										</tbody>
									</table>
								</div>
								<TotalPaginacao metadata={_statePdas} paginacao={stateFiltros} loading={loadingGetPdas} changeFiltro={changeFiltro} />
							</div>
						</div>
					</div>
				</div>

			</div>

			<PortalPDAsDetalhes
				show={portais.detalhes.show}
				id={portais.detalhes.id}
				onClose={() => {
					setPortais({...portais, detalhes: {}})
				}}
			/>

			<PortalPDAsFormCadastro
				show={portais.cadastro.show}
				onClose={() => {
					setPortais({...portais, cadastro: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastro: {}});
					getPdas();
				}}
				closeOnEsc={false}
			/>

			<PortalPDAsFormEdicao
				show={portais.edicao.show}
				id={portais.edicao.id}
				onClose={() => {
					setPortais({...portais, edicao: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, edicao: {}});
					getPdas();
				}}
				closeOnEsc={false}
			/>

			<PortalPDAsFormDespesas
				show={portais.despesas.show}
				id={portais.despesas.id}
				onClose={() => {
					setPortais({...portais, despesas: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, despesas: {}});
				}}
				closeOnEsc={false}
			/>
		</>
	);
};

export { PDAs };
