import React from 'react';

// css
import './LabelNavio.css';

const LabelNavio = props => {
	const {
		className = '',
		status,
	} = props;

	const CORES_STATUS = {
		DISCH: {
			backgroundColor: '#D0B5C5',
			color: '#000',
		},
		WTNG: {
			backgroundColor: '#F4A00B',
			color: '#FFF',
		},
		SAILED: {
			backgroundColor: '#DC1623',
			color: '#FFF',
		},
		LDNG: {
			backgroundColor: '#0C9449',
			color: '#FFF',
		},
		_default: {
			backgroundColor: '#FFF',
			color: '#000',
		},
	};

	const corStatus = (status) => {
		if(CORES_STATUS[status]) {
			return CORES_STATUS[status];
		}
		return CORES_STATUS['_default'];
	};

	var cor = corStatus(status);

	return (
		<>
			<div className={`cpnt-labelnavio-box ${className} ${status?.length > 7 ? 'cpnt-labelnavio-box-small' : ''}`} style={{backgroundColor: cor.backgroundColor, color: cor.color}}>
				{status || '-'}
			</div>
		</>
	);
};

export { LabelNavio };