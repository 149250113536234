import DOMPurify from 'dompurify';

/*
** Funções de regex
** ================================================== */

export const apenasNumeros = (string, converter = false) => {
	if(!string) {
		return string;
	}
	var numeros = string.toString();
	numeros = numeros.replace(/\D+/g, '');
	if(converter) {
		numeros = Number(numeros);
	}
	return numeros;
}

export const apenasLetras = (string) => {
	if(!string) {
		return string;
	}
	if(typeof(string) !== 'string') {
		return string;
	}

	var letras = string.toString();
	letras = letras.replace(/[^a-zA-Z]/g, '');
	return letras;
}

export const apenasNumerosCpfCnpj = string => {
	var retorno = '';
	if(string && typeof(string) === 'string') {
		retorno = string.replace(/\D/g,'').toString();
		if(retorno > 14) {
			retorno = retorno.slice(0, 14);
		}
	}
	return retorno;
}

export const removerHtml = (texto, tagsPermitidas = [], barraN = true) => {
	if(texto) {
		var clean = DOMPurify.sanitize(texto, {ALLOWED_TAGS: tagsPermitidas}); // ['b', 'a', 'strong']
		if(barraN) {
			clean = clean.replace(/\n\n+/g, '\n\n');
		}
		return clean;
		// return texto.replace(/<br\s*\\?>/g, "\r\n\n").replace(/<[^>]+>/g, "");
	}
	return texto;
}
