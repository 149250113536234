import React, { useEffect, useRef, useState } from 'react';

// css
import './Usuarios.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Button from "@cpnt/Button/Button";
import { CirculoLetras } from "@cpnt/CirculoLetras/CirculoLetras";
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import Input from "@cpnt/Input/Input";
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";
import PortalUsuariosDetalhes from "@cpnt/Portais/PortaisUsuarios/PortalUsuariosDetalhes/PortalUsuariosDetalhes";
import PortalUsuariosFormCadastro from "@cpnt/Portais/PortaisUsuarios/PortalUsuariosFormCadastro/PortalUsuariosFormCadastro";
import PortalUsuariosFormEdicao from "@cpnt/Portais/PortaisUsuarios/PortalUsuariosFormEdicao/PortalUsuariosFormEdicao";
import { Select } from "@cpnt/Select/Select";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";

// Objetos
import { FILTROS_RESULTADOS, STATUS_FILTRO } from "@config/objetos";

// Func
import { statusDefault } from "@func/components";
import { msgs } from "@func/jsx";
import { msgStatus, tratarFiltrosApi } from "@func/requests";
import { initSetFiltros, setFiltrosURL } from "@func/routeRequest";
import { msg } from "@func/utils";
import { dataApiParaDataHoraMinSeg } from "@func/date";
import PortalUsuariosFormAtivacao from "@cpnt/Portais/PortaisUsuarios/PortalUsuariosFormAtivacao/PortalUsuariosFormAtivacao";

const Usuarios = props => {

	/*
	** Filtros
	** ================================================== */
	const filtrosDefault = {
		id_company: '',
		limit: 20,
		page: 1,
		search: '',
		app: '',
		active: 2,
	};
	const [stateFiltros, setStateFiltros] = useState(filtrosDefault);

	// Ref filtros
	const refRequestFeito = useRef(false);
	const refFiltrosAtuais = useRef(false);
	const refGetBackup = useRef(window.location.search);
	const refIniciado = useRef(false);

	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastro: {},
		edicao: {},
		detalhes: {},
		ativacao: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	/*
	** States
	** ================================================== */
	const [stateUsuarios, setStateUsuarios] = useState([]);
	const [_stateUsuarios, _setStateUsuarios] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetUsuarios, setLoadingGetUsuarios] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);

	/*
	** Init - define filtros
	** ================================================== */
	useEffect(() => {
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
		apiGeral.companies({setLoadingGetEmpresas, setStateEmpresas});
	}, []);

	useEffect(() => {
		if (!refGetBackup.current !== window.location.search) {
			getUsuarios();
		}
		refIniciado.current = true;
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, [window.location.search]);

	/*
	** API - Get Usuarios
	** ================================================== */
	const getUsuarios = async () => {
		if(loadingGetUsuarios) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = tratarFiltrosApi({
			id_company: refFiltrosAtuais.current.id_company,
			limit: refFiltrosAtuais.current.limit,
			search: refFiltrosAtuais.current.search,
			active: refFiltrosAtuais.current.active,
			app: refFiltrosAtuais.current.app,
			page: refFiltrosAtuais.current.page,
		});

		if(dadosEnviar.id_company === '') {
			delete dadosEnviar.id_company;
		}

		setLoadingGetUsuarios(true);

		const ret = await api.users.all.get(dadosEnviar);
		_setStateUsuarios(msgStatus(ret));

		refRequestFeito.current = true;

		if(ret.status === 200) {
			setStateUsuarios(ret.data);
		} else {
			msgs([ret]);
		}

		setLoadingGetUsuarios(false);
	}

	const changeFiltro = (filterName, filterValue) => {
		setStateFiltros({ ...stateFiltros, [filterName]: filterValue });
		refFiltrosAtuais.current = {...stateFiltros, [filterName]: filterValue};
		setFiltrosURL(refFiltrosAtuais);
		refRequestFeito.current = false;
	};

	return (
		<>
			<div className={`pg-usuarios`}>
				<div>
					<FiltrosContainer>
						<div className="titulo cpnt-baract-titulo color-1">
							Usuários
						</div>
						<FiltrosWrap>
							<form
								onSubmit={e => e.preventDefault()}
								className="cpnt-baract-filtros"
							>
								<div className="input-box flex-1 box-1-980">
									<Input
										label="Pesquisar usuários"
										_={{
											value: stateFiltros.search,
        									onChange: e => changeFiltro('search', e.target.value)
										}}
										debounce
									/>
								</div>
								<div className="input-box w-200 box-1-980">
									<Select
										label="Empresa"
										_={{
											value: stateFiltros.id_company,
        									onChange: e => changeFiltro('id_company', e ? e.value : ''),
											options: stateEmpresas,
											isClearable: true,
										}}
									/>
								</div>
								<div className="input-box flex-1 box-1-980">
									<Select
										label="Status"
										_={{
											value: stateFiltros.active,
        									onChange: e => changeFiltro('active', e ? e.value : ''),
											options: STATUS_FILTRO,
											isClearable: true,
										}}
									/>
								</div>
								<div className="input-box flex-1 box-1-980">
									<Select
										label="Origem"
										_={{
											value: stateFiltros.app,
        									onChange: e => changeFiltro('app', e ? e.value : ''),
											options: [
												{value: 2, label: 'Todos'},
												{value: 1, label: 'App'},
												{value: 0, label: 'Painel'},
											],
											isClearable: true,
										}}
									/>
								</div>
								<div className="input-box flex-1 box-1-980 input-box-btnlimpar">
									<Select
										label="Resultados"
										_={{
											value: stateFiltros.limit,
        									onChange: e => changeFiltro('limit', e.value),
											options: FILTROS_RESULTADOS,
										}}
										wrap="flex-1"
									/>
								</div>
							</form>
						</FiltrosWrap>
						<Button
							_={{
								value: 'Adicionar usuário',
								className: 'btn btn-default-alt',
								onClick: e => {
									setPortais({
										...portais,
										cadastro: {
											show: true,
										}
									})
								},
							}}
						/>
					</FiltrosContainer>

					<div className="base">
						<div className="base-content">
							<div id="tabela-container">
								<TotalPaginacao metadata={_stateUsuarios} paginacao={stateFiltros} loading={loadingGetUsuarios} changeFiltro={changeFiltro} />
								<div className="table-wrap">
									<table className="tabela">
										<thead>
											<tr>
												<th className="col-id">
													ID
												</th>
												<th className="">
													Nome do usuário
												</th>
												<th className="">
													E-mail
												</th>
												<th className="">
													Empresa
												</th>
												<th className="col-min-7">
													Data Cadastro
												</th>
												<th className="col-min-3">
													Origem
												</th>
												<th className="col-min-5">
													Status
												</th>
												<th className="col-btn">
													Editar
												</th>
												<th className="col-btn">
													Visualizar
												</th>
											</tr>
										</thead>
										<tbody>
											<PlaceholderRender
												loading={loadingGetUsuarios}
												itens={stateUsuarios}
												request={_stateUsuarios}
												cpnt="PlaceholderUsuarios"
												qtdColunas={9}
											>
												{stateUsuarios.map((val, key) => {
													return (
														<tr key={key}>
															<td data-titulo="ID" className="col-id">
																<div className="td-wrap">
																	{val.id}
																</div>
															</td>
															<td data-titulo="Usuário" className="col-responsavel">
																<div className="td-wrap">
																	<div className="inline-flex flex-center fs-18">
																		<CirculoLetras
																			texto={val.name}
																			alt
																		/>
																		<span className="margin-l-15">{val.name}</span>
																	</div>
																</div>
															</td>
															<td data-titulo="E-mail">
																<div className="td-wrap">
																	{val.email}
																</div>
															</td>
															<td data-titulo="Empresa">
																<div className="td-wrap">
																	<div>{val.company_name || val.informed_company || '-'}</div>
																</div>
															</td>
															<td data-titulo="Data cadastro">
																<div className="td-wrap align-center">
																	{dataApiParaDataHoraMinSeg(val.created_at)}
																</div>
															</td>
															<td data-titulo="Origem">
																<div className="td-wrap">
																	{val.app ? <span className="color-green strong">App</span> : <span>Painel</span>}
																</div>
															</td>
															<td data-titulo="Status" className="relative">
																<div className="td-wrap ">
																	{val.active ? (
																		<div className={'td-desc-alt'}>{statusDefault(val.active)}</div>
																	) : (
																		<Button
																			_={{
																				value: statusDefault(val.active),
																				className: 'btn btn-red btn-min',
																				onClick: e => {
																					setPortais({
																						...portais,
																						ativacao: {
																							show: true,
																							id: val.id,
																						}
																					})
																				}
																			}}
																			classNameWrap="flex margin-auto padding-v-5"
																		/>
																	)}
																</div>
															</td>
															<td data-titulo="Editar" className="">
																<Button
																	_={{
																		value: 'Editar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				edicao: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
															<td data-titulo="Visualizar" className="">
																<Button
																	_={{
																		value: 'Visualizar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				detalhes: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
														</tr>
													)
												})}
											</PlaceholderRender>
										</tbody>
									</table>
								</div>
								<TotalPaginacao metadata={_stateUsuarios} paginacao={stateFiltros} loading={loadingGetUsuarios} changeFiltro={changeFiltro} />
							</div>
						</div>
					</div>
				</div>

			</div>

			<PortalUsuariosDetalhes
				show={portais.detalhes.show}
				id={portais.detalhes.id}
				onClose={() => {
					setPortais({...portais, detalhes: {}})
				}}
			/>

			<PortalUsuariosFormCadastro
				show={portais.cadastro.show}
				onClose={() => {
					setPortais({...portais, cadastro: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastro: {}});
					getUsuarios();
				}}
				closeOnEsc={false}
			/>

			<PortalUsuariosFormEdicao
				show={portais.edicao.show}
				id={portais.edicao.id}
				onClose={() => {
					setPortais({...portais, edicao: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, edicao: {}});
					getUsuarios();
				}}
				closeOnEsc={false}
			/>

			<PortalUsuariosFormAtivacao
				show={portais.ativacao.show}
				id={portais.ativacao.id}
				onClose={() => {
					setPortais({...portais, ativacao: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, ativacao: {}});
					getUsuarios();
				}}
				closeOnEsc={false}
			/>
		</>
	);
};

export { Usuarios };
