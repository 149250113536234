import _ from 'lodash';
import { trim, trimRight } from "./strings";

/*
** Funções de strings, arrays e objetos
** ================================================== */
export const isObjDiferente = (fromObject, toObject, retornoIgual = {}) => {
    const changes = {};

    const buildPath = (path, obj, key) =>
        _.isUndefined(path) ? key : `${path}.${key}`;

    const walk = (fromObject, toObject, path) => {
        for (const key of _.keys(fromObject)) {
            const currentPath = buildPath(path, fromObject, key);
            if (!_.has(toObject, key)) {
                changes[currentPath] = {from: _.get(fromObject, key)};
            }
        }

        for (const [key, to] of _.entries(toObject)) {
            const currentPath = buildPath(path, toObject, key);
            if (!_.has(fromObject, key)) {
                changes[currentPath] = {to};
            } else {
                const from = _.get(fromObject, key);
                if (!_.isEqual(from, to)) {
                    if (_.isObjectLike(to) && _.isObjectLike(from)) {
                        walk(from, to, currentPath);
                    } else {
                        changes[currentPath] = {from, to};
                    }
                }
            }
        }
    };

    walk(fromObject, toObject);

	if(Object.keys(changes).length) {
		return changes;
	}

	return retornoIgual;

}

export const trimArr = (texto, caracterTrimArr, sufixo = false) => {
    var retorno = texto;
	caracterTrimArr.map((val, key) => {
		retorno = trim(retorno, val);
	});
	if(sufixo) {
		retorno = trimRight(retorno, sufixo);
	}
	return retorno;
}

export const shuffleArray = (arr) => {
	let i = arr.length - 1;
	for (; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		const temp = arr[i];
		arr[i] = arr[j];
		arr[j] = temp;
	}
	return arr;
}

export const joinArray = (arr, separador = ',', final = 'e', keyObj = false) => {
    var retorno = '';
	if(typeof(arr) === 'object') {
		if (arr.length === 1) {
			if(keyObj) {
				retorno = arr[0][keyObj];
			} else {
				retorno = arr[0];
			}
		} else if (arr.length === 2) {
			if(keyObj) {
				retorno = _.map(arr, keyObj).join(` ${final} `);;
			} else {
				retorno = arr.join(` ${final} `);
			}
		} else if (arr.length > 2) {
			if(keyObj) {
				var keysOk = _.map(arr, keyObj);
				retorno = keysOk.slice(0, -1).join(`${separador} `) + `, ${final} ` + keysOk.slice(-1);
			} else {
				retorno = arr.slice(0, -1).join(`${separador} `) + `, ${final} ` + arr.slice(-1);
			}
		}
	} else {
		retorno = arr;
	}
    return retorno;
}

export const joinObj = (obj, separador = ',', final = 'e') => {
    var retorno = '';
	if(typeof(obj) === 'object') {
		var arr = Object.keys(obj);
		if (arr.length === 1) {
			retorno = arr[0];
		} else if (arr.length === 2) {
			retorno = arr.join(` ${final} `);
		} else if (arr.length > 2) {
			retorno = arr.slice(0, -1).join(`${separador} `) + `, ${final} ` + arr.slice(-1);
		}
	} else {
		retorno = arr;
	}
    return retorno;
}

/*
** Definir filtros para a busca
** ================================================== */
export const removerKeysVazias = (dados = {}) => {
	var dadosSemKeysVazias = {};
	Object.keys(dados).map((val, key) => {
		if(dados[val] && dados[val] !== false && dados[val] !== '' && dados[val] !== 'false') {
			if(
				(typeof(dados[val]) === 'string' && dados[val] !== '') ||
				(typeof(dados[val]) === 'object' && dados[val].length > 0) ||
				(typeof(dados[val]) === 'boolean') ||
				(typeof(dados[val]) === 'number' && dados[val] > 0)
			) {
				if(dados[val] !== 'Invalid date') {
					dadosSemKeysVazias[val] = dados[val];
				}
			}
		}
	});

	return dadosSemKeysVazias;
}

export const temKeys = (dados = {}) => {
	if(dados && Object.keys(dados).length) {
		return true;
	}
	return false;
}

export const arrGetCheckeds = (lista = [], keyId = 'id', keyRetorno) => {
	var retorno = [];
	if(lista && typeof(lista) === 'object' && lista.length) {
		lista.map((val, key) => {
			if(val.checked) {
				if(keyRetorno) {
					retorno.push({
						[keyRetorno]: val[keyId]
					});
				} else {
					retorno.push(val);
				}
			}
		});
	}
	return retorno;
}

export const arrValues = (lista = [], keyRetorno, keyObj) => {
	var retorno = [];
	if(lista && typeof(lista) === 'object' && lista.length) {
		lista.map((val, key) => {
			if(val[keyRetorno]) {
				if(keyObj) {
					retorno.push({
						[keyObj]: val[keyRetorno]
					});
				} else {
					retorno.push(val[keyRetorno]);
				}
			}
		});
	}
	return retorno;
}

export const arrValue = (lista = [], keySearch, valueCompare, keyRetorno, retornoDefault = '') => {
	var retorno = retornoDefault;
	if(lista && typeof(lista) === 'object' && lista.length) {
		lista.map((val, key) => {
			if(val[keySearch] === valueCompare) {
				retorno = val[keyRetorno];
			}
		});
	}
	return retorno;
}

export const checkArr = (listaCompleta, listaComCheck, keyLista1 = 'id', keyLista2 = 'id') => {

	if(!listaComCheck || typeof(listaComCheck) !== 'object') {
		listaComCheck = [];
	}

	var _listaCompleta = listaCompleta.map(item => {
		return {
			...item,
			checked: false,
		}
	});

	var _listaCompletaFinal = _listaCompleta.map(item => {
		if(_.find(listaComCheck, {[keyLista1]: item[keyLista2]})) {
			return {
				...item,
				checked: true,
			}
		}
		return item;
	});

	return _listaCompletaFinal;
}

export const uncheckArr = (lista) => {

	if(!lista || typeof(lista) !== 'object') {
		lista = [];
	}

	var _listaCompleta = lista.map(item => {
		return {
			...item,
			checked: false,
		}
	});

	return _listaCompleta;
}

export const unificarArr = (arrays, keyToCheck = 'id') => {
	if(!arrays || typeof(arrays) !== 'object' || !arrays.length) {
		return [];
	}

	var arrs = [];
	arrays.map(val => {
		if(val) {
			arrs = [
				...arrs,
				...val,
			];
		}
	});

	const unicoQrr = _.uniqBy(arrs, e => {
		return e[keyToCheck];
	});

	return unicoQrr;
}

export const getKey = (dadosArr, valorPesquisar, chavePesquisar) => {
	var dados = {};

	if(dadosArr && typeof(dadosArr) === 'object' && dadosArr.length) {
		dados = _.find(dadosArr, {[chavePesquisar]: valorPesquisar});
		if(dados && typeof(dados) === 'object' && Object.keys(dados).length) {
			return dados;
		}
	}

	return {};
}
