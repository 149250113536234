import Logout from "@page/General/Logout/Logout";
import AtivarCadastro from "@page/NotLogged/AtivarCadastro/AtivarCadastro";
import Erro404Deslogado from "@page/NotLogged/Erro404Deslogado/Erro404Deslogado";
import Login from "@page/NotLogged/Login/Login";
import RecuperarSenha from "@page/NotLogged/RecuperarSenha/RecuperarSenha";
import LayoutInicio from "@role/Layouts/LayoutInicio/LayoutInicio";
import { useRoutes } from 'react-router-dom';

export const ROTAS_GERAIS = [
	{
		path: '/',
		element:
		<LayoutInicio
			meta={{
				title: 'Login',
			}}
			component={Login}
		/>,
	},
	{
		path: '/login',
		element:
		<LayoutInicio
			meta={{
				title: 'Login',
			}}
			component={Login}
		/>,
	},
	{
		path: '/recuperar-senha',
		element:
		<LayoutInicio
			meta={{
				title: 'Recuperar senha',
			}}
			component={RecuperarSenha}
		/>,
	},
	{
		path: '/ativar-cadastro',
		element:
		<LayoutInicio
			meta={{
				title: 'Ativar cadastro',
			}}
			component={AtivarCadastro}
		/>,
	},
	{
		path: '/logout',
		element:
		<Logout
			meta={{
				title: 'Fazendo logout, aguarde...',
			}}
		/>,
	},
	{
		path: '*',
		element:
		<LayoutInicio
			meta={{
				title: 'Página não encontrada...',
			}}
			component={Erro404Deslogado}
		/>,
	}
];

function ScreensGerais() {
	const rotasGerais = useRoutes(ROTAS_GERAIS);
	return rotasGerais;
}

export default ScreensGerais;