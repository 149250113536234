import React, { useEffect, useRef, useState } from 'react';

// css
import './Bercos.css';

// API
import { api } from "@api/api";

// Componentes
import Button from "@cpnt/Button/Button";
import { CirculoLetras } from "@cpnt/CirculoLetras/CirculoLetras";
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import Input from "@cpnt/Input/Input";
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";
import PortalBercoDetalhes from "@cpnt/Portais/PortaisBercos/PortalBercoDetalhes/PortalBercoDetalhes";
import PortalBercoFormCadastro from "@cpnt/Portais/PortaisBercos/PortalBercoFormCadastro/PortalBercoFormCadastro";
import PortalBercoFormEdicao from "@cpnt/Portais/PortaisBercos/PortalBercoFormEdicao/PortalBercoFormEdicao";
import { Select } from "@cpnt/Select/Select";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";

// Objetos
import { FILTROS_RESULTADOS, STATUS_FILTRO } from "@config/objetos";

// Func
import { statusDefault } from "@func/components";
import { msgs } from "@func/jsx";
import { msgStatus, tratarFiltrosApi } from "@func/requests";
import { initSetFiltros, setFiltrosURL } from "@func/routeRequest";
import { msg } from "@func/utils";

const Bercos = props => {

	/*
	** Filtros
	** ================================================== */
	const filtrosDefault = {
		limit: 20,
		page: 1,
		search: '',
		active: 2,
	};
	const [stateFiltros, setStateFiltros] = useState(filtrosDefault);

	// Ref filtros
	const refRequestFeito = useRef(false);
	const refFiltrosAtuais = useRef(false);
	const refGetBackup = useRef(window.location.search);
	const refIniciado = useRef(false);


	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastro: {},
		edicao: {},
		detalhes: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	/*
	** States
	** ================================================== */
	const [stateBercos, setStateBercos] = useState([]);
	const [_stateBercos, _setStateBercos] = useState({});

	/*
	** Loading
	** ================================================== */
	const [loadingGetBercos, setLoadingGetBercos] = useState(false);

	/*
	** Init - define filtros
	** ================================================== */
	useEffect(() => {
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, []);

	useEffect(() => {
		if (!refGetBackup.current !== window.location.search) {
			getBercos();
		}
		refIniciado.current = true;
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, [window.location.search]);

	/*
	** API - Get Bercos
	** ================================================== */
	const getBercos = async () => {
		if(loadingGetBercos) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = tratarFiltrosApi({
			limit: refFiltrosAtuais.current.limit,
			search: refFiltrosAtuais.current.search,
			status: refFiltrosAtuais.current.status,
			active: refFiltrosAtuais.current.active,
			page: refFiltrosAtuais.current.page,
		});

		setLoadingGetBercos(true);

		const ret = await api.berths.all.get(dadosEnviar);
		_setStateBercos(msgStatus(ret));

		refRequestFeito.current = true;

		if(ret.status === 200) {
			setStateBercos(ret.data);
		} else {
			msgs([ret]);
		}

		setLoadingGetBercos(false);
	}

	const changeFiltro = (filterName, filterValue) => {
		var page = stateFiltros.page;
		if(filterName !== 'page') { page = 1; } else { page = filterValue; }
		setStateFiltros({ ...stateFiltros, [filterName]: filterValue, page: page });
		refFiltrosAtuais.current = {...stateFiltros, [filterName]: filterValue, page: page};
		setFiltrosURL(refFiltrosAtuais);
		refRequestFeito.current = false;
	};

	return (
		<>
			<div className={`pg-Berco`}>
				<div>
					<FiltrosContainer>
						<div className="titulo cpnt-baract-titulo color-1">
							Berços
						</div>
						<FiltrosWrap>
							<form
								onSubmit={e => e.preventDefault()}
								className="cpnt-baract-filtros"
							>
								<div className="input-box flex-1 box-1-980">
									<Input
										label="Pesquisar berços"
										_={{
											value: stateFiltros.search,
        									onChange: e => changeFiltro('search', e.target.value)
										}}
										debounce
									/>
								</div>
								<div className="input-box flex-1 box-1-980">
									<Select
										label="Status"
										_={{
											value: stateFiltros.active,
        									onChange: e => changeFiltro('active', e ? e.value : ''),
											options: STATUS_FILTRO,
										}}
									/>
								</div>
								<div className="input-box flex-1 box-1-980 input-box-btnlimpar">
									<Select
										label="Resultados"
										_={{
											value: stateFiltros.limit,
        									onChange: e => changeFiltro('limit', e.value),
											options: FILTROS_RESULTADOS,
										}}
										wrap="flex-1"
									/>
								</div>
							</form>
						</FiltrosWrap>
						<Button
							_={{
								value: 'Adicionar berço',
								className: 'btn btn-default-alt',
								onClick: e => {
									setPortais({
										...portais,
										cadastro: {
											show: true,
										}
									})
								},
							}}
						/>
					</FiltrosContainer>

					<div className="base">
						<div className="base-content">
							<div id="tabela-container">
								<TotalPaginacao metadata={_stateBercos} paginacao={stateFiltros} loading={loadingGetBercos} changeFiltro={changeFiltro} />
								<div className="table-wrap">
									<table className="tabela">
										<thead>
											<tr>
												<th className="col-id">
													ID
												</th>
												<th className="">
													Nome do berço
												</th>
												<th className="">
													Porto
												</th>
												<th className="col-min-3">
													Status
												</th>
												<th className="col-btn">
													Editar
												</th>
												<th className="col-btn">
													Visualizar
												</th>
											</tr>
										</thead>
										<tbody>
											<PlaceholderRender
												loading={loadingGetBercos}
												itens={stateBercos}
												request={_stateBercos}
												cpnt="PlaceholderBercos"
												qtdColunas={7}
											>
												{stateBercos.map((val, key) => {
													return (
														<tr key={key}>
															<td data-titulo="ID" className="col-id">
																<div className="td-wrap">
																	{val.id}
																</div>
															</td>
															<td data-titulo="Berço" className="col-responsavel">
																<div className="td-wrap">
																	<div className="inline-flex flex-center fs-18">
																		<CirculoLetras
																			texto={val.name}
																			alt
																		/>
																		<span className="margin-l-15">{val.name}</span>
																	</div>
																</div>
															</td>
															<td data-titulo="Porto">
																<div className="td-wrap">
																	{val.port_name}
																</div>
															</td>
															<td data-titulo="Status" className="relative">
																<div className="td-wrap ">
																	<div className={`td-desc-alt ${val.active ? '' : 'color-red'}`}>{statusDefault(val.active)}</div>
																</div>
															</td>
															<td data-titulo="Editar" className="">
																<Button
																	_={{
																		value: 'Editar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				edicao: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
															<td data-titulo="Visualizar" className="">
																<Button
																	_={{
																		value: 'Visualizar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				detalhes: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
														</tr>
													)
												})}
											</PlaceholderRender>
										</tbody>
									</table>
								</div>
								<TotalPaginacao metadata={_stateBercos} paginacao={stateFiltros} loading={loadingGetBercos} changeFiltro={changeFiltro} />
							</div>
						</div>
					</div>
				</div>

			</div>

			<PortalBercoDetalhes
				show={portais.detalhes.show}
				id={portais.detalhes.id}
				onClose={() => {
					setPortais({...portais, detalhes: {}})
				}}
			/>

			<PortalBercoFormCadastro
				show={portais.cadastro.show}
				onClose={() => {
					setPortais({...portais, cadastro: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastro: {}});
					getBercos();
				}}
				closeOnEsc={false}
			/>

			<PortalBercoFormEdicao
				show={portais.edicao.show}
				id={portais.edicao.id}
				onClose={() => {
					setPortais({...portais, edicao: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, edicao: {}});
					getBercos();
				}}
				closeOnEsc={false}
			/>
		</>
	);
};

export { Bercos };
