import React from 'react';

// CSS
import { apenasNumeros } from "@func/regex";
import './ContabilizarPaginas.css';

const ContabilizarPaginas = props => {
	var {
		className = '',
		wrap = '',

		paginaAtual = 0,
		limite = 0,
		qtdPaginas = 0,
		total = 0,
	} = props;

	const contabilizar = () => {
		paginaAtual = apenasNumeros(paginaAtual, true);
		limite = apenasNumeros(limite, true);
		total = apenasNumeros(total, true);

		var conta1 = ((paginaAtual-1)*limite)+1;
		var conta2 = ((paginaAtual-1)*limite)+limite;

		if(paginaAtual === qtdPaginas) {
			conta2 = total;
		}

		return <div>Exibindo {conta1}-{conta2} de {total || conta2} resultados</div>;
	}

	return (
		<div className={`cpnt-contabilizarpg ${wrap}`}>
			<div className={`cpnt-contabilizarpg-box ${className}`}>
				{contabilizar()}
			</div>
		</div>
	);
};

export default ContabilizarPaginas;