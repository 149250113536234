import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalPDAsFormDespesas.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { converterRealParaNumero } from "@func/convert";
import { dataHoraMinSegApiParaDataApi } from "@func/date";
import { msgs } from "@func/jsx";
import { msgStatus } from "@func/requests";
import { camposInvalidos } from "@func/validate";
import FormPDADespesas from "@page/Logged/PDAs/FormPDADespesas/FormPDADespesas";
import { formatarDinheiro } from "@func/format";

const PortalPDAsFormDespesas = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id_pda: 0,
		pilotage_expense: 0,
		tug_hire: 0,
		launch_hire: 0,
		mooring: 0,
		linesmen: 0,
		wharfage: 0,
		gangway_watchmen: 0,
		light_dues: 0,
		entering_cleaning: 0,
		car_hire: 0,
		contribution: 0,
		petty: 0,
		garbage: 0,
		federal_tax: 0,
		municipal_tax: 0,
		port_system_fee: 0,
		agency_fee: 0
	};

	/*
	** Portais
	** ================================================== */
	const [showPortalConfirmacaoApagar, setShowPortalConfirmacaoApagar] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);
	const [loadingApagar, setLoadingApagar] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState({});
	const [stateDadosDespesas, setStateDadosDespesas] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setLoading(true);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await api.pdas.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);

			var expenses = retGetItem.data.expenses;
			var despesas = {};

			Object.keys(expenses).map((val, key) => {
				despesas[val] = formatarDinheiro(expenses[val])
			});

			setStateDadosDespesas(despesas);
			_setStateDados(msgStatus(retGetItem));
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}


	/*
	** Editar Navio
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id_pda: id,
			pilotage_expense: converterRealParaNumero(stateDadosDespesas.pilotage_expense),
			tug_hire: converterRealParaNumero(stateDadosDespesas.tug_hire),
			launch_hire: converterRealParaNumero(stateDadosDespesas.launch_hire),
			mooring: converterRealParaNumero(stateDadosDespesas.mooring),
			linesmen: converterRealParaNumero(stateDadosDespesas.linesmen),
			wharfage: converterRealParaNumero(stateDadosDespesas.wharfage),
			gangway_watchmen: converterRealParaNumero(stateDadosDespesas.gangway_watchmen),
			light_dues: converterRealParaNumero(stateDadosDespesas.light_dues),
			entering_cleaning: converterRealParaNumero(stateDadosDespesas.entering_cleaning),
			car_hire: converterRealParaNumero(stateDadosDespesas.car_hire),
			contribution: converterRealParaNumero(stateDadosDespesas.contribution),
			petty: converterRealParaNumero(stateDadosDespesas.petty),
			garbage: converterRealParaNumero(stateDadosDespesas.garbage),
			federal_tax: converterRealParaNumero(stateDadosDespesas.federal_tax),
			municipal_tax: converterRealParaNumero(stateDadosDespesas.municipal_tax),
			port_system_fee: converterRealParaNumero(stateDadosDespesas.port_system_fee),
			agency_fee: converterRealParaNumero(stateDadosDespesas.agency_fee),
		};

		if(camposInvalidos(dadosEnviar, [
			'id_pda',
		])) return;

		setLoadingEditar(true);

		const ret = await api.expenses.update.put(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Despesas atualizadas com sucesso.');
			onSuccess({
				ret: ret,
			})
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingEditar(false);
	}

	/*
	** Deletar Navio
	** ================================================== */
	const apagar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingApagar(true);

		const ret = await api.expenses.delete(id);

		if(ret.status === 200) {
			msg.success('Despesas removidas com sucesso.');
			onSuccess({
				ret: ret,
			});
			setShowPortalConfirmacaoApagar(false);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingApagar(false);
	}

	return (
		<Portal
			titulo="Editar despesas"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<FormPDADespesas
								onSubmit={editar}
								stateDados={stateDados}
								stateDadosDespesas={stateDadosDespesas}
								setStateDadosDespesas={setStateDadosDespesas}
								after={
									<div className="form-bottom">
										<Button
											_={{
												value: 'Apagar',
												className: 'btn btn-red',
												type: 'button',
												onClick: e => {
													setShowPortalConfirmacaoApagar(true);
												},
											}}
										/>
										<Button
											_={{
												value: 'Cancelar',
												className: 'btn btn-1',
												type: 'button',
												onClick: onClose,
											}}
											classNameWrap="margin-l-auto"
										/>
										<Button
											_={{
												value: 'Salvar dados',
												onClick: editar
											}}
											loading={loadingEditar}
											classNameWrap="margin-l-15"
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}

			<PortalConfirmacao
				show={showPortalConfirmacaoApagar}
				titulo="Deseja realmente apagar esse navio?"
				desc={
					<>
						<div>As despesas do PDA com ID "<strong>{stateDados.id}</strong>" serão removidas. Essa ação não poderá ser desfeita. Quaisquer dados associados a essas despesas também serão removidos.</div>
						<div className="margin-t-10">Deseja continuar?</div>
					</>
				}
				onClose={() => setShowPortalConfirmacaoApagar(false)}
				onClickCancelar={() => setShowPortalConfirmacaoApagar(false)}
				onClickConfirmar={apagar}
				loadingConfirmar={loadingApagar}
			/>
		</Portal>
	);
};

export default PortalPDAsFormDespesas;