import React from 'react';

// CSS
import '@css/variaveis.css';
import '@css/animations.css';
import '@css/frameworcss.css';
import '@css/partes.css';
import '@css/style.css';
import '@css/tabela.css';
import './LayoutInicio.css';

// Config
import { TITULO_PAGINA_DEFAULT, TITULO_PAGINA_PREFIXO } from '@config/config';

import imgLogoCargonavegroup from '@img/logo-cargonavegroup.svg';

const LayoutInicio = props => {

	var titulo = TITULO_PAGINA_DEFAULT;
	if(props.meta.title) {
		titulo = `${TITULO_PAGINA_PREFIXO}${props.meta.title}`;
	}

	window.document.title = titulo;

	const Cpnt = props.component;

	return (
		<div className="lt-inicio-container" {...props.container}>
			<div className="lt-inicio-left">
				<div className="lt-inicio-logo-box">
					<img src={imgLogoCargonavegroup} alt="logo-cargonavegroup" />
				</div>
			</div>
			<div className="lt-inicio-right">
				<Cpnt {...props} />
			</div>
		</div>
	);
}

export default LayoutInicio;
