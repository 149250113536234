import React from 'react';

// css
import c from './FormComplement.module.css';

// Routes
import Input from "@cpnt/Input/Input";

const FormComplement = props => {

	const {
		onSubmit,
		stateDados,
		setStateDados,
		after,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="box-txt">
						<div className="flex gap-20">
							<div className="flex-1">
								<Input
									label="ETA Time"
									_={{
										value: stateDados.eta_time,
										onChange: e => {
											setStateDados({
												...stateDados,
												eta_time: e.target.value,
											})
										},
										mask: '99:99',
									}}
									debounce
								/>
							</div>
							<div className="flex-1">
								<Input
									label="ETB Time"
									_={{
										value: stateDados.etb_time,
										onChange: e => {
											setStateDados({
												...stateDados,
												etb_time: e.target.value,
											})
										},
										mask: '99:99',
									}}
									debounce
								/>
							</div>
							<div className="flex-1">
								<Input
									label="ETS Time"
									_={{
										value: stateDados.ets_time,
										onChange: e => {
											setStateDados({
												...stateDados,
												ets_time: e.target.value,
											})
										},
										mask: '99:99',
									}}
									debounce
								/>
							</div>
						</div>
					</div>
				</div>

				{after}
			</form>
		</>
	);
};

export default FormComplement;