import React from 'react';

// CSS
import './Radio.css';

const Radio = props => {

	const {
		_,
		classNameWrap = '',
		classNameLabel = '',
		label,
		classNameSubLabel = '',
		invalid,
		smallLabel,
	} = props;

	return (
		<div className={`cpnt-radio ${classNameWrap}`}>
			<label className={classNameLabel}>
				<input
					type='radio'
					{..._}
				/>
				<span className="cpnt-radio-check"></span>
				<span className={`cpnt-radio-label ${classNameSubLabel} ${smallLabel ? 'cpnt-radio-label-small' : ''}`}>{label}</span>
			</label>
		</div>
	)
}

export default Radio;