import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiExpenses = {
	insert: {
		post: async dadosObj => {
			return await api.post(`/expenses/insert`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	update: {
		put: async dadosObj => {
			return await api.put(`/expenses/update`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	delete: async id => {
		return await api.delete(`/expenses/${id}`, config())
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	},
};

export { apiExpenses };