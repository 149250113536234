import React, { useContext, useEffect, useState } from 'react';

// Libs
import { Link, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import './RecuperarSenha.css';

// Componentes
import Button from "@cpnt/Button/Button";
import Input from "@cpnt/Input/Input";

// Funções
import { api } from "@api/api";
import Icone from "@cpnt/Icone/Icone";
import Info from "@cpnt/Info/Info";
import Loading from "@cpnt/Loading/Loading";
import { msgs } from "@func/jsx";
import { msg } from "@func/utils";
import { camposInvalidos, validarEmail } from "@func/validate";

import imgIconeVoltar from '@svg/solid/arrow-left.svg';

const RecuperarSenha = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();

	const camposDefault = {
		email: '',
		code: '',
		new_password: '',
	}

	const [loadingRecuperarSenha, setLoadingRecuperarSenha] = useState(false);
	const [loadingValidarCodigo, setLoadingValidarCodigo] = useState(false);
	const [loadingAlterarSenha, setLoadingAlterarSenha] = useState(false);
	const [stateDados, setStateDados] = useState(camposDefault);

	const [passo, setPasso] = useState(0);
	const [segundos, setSegundos] = useState(0);

	useEffect(() => {
		segundos > 0 && setTimeout(() => setSegundos(segundos - 1), 1000);
	}, [segundos]);

	const Voltar = () => {
		return (
			<div className="pg-recsenha-btn-voltar" onClick={e => {
				var passoAtual = passo;
				passoAtual--;
				if(passoAtual < 0) {
					passoAtual = 0;
				}
				setPasso(passoAtual);
			}}>
				<Icone img={imgIconeVoltar} cor="#3CA56A" />
				<span className="margin-l-10">Voltar</span>
			</div>
		)
	}

	const recuperarSenha = async (e) => {
		e.preventDefault();

		if(loadingRecuperarSenha) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			email: stateDados.email,
		};

		if(camposInvalidos(dadosEnviar)) return;

		if(!validarEmail(dadosEnviar.email)) return;

		setLoadingRecuperarSenha(true);

		const ret = await api.redefinition.callHash.put(dadosEnviar);
		// const ret = {
		// 	status: 200,
		// };

		if(ret.status === 200) {
			msg.success('E-mail enviado com sucesso.');
			setPasso(1);
			setSegundos(60);
		} else {
			msgs([ret]);
		}

		setLoadingRecuperarSenha(false);
	}

	const validarCodigo = async e => {
		e.preventDefault();

		if(loadingValidarCodigo) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			email: stateDados.email,
			code: stateDados.code,
		};

		if(camposInvalidos(dadosEnviar)) return;

		if(!validarEmail(dadosEnviar.email)) return;

		setLoadingValidarCodigo(true);

		const ret = await api.redefinition.validateHash.put(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Código validado com sucesso, insira a nova senha.');
			setPasso(2);
		} else {
			msgs([ret]);
		}

		setLoadingValidarCodigo(false);
	}

	const alterarSenha = async e => {
		e.preventDefault();

		if(loadingAlterarSenha) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			email: stateDados.email,
			code: stateDados.code,
			new_password: stateDados.new_password,
		};

		if(camposInvalidos(dadosEnviar)) return;

		if(!validarEmail(dadosEnviar.email)) return;

		setLoadingAlterarSenha(true);

		const ret = await api.redefinition.changePassword.put(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Senha alterada com sucesso.');
			setPasso(3);
		} else {
			msgs([ret]);
		}

		setLoadingAlterarSenha(false);
	}

	const renderTelaCorreta = () => {

		/*
		** Tela inicial
		** Redefinir senha
		** ================================================== */
		if(passo === 0) {
			return (
				<form onSubmit={recuperarSenha}>
					<h3 className="titulo">Recuperar Senha</h3>
					<div className="desc">
						<p>Enviaremos para seu e-mail um link com para a recuperação da senha.</p>
					</div>
					<div className="inputs-box">
						<div className="input-box">
							<Input
								label="E-mail"
								req
								_={{
									type: 'email',
									value: stateDados.email,
									onChange: e => setStateDados({...stateDados, email: e.target.value}),
								}}
							/>
						</div>
						<div className="input-box input-box-btn">
							<Button
								_={{
									value: 'Enviar',
									onClick: recuperarSenha,
								}}
								loading={loadingRecuperarSenha}
							/>
						</div>
						<div className="input-box input-box-btn align-center">
							<Link to="/login">Ir para login</Link>
						</div>
					</div>
				</form>
			)
		}

		/*
		** Código de validação
		** ================================================== */
		if(passo === 1) {
			return (
				<div>
					<h3 className="titulo">Recuperar Senha</h3>
					<Voltar />
					<form onSubmit={validarCodigo}>
						<div className="desc">
							<p>Foi enviado um e-mail para <strong>{stateDados.email}</strong> com um <strong>código</strong> de confirmação, insira-o abaixo para continuar.</p>
						</div>
						<div className="inputs-box">
							<div className="input-box">
								<Input
									label="Código de verificação"
									req
									_={{
										value: stateDados.code,
										onChange: e => setStateDados({...stateDados, code: e.target.value}),
									}}
								/>
							</div>
							<div className="input-box input-box-btn">
								<Button
									_={{
										value: 'Validar código',
										onClick: validarCodigo,
									}}
									loading={loadingValidarCodigo}
								/>
							</div>
							<div className="input-box input-box-btn align-center relative">
								{loadingRecuperarSenha ? <Loading /> : null}
								{segundos > 0 ? <div className="fill bg-white-6" onClick={e => msg.warning(`Você poderá solicitar o reenvio do código em ${segundos} segundos.`)}></div> : null}
								<span className="link" onClick={recuperarSenha}>Reenviar código{segundos > 0 ? ` (aguarde ${segundos} segundos)` : ''}</span>
							</div>
							<div className="input-box input-box-btn align-center">
								<Link to="/login">Ir para login</Link>
							</div>
						</div>
					</form>
				</div>
			)
		}

		/*
		** Redefinir senha
		** ================================================== */
		if(passo === 2) {
			return (
				<div>
					<h3 className="titulo">Recuperar Senha</h3>
					<Voltar />
					<form onSubmit={alterarSenha}>
						<div className="desc">
							<p>Código validado com sucesso, insira a nova senha.</p>
						</div>
						<div className="inputs-box">
							<div className="input-box">
								<Input
									label="Senha"
									req
									_={{
										type: 'password',
										value: stateDados.new_password,
										onChange: e => setStateDados({...stateDados, new_password: e.target.value}),
									}}
								/>
							</div>
							<div className="input-box input-box-btn">
								<Button
									_={{
										value: 'Confirmar alteração de senha',
										onClick: alterarSenha,
									}}
									loading={loadingAlterarSenha}
								/>
							</div>
							<div className="input-box input-box-btn align-center">
								<Link to="/login">Ir para login</Link>
							</div>
						</div>
					</form>
				</div>
			)
		}

		return (
			<div>
				<Info
					icone="sucesso"
					texto={
						<>
							<h3>Senha alterada com sucesso!</h3>
							<p>Você já pode fazer login utilizando sua nova senha.</p>
							<div className="margin-t-15 align-center"><Link to="/login">Fazer login</Link></div>
						</>
					}
				/>
			</div>
		)
	}

	return (
		<div className="pg-login">
			{renderTelaCorreta()}

			{/* <div className="flex flex-center">
				<span className="bg-1 padding-5" onClick={e => setPasso(passo - 1)}>Voltar</span> | <span className="bg-1 padding-5" onClick={e => setPasso(passo + 1)}>Próximo</span>
			</div> */}
		</div>
	)
}

export default RecuperarSenha;