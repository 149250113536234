import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalAcompanhamentosComplementFormCadastro.module.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormHold from "@page/Logged/Acompanhamentos/FormHold/FormHold";
import FormComplement from "@page/Logged/Acompanhamentos/FormComplement/FormComplement";
import { removerKeysVazias } from "@func/arrsObjs";

const PortalAcompanhamentosComplementFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
		onSuccess = () => null,
		onError = () => null,
		stateComplement,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		tracking_verssels_id: stateComplement.id,
		eta_time: '',
		etb_time: '',
		ets_time: '',
		...stateComplement.complement,
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [statePaises, setStatePaises] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			tracking_verssels_id: stateDados.tracking_verssels_id,
			eta_time: stateDados.eta_time,
			etb_time: stateDados.etb_time,
			ets_time: stateDados.ets_time,
		}

		if(camposInvalidos(dadosEnviar, [
			'tracking_verssels_id',
		], {
			tracking_verssels_id: 'Tracking Verssels ID',
		})) return;

		setLoadingCadastrar(true);

		var dadosEnviarSemCamposVazios = removerKeysVazias(dadosEnviar);
		const ret = await api.trackingVessels.complement.put(dadosEnviarSemCamposVazios);

		if(ret.status === 200) {
			msg.success('Complementos atualizados com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Editar complement"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormComplement
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Salvar complement',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalAcompanhamentosComplementFormCadastro;