// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BarraPorcentagem_porcentagem__RiNWf {}\n.BarraPorcentagem_porcentagem-inner__nogJw {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\ttext-align: center;\n\tposition: relative;\n\tborder: 1px solid #CCC;\n\tborder-radius: 30px;\n\tbackground-color: #FFF;\n\tpadding: 3px;\n\toverflow: hidden;\n}\n.BarraPorcentagem_porcentagem-numbero__FOCYE {\n\tposition: relative;\n\tz-index: 1;\n}\n.BarraPorcentagem_porcentagem-barra__Gpenz {\n\tposition: absolute;\n\tleft: 0;\n\ttop: 0;\n\tbottom: 0;\n\twidth: 0;\n\ttransition: all 1s ease 0s;\n\tbackground-color: #CAF0CC;\n}\n", "",{"version":3,"sources":["webpack://./src/components/BarraPorcentagem/BarraPorcentagem.module.css"],"names":[],"mappings":"AAAA,sCAAc;AACd;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,kBAAkB;CAClB,kBAAkB;CAClB,sBAAsB;CACtB,mBAAmB;CACnB,sBAAsB;CACtB,YAAY;CACZ,gBAAgB;AACjB;AACA;CACC,kBAAkB;CAClB,UAAU;AACX;AACA;CACC,kBAAkB;CAClB,OAAO;CACP,MAAM;CACN,SAAS;CACT,QAAQ;CACR,0BAA0B;CAC1B,yBAAyB;AAC1B","sourcesContent":[".porcentagem {}\n.porcentagem-inner {\n\tdisplay: flex;\n\talign-items: center;\n\tjustify-content: center;\n\ttext-align: center;\n\tposition: relative;\n\tborder: 1px solid #CCC;\n\tborder-radius: 30px;\n\tbackground-color: #FFF;\n\tpadding: 3px;\n\toverflow: hidden;\n}\n.porcentagem-numbero {\n\tposition: relative;\n\tz-index: 1;\n}\n.porcentagem-barra {\n\tposition: absolute;\n\tleft: 0;\n\ttop: 0;\n\tbottom: 0;\n\twidth: 0;\n\ttransition: all 1s ease 0s;\n\tbackground-color: #CAF0CC;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"porcentagem": "BarraPorcentagem_porcentagem__RiNWf",
	"porcentagem-inner": "BarraPorcentagem_porcentagem-inner__nogJw",
	"porcentagem-numbero": "BarraPorcentagem_porcentagem-numbero__FOCYE",
	"porcentagem-barra": "BarraPorcentagem_porcentagem-barra__Gpenz"
};
export default ___CSS_LOADER_EXPORT___;
