import { BYPASS_TESTE_MENU, DEV } from "@config/config";

/*
** Sidebar Admin
** ================================================== */
const MENU_ADMIN = {
	menu: [
		{
			title: 'Empresas',
			link: '/empresas',
			icon: 'empresas',
		},
		{
			title: 'Usuários',
			link: '/usuarios',
			icon: 'usuarios',
		},
		{
			title: 'Portos',
			link: '/portos',
			icon: 'portos',
		},
		{
			title: 'Cargos',
			link: '/cargos',
			icon: 'cargos',
		},
		{
			title: 'Navios',
			link: '/navios',
			icon: 'navios',
		},
		{
			title: 'Acompanhamentos',
			link: '/acompanhamento',
			icon: 'acompanhamento',
		},
		{
			title: 'Berços',
			link: '/bercos',
			icon: 'bercosAlt',
		},
		{
			title: 'Endereços',
			link: '/enderecos',
			icon: 'enderecosAlt',
		},
		{
			title: 'PDAs',
			link: '/pdas',
			icon: 'pdas',
		},
		{
			title: 'Line Up',
			link: '/line-up',
			icon: 'lineup',
		},
		{
			title: 'News',
			link: '/news',
			icon: 'news',
		},
		{
			title: 'Uploads',
			link: '/uploads',
			icon: 'uploads',
		},
	]
};

// Caso seja admin e em desenvolvimento, exibe página de testes
// if(BYPASS_TESTE_MENU) {
// 	MENU_ADMIN.menu.push({
// 		title: false,
// 		itens: [
// 			{
// 				title: 'Testes (dev)',
// 				link: '/testes',
// 				icon: 'pdas.svg',
// 			},
// 		]
// 	})
// }

export { MENU_ADMIN };
