import { LabelNavio } from "@cpnt/LabelNavio/LabelNavio";
import { LinhaItem } from "@cpnt/LinhaItem/LinhaItem";
import { dataApiParaDataHoraMinSeg } from "@func/date";
import React from 'react';

const DetalhesNavio = props => {
	const {
		stateDados,
		after,
	} = props;

	return (
		<>
			<div>
				<LinhaItem titulo="Nome" desc={stateDados.name} />
				<LinhaItem titulo="Status do navio" desc={<LabelNavio {...stateDados} />} />
				<LinhaItem titulo="Flag" desc={stateDados.id_country} />
				<LinhaItem titulo="DWT" desc={stateDados.deadweight} tip="Deadweight" />
				<LinhaItem titulo="GRT" desc={stateDados.gross_register_tonnage} tip="Gross Register Tonnage" />
				<LinhaItem titulo="LOA" desc={stateDados.length_overall} tip="Length Overall" />
				<LinhaItem titulo="Descrição" desc={stateDados.description} />
				<LinhaItem titulo="Status" desc={(stateDados.active ? 'Ativo' : <span className="color-red">Inativo</span>)} />
				<LinhaItem titulo="ID de cadastro" desc={stateDados.id} />
				<LinhaItem titulo="Cadastrado em" desc={dataApiParaDataHoraMinSeg(stateDados.created_at)} />
			</div>
			{after}
		</>

	);
};

export { DetalhesNavio };
