import React from 'react';

// CSS
import c from './ButtonUpload.module.css';

import Loading from "@cpnt/Loading/Loading";
import Icone from "@cpnt/Icone/Icone";
import imgIconeExcel from './file-spreadsheet.svg';

const ButtonUpload = props => {

	const {
		className = '',
		classNameDefault = `${c['btn']} ${c['btn-default']}`,
		value,
		loading,
		onClickLoading,
		onSelectFile,
	} = props;

	return (
		<label className={`${c['btn']} ${className ? className : 'inline-flex'} ${classNameDefault}`}>
			{loading && <span className="color-black" onClick={onClickLoading}><Loading absolute alt texto="Enviando, aguarde..." /></span>}
			<Icone
				img={imgIconeExcel}
				cor="#FFF"
			/>
			<span className="margin-l-10">{value}</span>
			{!loading && <input type="file" onChange={onSelectFile} className="none" />}
		</label>
	)
}

export { ButtonUpload };