import { useRoutes } from 'react-router-dom';

// Layout
import LayoutPainel from "@role/Layouts/LayoutPainel/LayoutPainel";

// Menu
import { MENU_ADMIN } from "@role/Menu/MenuAdmin";

// Páginas
import Logout from "@page/General/Logout/Logout";
import { RedirectTo } from "@page/General/RedirectTo/RedirectTo";
import { Bercos } from "@page/Logged/Bercos/Bercos";
import { Cargos } from "@page/Logged/Cargos/Cargos";
import { Empresas } from "@page/Logged/Empresas/Empresas";
import { Enderecos } from "@page/Logged/Enderecos/Enderecos";
import Erro404Logado from "@page/Logged/Erro404Logado/Erro404Logado";
import { LineUp } from "@page/Logged/LineUp/LineUp";
import { Navios } from "@page/Logged/Navios/Navios";
import { News } from "@page/Logged/News/News";
import { PDAs } from "@page/Logged/PDAs/PDAs";
import { Portos } from "@page/Logged/Portos/Portos";
import { Uploads } from "@page/Logged/Uploads/Uploads";
import { Usuarios } from "@page/Logged/Usuarios/Usuarios";
import LayoutBlank from "@role/Layouts/LayoutBlank/LayoutBlank";
import { Acompanhamentos } from "@page/Logged/Acompanhamentos/Acompanhamentos";

export const ROTAS_ADMIN = [
	{
		path: '/',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Empresas',
			}}
			component={Empresas}
		/>,
	},
	{
		path: '/empresas',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Empresas',
			}}
			component={Empresas}
		/>,
	},
	{
		path: '/usuarios',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Usuários',
			}}
			component={Usuarios}
		/>,
	},
	{
		path: '/portos',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Portos',
			}}
			component={Portos}
		/>,
	},
	{
		path: '/cargos',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Cargos',
			}}
			component={Cargos}
		/>,
	},
	{
		path: '/navios',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Navios',
			}}
			component={Navios}
		/>,
	},
	{
		path: '/acompanhamento',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Acompanhamentos',
			}}
			component={Acompanhamentos}
		/>,
	},
	{
		path: '/pdas',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'PDAs',
			}}
			component={PDAs}
		/>,
	},
	{
		path: '/line-up',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Line Up',
			}}
			component={LineUp}
		/>,
	},
	{
		path: '/bercos',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Berços',
			}}
			component={Bercos}
		/>,
	},
	{
		path: '/enderecos',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Endereços',
			}}
			component={Enderecos}
		/>,
	},
	{
		path: '/uploads',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Uploads',
			}}
			component={Uploads}
		/>,
	},
	{
		path: '/news',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'News',
			}}
			component={News}
		/>,
	},
	{
		path: '/login',
		element:
		<LayoutBlank
			meta={{
				title: 'Login',
				titleRemovePrefix: false,
			}}
			component={RedirectTo}
			redirectTo="/"
		/>,
	},

	/*
	** Rotas default
	** ================================================== */
	{
		path: '/logout',
		element:
		<Logout
			meta={{
				title: 'Fazendo logout, aguarde...',
			}}
		/>,
	},
	{
		path: '*',
		element:
		<LayoutPainel
			menu={MENU_ADMIN}
			meta={{
				title: 'Página não encontrada...',
				description: 'A página que você está procurando não existe.',
			}}
			component={Erro404Logado}
		/>,
	}
];

function ScreensAdmin() {
	const rotasAdmin = useRoutes(ROTAS_ADMIN);
	return rotasAdmin;
}

export default ScreensAdmin;