// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormNews_box-preview__gOjg8 {\n\tdisplay: flex;\n\tflex: 1 1;\n\talign-items: center;\n}\n.FormNews_box-preview-img__xJwg6 {\n\twidth: 150px;\n\tpadding-right: 15px;\n\tpadding: 5px 15px 5px 0;\n}\n.FormNews_box-preview-img__xJwg6 img {\n\tdisplay: block;\n\twidth: 100%;\n\tborder-radius: 5px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Logged/News/FormNews/FormNews.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,SAAO;CACP,mBAAmB;AACpB;AACA;CACC,YAAY;CACZ,mBAAmB;CACnB,uBAAuB;AACxB;AACA;CACC,cAAc;CACd,WAAW;CACX,kBAAkB;AACnB","sourcesContent":[".box-preview {\n\tdisplay: flex;\n\tflex: 1;\n\talign-items: center;\n}\n.box-preview-img {\n\twidth: 150px;\n\tpadding-right: 15px;\n\tpadding: 5px 15px 5px 0;\n}\n.box-preview-img img {\n\tdisplay: block;\n\twidth: 100%;\n\tborder-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box-preview": "FormNews_box-preview__gOjg8",
	"box-preview-img": "FormNews_box-preview-img__xJwg6"
};
export default ___CSS_LOADER_EXPORT___;
