import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalEnderecoFormEdicao.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { msgs } from "@func/jsx";
import { msgStatus } from "@func/requests";
import { camposInvalidos } from "@func/validate";
import FormEndereco from "@page/Logged/Enderecos/FormEndereco/FormEndereco";

const PortalEnderecoFormEdicao = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-900',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: 0,
		address_type: 0,
		street: '',
		number: '',
		complement: '',
		distric: '',
		city: '',
		state: '',
		zip_code: '',
		phone: '',
		fax: '',
		email: '',
	};

	/*
	** Portais
	** ================================================== */
	const [showPortalConfirmacaoApagar, setShowPortalConfirmacaoApagar] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);
	const [loadingApagar, setLoadingApagar] = useState(false);
	const [loadingGetTiposEnderecos, setLoadingGetTiposEnderecos] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateTiposEnderecos, setStateTiposEnderecos] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setLoading(true);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await api.address.getOne.get(id);
		_setStateDados(msgStatus(retGetItem));

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			apiGeral.addressType({setLoadingGetTiposEnderecos, setStateTiposEnderecos});
		} else {
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** Editar Berco
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id,
			address_type: stateDados.address_type,
			street: stateDados.street,
			number: stateDados.number,
			complement: stateDados.complement,
			distric: stateDados.distric,
			city: stateDados.city,
			state: stateDados.state,
			zip_code: stateDados.zip_code,
			phone: stateDados.phone,
			fax: stateDados.fax,
			email: stateDados.email,
		};

		if(camposInvalidos(dadosEnviar, [
			'id',
			'address_type',
			'street',
			'number',
			'distric',
			'city',
			'state',
			'zip_code',
		])) return;

		setLoadingEditar(true);

		const ret = await api.address.update.put(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Endereço atualizado com sucesso.');
			onSuccess({
				ret: ret,
			})
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingEditar(false);
	}

	/*
	** Deletar Berco
	** ================================================== */
	const apagar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingApagar(true);

		const ret = await api.address.delete(id);

		if(ret.status === 200) {
			msg.success('Endereço removido com sucesso.');
			onSuccess({
				ret: ret,
			});
			setShowPortalConfirmacaoApagar(false);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingApagar(false);
	}

	return (
		<Portal
			titulo="Editar Endereço"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<FormEndereco
								onSubmit={editar}
								stateDados={stateDados}
								setStateDados={setStateDados}
								stateTiposEnderecos={stateTiposEnderecos}
								loadingGetTiposEnderecos={loadingGetTiposEnderecos}
								after={
									<div className="form-bottom">
										<Button
											_={{
												value: 'Apagar',
												className: 'btn btn-red',
												type: 'button',
												onClick: e => {
													setShowPortalConfirmacaoApagar(true);
												},
											}}
										/>
										<Button
											_={{
												value: 'Cancelar',
												className: 'btn btn-1',
												type: 'button',
												onClick: onClose,
											}}
											classNameWrap="margin-l-auto"
										/>
										<Button
											_={{
												value: 'Salvar dados',
												onClick: editar
											}}
											loading={loadingEditar}
											classNameWrap="margin-l-15"
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}

			<PortalConfirmacao
				show={showPortalConfirmacaoApagar}
				titulo="Deseja realmente apagar esse Endereço?"
				desc={
					<>
						<div>O endereço "<strong>{stateDados.street}, {stateDados.number || '(sem número)'} - {stateDados.distric} / {stateDados.state}</strong>" será removido. Essa ação não poderá ser desfeita. Qualquer dado associado a esse Endereço também será removido.</div>
						<div className="margin-t-10">Deseja continuar?</div>
					</>
				}
				onClose={() => setShowPortalConfirmacaoApagar(false)}
				onClickCancelar={() => setShowPortalConfirmacaoApagar(false)}
				onClickConfirmar={apagar}
				loadingConfirmar={loadingApagar}
			/>
		</Portal>
	);
};

export default PortalEnderecoFormEdicao;