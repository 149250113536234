export const NOMES_CAMPOS = {
	name: 'Nome',
	password: 'Senha',
	email: 'E-mail',
	email_responsible: 'E-mail do responsável',
	cpf_cnpj: 'CPF ou CNPJ',
	accept_terms: 'Termos de uso',
	accept_issuing_invoice: 'Termos de Nota Fiscal',
	id_bank: 'ID do banco',
	full_name: 'Nome completo',
	agency: 'Agência',
	account: 'Conta',
	cpf: 'CPF',
	pix_value: 'PIX',
	pix_type: 'Tipo de chave PIX',
	accept_date_payment: 'Termos de data de pagamento',
	actual_password: 'Senha atual',
	new_password: 'Nova senha',
	password_confirmation: 'Confirmação de senha',
	city: 'Cidade',
	complement: 'Complemento',
	district: 'Bairro',
	number: 'Número',
	state: 'Estado',
	street: 'Logradouro',
	zip_code: 'CEP',
	company: 'Empresa',
	description: 'Descrição',
	start_date: 'Data inicial',
	end_date: 'Data final',
	title: 'Título',
	id_user: 'Usuário',
	id_project: 'Projeto',
	id_freelancer: 'Freelancer',
	id_area: 'Área de atuação',
	id_company: 'Empresa',
	id_position: 'Cargo',
	social_reason: 'Razão social',
	name_responsible: 'Nome do responsável',
	position_responsible: 'Cargo do responsável',
	id_customer: 'Cliente',
	hash: 'Token',
	message: 'Mensagem',
	phone: 'Telefone',
	role: 'Cargo',
	url: 'URL',
	issuer: 'Emissor',
	issuer_date: 'Data de emissão',
	issue_date: 'Data de emissão',
	percentage: 'Porcentagem',
	id_state: 'Localização',
	cargo_type: 'Tipo de cargo/commodity',
	code: 'Código',
	id_country: 'Flag',

	deadweight: 'DWT (Deadweight)',
	gross_register_tonnage: 'GRT (Gross Register Tonnage)',
	length_overall: 'LOA (Length Overall)',

	id_port: 'Porto',
	id_cargo: 'Cargo',
	operation_type: 'Tipo de operações',
	berth: 'Berço',
	id_vessel: 'Navio',

	address_type: 'Tipo de endereço',
};

export const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
export const dias = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

export const MESES_NUMERO = [
	{label: 'Janeiro', value: 1},
	{label: 'Fevereiro', value: 2},
	{label: 'Março', value: 3},
	{label: 'Abril', value: 4},
	{label: 'Maio', value: 5},
	{label: 'Junho', value: 6},
	{label: 'Julho', value: 7},
	{label: 'Agosto', value: 8},
	{label: 'Setembro', value: 9},
	{label: 'Outubro', value: 10},
	{label: 'Novembro', value: 11},
	{label: 'Dezembro', value: 12},
];

export const localidade = {
	localize: {
		month: n => meses[n],
		day: n => dias[n]
	},
	formatLong: {}
};

export const FILTROS_RESULTADOS = [
	{value: 10, label: '10 resultados'},
	{value: 20, label: '20 resultados'},
	{value: 30, label: '30 resultados'},
	{value: 40, label: '40 resultados'},
	{value: 50, label: '50 resultados'},
	{value: 60, label: '60 resultados'},
	{value: 70, label: '70 resultados'},
	{value: 80, label: '80 resultados'},
	{value: 90, label: '90 resultados'},
	{value: 100, label: '100 resultados'},
];

export const STATUS_FILTRO = [
	{label: 'Ativo', value: 1},
	{label: 'Inativo', value: 0},
	{label: 'Todos', value: 2},
];

export const STATUS_DEFAULT = [
	{label: 'Ativo', value: 1},
	{label: 'Inativo', value: 0},
];

export const STATUS_DEFAULT_TF = [
	{label: 'Ativo', value: true},
	{label: 'Inativo', value: false},
];
