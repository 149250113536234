import { LinhaItem } from "@cpnt/LinhaItem/LinhaItem";
import { dataApiParaDataHoraMinSeg } from "@func/date";
import React from 'react';

const DetalhesCargo = props => {
	const {
		stateDados,
		after,
	} = props;

	return (
		<>
			<div>
				<LinhaItem titulo="Nome" desc={stateDados.name} />
				<LinhaItem titulo="Tipo de cargo/commodity" desc={stateDados.cargo_type} />
				<LinhaItem titulo="Status" desc={(stateDados.active ? 'Ativo' : <span className="color-red">Inativo</span>)} />
				<LinhaItem titulo="ID de cadastro" desc={stateDados.id} />
				<LinhaItem titulo="Cadastrado em" desc={dataApiParaDataHoraMinSeg(stateDados.created_at)} />
			</div>
			{after}
		</>

	);
};

export { DetalhesCargo };
