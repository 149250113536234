import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalEnderecoFormCadastro.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormEndereco from "@page/Logged/Enderecos/FormEndereco/FormEndereco";

const PortalEnderecoFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-900',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		address_type: 0,
		street: '',
		number: '',
		complement: '',
		distric: '',
		city: '',
		state: '',
		zip_code: '',
		phone: '',
		fax: '',
		email: '',
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetTiposEnderecos, setLoadingGetTiposEnderecos] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateTiposEnderecos, setStateTiposEnderecos] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		apiGeral.addressType({setLoadingGetTiposEnderecos, setStateTiposEnderecos});
	}

	/*
	** Editar Berco
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			address_type: stateDados.address_type,
			street: stateDados.street,
			number: stateDados.number,
			complement: stateDados.complement,
			distric: stateDados.distric,
			city: stateDados.city,
			state: stateDados.state,
			zip_code: stateDados.zip_code,
			phone: stateDados.phone,
			fax: stateDados.fax,
			email: stateDados.email,
		};

		if(camposInvalidos(dadosEnviar, [
			'address_type',
			'street',
			'number',
			'distric',
			'city',
			'state',
			'zip_code',
		])) return;

		setLoadingCadastrar(true);

		const ret = await api.address.insert.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Endereço cadastrado com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Cadastrar Endereço"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormEndereco
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				stateTiposEnderecos={stateTiposEnderecos}
				loadingGetTiposEnderecos={loadingGetTiposEnderecos}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Cadastrar Endereço',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalEnderecoFormCadastro;