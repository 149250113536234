import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FormEmpresa.css';

// Routes
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";

const FormEmpresa = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isCadastro,
		stateDados,
		setStateDados,
		after,
		onSuccessStatus,
		onErrorStatus,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="flex flex-wrap">
						<div className="input-box box-1">
							<Input
								label="Nome da empresa"
								req
								_={{
									value: stateDados.name,
									onChange: e => setStateDados({
										...stateDados,
										name: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-1">
							<Input
								label="E-mail"
								req
								_={{
									value: stateDados.email,
									onChange: e => {
										setStateDados({
											...stateDados,
											email: e.target.value,
										});
									},
								}}
							/>
						</div>
						<div className="input-box box-1">
							<Input
								label="Telefone"
								req
								_={{
									value: stateDados.phone,
									onChange: e => {
										setStateDados({
											...stateDados,
											phone: e.target.value,
										})
									}
								}}
							/>
						</div>
					</div>
					{!isCadastro ? (
						<div className="input-box box-1">
							<Checkbox
								label="Status"
								_={{
									checked: stateDados.active,
									onChange: e => {
										setStateDados({
											...stateDados,
											active: e.target.checked ? 1 : 0,
										});
									},
								}}
								classNameWrap="padding-t-15"
							/>
						</div>
					) : null}
				</div>

				{after}
			</form>
		</>
	);
};

export default FormEmpresa;