import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalUsuariosFormAtivacao.module.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import FormEmpresa from "@page/Logged/Empresas/FormEmpresa/FormEmpresa";
import { msgStatus } from "@func/requests";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import Button from "@cpnt/Button/Button";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import FormUsuario from "@page/Logged/Usuarios/FormUsuario/FormUsuario";
import { formatarSelect } from "@func/components";
import { DetalhesUsuario } from "@page/Logged/Usuarios/DetalhesUsuario/DetalhesUsuario";

const PortalUsuariosFormAtivacao = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-500',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: 0,
	};

	/*
	** Portais
	** ================================================== */
	const [showPortalConfirmacaoAtivar, setShowPortalConfirmacaoAtivar] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingAtivar, setLoadingAtivar] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setLoading(true);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await api.users.getOne.get(id);
		_setStateDados(msgStatus(retGetItem));

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
		} else {
			msgs([retGetItem]);
		}

		setLoading(false);
	}

	/*
	** Ativar usuário
	** ================================================== */
	const ativar = async e => {
		e.preventDefault();

		if(loadingAtivar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			id: stateDados.id,
		};

		if(camposInvalidos(dadosEnviar, [
			'id',
		])) return;

		setLoadingAtivar(true);

		const ret = await api.users.validate.put(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Usuário ativado com sucesso. O usuário também foi informado por e-mail da ativação do seu cadastro.', {
				autoClose: 10000
			});
			onSuccess({
				ret: ret,
			})
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingAtivar(false);
	}

	return (
		<Portal
			titulo="Ativar usuário"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<div>
								<DetalhesUsuario
									stateDados={stateDados}
									after={
										<div className="form-bottom">
											<Button
												_={{
													value: 'Cancelar',
													className: 'btn btn-1',
													type: 'button',
													onClick: onClose,
												}}
												classNameWrap="margin-l-auto"
											/>
											<Button
												_={{
													value: 'Ativar cadastro',
													type: 'button',
													onClick: e => {
														setShowPortalConfirmacaoAtivar(true);
													},
												}}
												classNameWrap="margin-l-15"
											/>
										</div>
									}
								/>
							</div>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}

			<PortalConfirmacao
				show={showPortalConfirmacaoAtivar}
				titulo="Deseja realmente ativar o cadastro desse usuário?"
				desc={
					<>
						<div>O cadastro do usuário "<strong>{stateDados.name}</strong>" será ativado. Esse usuário também será notificado por e-mail sobre a sua ativação.</div>
						<div className="margin-t-10">Caso necessário, você poderá desativar esse cadastro editando o usuário e alterando seu status.</div>
						<div className="margin-t-10">Deseja continuar com a ativação?</div>
					</>
				}
				onClose={() => setShowPortalConfirmacaoAtivar(false)}
				onClickCancelar={() => setShowPortalConfirmacaoAtivar(false)}
				onClickConfirmar={ativar}
				loadingConfirmar={loadingAtivar}
				classNameBtnConfirmar="btn btn-default btn-green"
			/>
		</Portal>
	);
};

export default PortalUsuariosFormAtivacao;