import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FormEndereco.css';

// Routes
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";

const FormEndereco = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isCadastro,
		stateDados,
		setStateDados,
		after,
		stateTiposEnderecos,
		loadingGetTiposEnderecos,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<h3 className="titulo margin-t-0">Endereço</h3>
				<div className="wrap margin-t-5">
					<div className="flex flex-wrap">
						<div className="input-box box-1">
							<Select
								label="Tipo de endereço"
								req
								_={{
									value: stateDados.address_type,
									onChange: e => setStateDados({
										...stateDados,
										address_type: e.value,
									}),
									options: stateTiposEnderecos,
								}}
								wrap="flex-1"
								loading={loadingGetTiposEnderecos}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="Logradouro"
								req
								_={{
									value: stateDados.street,
									onChange: e => setStateDados({
										...stateDados,
										street: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="Número"
								req
								_={{
									value: stateDados.number,
									onChange: e => setStateDados({
										...stateDados,
										number: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="Bairro"
								req
								_={{
									value: stateDados.distric,
									onChange: e => setStateDados({
										...stateDados,
										distric: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="Cidade"
								req
								_={{
									value: stateDados.city,
									onChange: e => setStateDados({
										...stateDados,
										city: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="Estado"
								req
								_={{
									value: stateDados.state,
									onChange: e => setStateDados({
										...stateDados,
										state: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="Complemento"
								_={{
									value: stateDados.complement,
									onChange: e => setStateDados({
										...stateDados,
										complement: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="CEP"
								req
								_={{
									value: stateDados.zip_code,
									onChange: e => setStateDados({
										...stateDados,
										zip_code: e.target.value,
									}),
								}}
							/>
						</div>
					</div>
				</div>

				<h3 className="titulo">Contato</h3>
				<div className="wrap margin-t-5">
					<div className="flex flex-wrap">
						<div className="input-box box-3 box-1-780">
							<Input
								label="Telefone"
								_={{
									value: stateDados.phone,
									onChange: e => setStateDados({
										...stateDados,
										phone: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="Fax"
								_={{
									value: stateDados.fax,
									onChange: e => setStateDados({
										...stateDados,
										fax: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="E-mail"
								_={{
									type: 'email',
									value: stateDados.email,
									onChange: e => setStateDados({
										...stateDados,
										email: e.target.value,
									}),
								}}
							/>
						</div>
					</div>
				</div>

				{after}
			</form>
		</>
	);
};

export default FormEndereco;