import React from 'react';
import Placeholder from '@cpnt/Placeholder/Placeholder';
import { DEV } from '@config/config';

const PlaceholderBercos = props => {
	const {
		itensQtd,
	} = props;

	return (
		<tr>
			<td className="col-id">
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<div className="flex flex-center-v flex-1">
						<Placeholder tipo="circulo" />
						<div className="w-100p">
							<Placeholder tipo="linha" wrap="w-100p" />
						</div>
					</div>
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
			<td>
				<div className="td-wrap">
					<Placeholder tipo="linha" />
				</div>
			</td>
		</tr>
	)
}

export { PlaceholderBercos };
