import { LinhaItem } from "@cpnt/LinhaItem/LinhaItem";
import { dataApiParaDataHoraMinSeg } from "@func/date";
import React from 'react';

const DetalhesUsuario = props => {
	const {
		stateDados,
		after,
	} = props;

	return (
		<>
			<div>
				<LinhaItem titulo="Nome do usuário" desc={stateDados.name} />
				<LinhaItem titulo="E-mail" desc={<a href={`mailto:${stateDados.email}`}>{stateDados.email}</a>} />
				{stateDados.company_name ? <LinhaItem titulo="Empresa" desc={stateDados.company_name} /> : null}
				<LinhaItem titulo="ID do usuário" desc={stateDados.id} />
				<LinhaItem titulo="Cadastrado em" desc={dataApiParaDataHoraMinSeg(stateDados.created_at)} />
			</div>

			{after}
		</>

	);
};

export { DetalhesUsuario };
