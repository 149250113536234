import React from 'react';

// Componentes
import { LinhaItem } from "@cpnt/LinhaItem/LinhaItem";

// Funções
import { dataApiParaDataHoraMinSeg } from "@func/date";

const DetalhesEndereco = props => {
	const {
		stateDados,
		after,
	} = props;

	return (
		<>
			<div>
				<h3 className="titulo margin-t-0">Endereço</h3>
				<div className="margin-t-10">
					<LinhaItem titulo="Tipo de endereço" desc={stateDados.type_name} />
					<LinhaItem titulo="Logradouro" desc={stateDados.street} />
					<LinhaItem titulo="Número" desc={stateDados.number || '(sem número)'} />
					<LinhaItem titulo="Bairro" desc={stateDados.distric} />
					<LinhaItem titulo="Cidade" desc={stateDados.city} />
					<LinhaItem titulo="Estado" desc={stateDados.state} />
					<LinhaItem titulo="Complemento" desc={stateDados.complement} />
					<LinhaItem titulo="CEP" desc={stateDados.zip_code} />
				</div>
			</div>
			<div className="margin-t-30">
				<h3 className="titulo">Contato</h3>
				<div className="margin-t-10">
					<LinhaItem titulo="Telefone" desc={stateDados.phone} />
					<LinhaItem titulo="Fax" desc={stateDados.fax} />
					<LinhaItem titulo="E-mail" desc={stateDados.email} />
				</div>
			</div>
			<div className="margin-t-30">
				<h3 className="titulo">Dados do cadastro</h3>
				<div className="margin-t-10">
					<LinhaItem titulo="ID do endereço" desc={stateDados.id} />
					<LinhaItem titulo="Cadastrado em" desc={dataApiParaDataHoraMinSeg(stateDados.created_at)} />
				</div>
			</div>
			{after}
		</>

	);
};

export { DetalhesEndereco };
