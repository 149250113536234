import React from 'react';

import { dataApiParaDataMesDiaAno } from "@func/date";

import './DetalhesPDA.css';
import { PDALinhaDespesas } from "./PDALinhaDespesas/PDALinhaDespesas";
import { LinhaItem } from "@cpnt/LinhaItem/LinhaItem";

const DetalhesPDA = props => {
	const {
		stateDados,
		after,
	} = props;

	return (
		<>
			<h3 className="margin-b-10  margin-t-0">Informações básicas</h3>
			<div className="box box-tabela">
				<div className="input-box box-3 box-1-980">
					<div className="strong">Nome da empresa</div>
					<div>{stateDados.company_name}</div>
				</div>
				<div className="input-box box-3 box-1-980">
					<div className="strong">ID de cadastro</div>
					<div>{stateDados.id}</div>
				</div>
				<div className="input-box box-3 box-1-980">
					<div className="strong">Data de criação</div>
					<div>{dataApiParaDataMesDiaAno(stateDados.created_at)}</div>
				</div>
			</div>

			<h3 className="margin-b-10 padding-t-20">Informações do navio</h3>
			<div className="box box-tabela">
				<div className="input-box box-3 box-1-980">
					<div className="strong">Porto</div>
					<div>{stateDados.port_name}</div>
				</div>
				<div className="input-box box-3 box-1-980">
					<div className="strong">Navio</div>
					<div>{stateDados.vessel_name}</div>
				</div>
				<div className="input-box box-3 box-1-980">
					<div className="strong">Flag</div>
					<div>{stateDados.flag}</div>
				</div>

				<div className="input-box box-5 box-1-980">
					<div className="strong">Dias atracados</div>
					<div>{stateDados.days_inner_road}</div>
				</div>
				<div className="input-box box-5 box-1-980">
					<div className="strong">Dias fundeados</div>
					<div>{stateDados.days_alongside}</div>
				</div>
				<div className="input-box box-5 box-1-980">
					<div className="strong">DWT</div>
					<div>{stateDados.deadweight}</div>
				</div>
				<div className="input-box box-5 box-1-980">
					<div className="strong">GRT</div>
					<div>{stateDados.gross_register_tonnage}</div>
				</div>
				<div className="input-box box-5 box-1-980">
					<div className="strong">LOA</div>
					<div>{stateDados.length_overall}</div>
				</div>
			</div>

			<h3 className="margin-b-10 padding-t-20">Informações da carga</h3>
			<div className="box box-tabela">
				<div className="input-box box-3 box-1-980">
					<div className="strong">Berço</div>
					<div>{stateDados.berth_name}</div>
				</div>
				<div className="input-box box-3 box-1-980">
					<div className="strong">Tipo de cargo/Commodity</div>
					<div>{stateDados.cargo_type.name}</div>
				</div>
				<div className="input-box box-3 box-1-980">
					<div className="strong">Nome do cargo</div>
					<div>{stateDados.cargo_name}</div>
				</div>

				<div className="input-box box-3 box-1-980">
					<div className="strong">Operação</div>
					<div>{stateDados.operation_type}</div>
				</div>
				<div className="input-box box-3 box-1-980">
					<div className="strong">Quantidade (t)</div>
					<div>{stateDados.quantity}</div>
				</div>
				<div className="input-box box-3 box-1-980">
					<div className="strong">Rate USD</div>
					<div>{stateDados.rate_usd}</div>
				</div>
			</div>

			<h3 className="margin-b-0 padding-t-20">Despesas dos Portos</h3>
			<LinhaItem titulo="Despesas adicionadas em" desc={dataApiParaDataMesDiaAno(stateDados.expenses.created_at || '0')} />

			<div className="box box-tabela margin-t-15">
				<div className="input-box box-1 none-680">
					<PDALinhaDespesas
						titulo="Tipo de despesa"
						dolar="Dólar"
						real="Real"
						header
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Pilotage expenses"
						dolar={stateDados.expenses.pilotage_expense || '0'}
						real={stateDados.expensesBRL.pilotage_expense}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Tug hire"
						dolar={stateDados.expenses.tug_hire || '0'}
						real={stateDados.expensesBRL.tug_hire}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Launch hire"
						dolar={stateDados.expenses.launch_hire || '0'}
						real={stateDados.expensesBRL.launch_hire}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Mooring/unmooring"
						dolar={stateDados.expenses.mooring || '0'}
						real={stateDados.expensesBRL.mooring}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Linesman"
						dolar={stateDados.expenses.linesmen || '0'}
						real={stateDados.expensesBRL.linesmen}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Wharfage"
						dolar={stateDados.expenses.wharfage || '0'}
						real={stateDados.expensesBRL.wharfage}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Gangway watchman"
						dolar={stateDados.expenses.gangway_watchmen || '0'}
						real={stateDados.expensesBRL.gangway_watchmen}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Light dues"
						dolar={stateDados.expenses.light_dues || '0'}
						real={stateDados.expensesBRL.light_dues}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Entering & Clearing"
						dolar={stateDados.expenses.entering_cleaning || '0'}
						real={stateDados.expensesBRL.entering_cleaning}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Car hire"
						dolar={stateDados.expenses.car_hire || '0'}
						real={stateDados.expensesBRL.car_hire}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Contribution"
						dolar={stateDados.expenses.contribution || '0'}
						real={stateDados.expensesBRL.contribution}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Pettv expenses (sundries)"
						dolar={stateDados.expenses.petty || '0'}
						real={stateDados.expensesBRL.petty}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Garbage"
						dolar={stateDados.expenses.garbage || '0'}
						real={stateDados.expensesBRL.garbage}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Fedefal tax"
						dolar={stateDados.expenses.federal_tax || '0'}
						real={stateDados.expensesBRL.federal_tax}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Municipal tax"
						dolar={stateDados.expenses.municipal_tax || '0'}
						real={stateDados.expensesBRL.municipal_tax}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Port system fee"
						dolar={stateDados.expenses.port_system_fee || '0'}
						real={stateDados.expensesBRL.port_system_fee}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Agency fee"
						dolar={stateDados.expenses.agency_fee || '0'}
						real={stateDados.expensesBRL.agency_fee}
					/>
				</div>
				<div className="input-box box-1">
					<PDALinhaDespesas
						titulo="Total de despesas"
						dolar={typeof(stateDados.total_expenses) === 'number' ? stateDados.total_expenses.toFixed(2) : stateDados.total_expenses}
						real={stateDados.total_expensesBRL}
						footer
					/>
				</div>
			</div>

			{after}
		</>

	);
};

export { DetalhesPDA };
