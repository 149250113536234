/*
** Funções de CSS e styles
** ================================================== */

export const cssTamanho = (tamanho) => {
	return {
		minWidth: tamanho,
		maxWidth: tamanho,
		minHeight: tamanho,
		maxHeight: tamanho,
	};
}

export const cssBg = img => {
	return {
		backgroundImage: `url('${img}')`,
	};
}

export const cssCorImagem = (dados = {}) => {
	var {
		width,
		height,
		cor,
		img,
	} = dados;

	var tamanho;

	if(width || height) {
		tamanho = width;
	}

	return {
		...(tamanho ? {width: tamanho, height: tamanho} : {}),
		...(cor ? {backgroundColor: cor} : {}),
		WebkitMaskImage: `url(${img})`,
		maskImage: `url(${img})`
	};
}
