import React from 'react';

// css
import c from './BarraPorcentagem.module.css';

const BarraPorcentagem = props => {

	const {
		className = '',
		classNameWrap = '',
		porcentagem = 0,
		width = '100%',
	} = props;

	var style = {};
	if(width) {
		style = {minWidth: width, maxWidth: width};
	}

	var porcent = porcentagem;

	if(porcent < 0) {
		porcent = 0;
	} else if(porcent > 100) {
		porcent = 100;
	}

	return (
		<div className={classNameWrap}>
			<div className={`${c['porcentagem']} ${className}`} style={style}>
				<div className={`${c['porcentagem-inner']}`}>
					<div className={`${c['porcentagem-numbero']}`}>{porcentagem}%</div>
					<div className={`${c['porcentagem-barra']}`} style={{width: `${porcent}%`}}></div>
				</div>
			</div>
		</div>
	);
};

export { BarraPorcentagem };
