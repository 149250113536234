
export const tamanhoTela = (dadosArr, retornoDefault, screen) => {

	if(!dadosArr || typeof(dadosArr) !== 'object' || dadosArr[0] === undefined) {
		return retornoDefault;
	}

	var retorno = retornoDefault;
	var encontrado = false;
	var dadosArrReverse = dadosArr.reverse();

	dadosArrReverse.map((val, key) => {
		if(!encontrado) {
			if(val.width >= screen.width) {
				encontrado = true;
				retorno = val.dados;
			}
		}
	});

	if(!encontrado) {
		retorno = retornoDefault;
	}

	return retorno;
}
