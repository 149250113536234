export const initSetFiltros = ({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup}) => {
	const filtrosAtuais = new URLSearchParams(window.location.search);

	var filtrosLoad = {};

	if(filtrosAtuais && typeof(filtrosAtuais) === 'object') {
		for (const [key, value] of filtrosAtuais.entries()) {
			filtrosLoad[key] = filtrosAtuais.get(key) || '';
		}
	}
	setStateFiltros({
		...filtrosDefault,
		...filtrosLoad,
	});
	refFiltrosAtuais.current = filtrosLoad;
	refGetBackup.current = window.location.search
}

export const setFiltrosURL = (refFiltrosAtuais) => {
	const query = new URLSearchParams(refFiltrosAtuais.current).toString();
	window.history.pushState({}, '', `?${query}`);
};
