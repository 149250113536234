import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiTrackingVessels = {
	all: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/trackingVessels/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	getOne: {
		get: async id => {
			return await api.get(`/trackingVessels/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	holds: {
		insertMany: {
			post: async dadosObj => {
				return await api.post('/trackingVessels/holds/insertMany', dadosObj, config())
				.then(retorno => {
					return validarRetorno(retorno);
				})
				.catch(retorno => {
					return validarRetorno(retorno);
				});
			},
		}
	},
	complement: {
		put: async dadosObj => {
			return await api.put(`/trackingVessels/complement`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	companies: {
		insertMany: {
			post: async dadosObj => {
				return await api.post('/trackingVessels/companies/insertMany', dadosObj, config())
				.then(retorno => {
					return validarRetorno(retorno);
				})
				.catch(retorno => {
					return validarRetorno(retorno);
				});
			},
		}
	},
};

export { apiTrackingVessels };