import { useRoutes } from 'react-router-dom';

// Layout
import LayoutPainel from "@role/Layouts/LayoutPainel/LayoutPainel";

// Menu
import { MENU_USUARIO } from "@role/Menu/MenuUsuario";

// Páginas
import Logout from "@page/General/Logout/Logout";
import { BemVindo } from "@page/Logged/BemVindo/BemVindo";
import Erro404Logado from "@page/Logged/Erro404Logado/Erro404Logado";
import LayoutBlank from "@role/Layouts/LayoutBlank/LayoutBlank";
import { RedirectTo } from "@page/General/RedirectTo/RedirectTo";

export const ROTAS_USUARIO = [
	{
		path: '/',
		element:
		<LayoutPainel
			menu={MENU_USUARIO}
			meta={{
				title: 'BemVindo',
			}}
			component={BemVindo}
		/>,
	},

	/*
	** Rotas default
	** ================================================== */
	{
		path: '/login',
		element:
		<LayoutBlank
			meta={{
				title: 'Login',
				titleRemovePrefix: false,
			}}
			component={RedirectTo}
			redirectTo="/"
		/>,
	},
	{
		path: '/logout',
		element:
		<Logout
			meta={{
				title: 'Fazendo logout, aguarde...',
			}}
		/>,
	},
	{
		path: '*',
		element:
		<LayoutPainel
			menu={MENU_USUARIO}
			meta={{
				title: 'Página não encontrada...',
				description: 'A página que você está procurando não existe.',
			}}
			component={Erro404Logado}
		/>,
	}
];

function ScreensUsuario() {
	const rotasUsuario = useRoutes(ROTAS_USUARIO);
	return rotasUsuario;
}

export default ScreensUsuario;