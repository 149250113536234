import React from 'react';

// CSS
import './Erro404Logado.css';

// Imagens

const Erro404Logado = props => {
	return (
		<div className="base">
			<div className="base-content">
				<div className="padding-t-50">
					<h1 className="margin-0 fs-26">Página não encontrada...</h1>
					<p className="margin-0 margin-t-5">Erro 404 - A página que você está procurando não existe.</p>
				</div>
			</div>
		</div>
	)
}

export default Erro404Logado;