import React from 'react';
import { toast as msgToast } from 'react-toastify';
import { removerKeysVazias } from "./arrsObjs";
import { consoleFormData } from "./requests";

/*
** Funções de arquivos
** ================================================== */

export const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, { lastModified: new Date().getTime(), type: theBlob.type });
}

export const extensaoPorMimeType = mime => {
	var ext = false;
	if(typeof(mime) === 'object') {
		if(typeof(mime.type) === 'string') {
			var extArr = mime.type.split('/');
			if(extArr.length === 2) {
				ext = extArr[1];
			}
		}
	}
	return ext;
}


export const kbToMb = (valorEmBytes, retornarDescricao = false) => {
	const unidades = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let l = 0, n = parseInt(valorEmBytes, 10) || 0;
	while(n >= 1024 && ++l){
		n = n/1024;
	}
	var valorFinal = n.toFixed(n < 10 && l > 0 ? 1 : 0);
	if(retornarDescricao) {
		valorFinal = `${valorFinal} ${unidades[l]}`;
	}
	return valorFinal;
}

export const typeFileSelecionarImagem = async dados => {
	const {
		e,
		formatos = ['image/png', 'image/jpg', 'image/jpeg'],
		tamanhoMax = 5120, // em MB // 5MB
		msgSucesso = 'Arquivo carregado com sucesso',
		msgInvalido = 'Arquivo não permitido. Selecione uma foto com o formato jpg, jpeg ou png.',
		msgIncorreto = 'Parece que sua foto está corrompida ou com alguma assinatura inválida, envie outra foto.',
		msgTamanho = 'O arquivo selecionado é muito grande, selecione um arquivo menor.',
		exibirTamanhoMax = true,
		cb = () => null,
	} = dados;

	var img = {};
	if(e && e.target && e.target.files && e.target.files[0]) {
		img = e.target.files[0];
	}

	var tamanho = tamanhoMax * 1024;

	if(img.size > tamanho) {
		msgToast.error(
			<>
				<div>{msgTamanho}</div>
				{exibirTamanhoMax ? (
					<div className="margin-t-10">Tamanho máximo: {kbToMb(tamanho, true)}</div>
				) : null}
			</>
		)
		cb({
			sucesso: false,
			msg: msgTamanho,
			erro: 'tamanho',
			tamanho: img.size,
			tamanhoMax: tamanhoMax,
			tamanhoMaxMb: tamanho,
		});
		return;
	}

	if(formatos.includes(img.type)) {
		let reader = new FileReader();
		reader.readAsDataURL(img);
		reader.onload = eImg => {
			let imagemBase64 = eImg.target.result;
			if(imagemBase64) {
				cb({
					sucesso: true,
					base64: imagemBase64,
					msg: msgSucesso,
				});
				return;
			} else {
				cb({
					sucesso: false,
					msg: msgIncorreto,
					erro: 'incorreto',
				});
				return;
			}
		};
	} else {
		cb({
			sucesso: false,
			msg: msgInvalido,
			erro: 'invalido',
		});
		return;
	}
}

export const dadosEnviarFormData = async dadosObj => {
	const {
		dadosEnviar,
		arquivo = '',
		keyArquivo = 'logo',
		nomeArquivo = 'foto',
		showConsoleLog,
	} = dadosObj;

	var retorno = dadosEnviar;

	if(arquivo && arquivo !== '') {
		const extensao = arquivo.match(/[^:/]\w+(?=;|,)/);
		if(extensao !== null) {

			var file = await fetch(arquivo).then(r => r.blob());

			if(!file.name) {
				file = blobToFile(file, `${nomeArquivo}.${extensaoPorMimeType(file)}`);
			}

			var dadosFormData = new FormData();

			retorno = removerKeysVazias(dadosEnviar);

			Object.keys(retorno).map((val, key) => {
				dadosFormData.append(val, retorno[val]);
			});

			dadosFormData.append(keyArquivo, file);

			retorno = dadosFormData;
			if(showConsoleLog) {
				consoleFormData(retorno);
			}
		}
	} else {
		retorno = removerKeysVazias(dadosEnviar);
	}
	return retorno;
}

export const extensaoString = string => {
	if(typeof(string) === 'string') {
		return string.match(/[^:/]\w+(?=;|,)/);
	}
	return false;
}
