import { formatarSelect } from "@func/components";
import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';
import { msgs } from "@func/jsx";

const apiAddressType = {
	all: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/addressType/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	getOne: {
		get: async id => {
			return await api.get(`/addressType/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	insert: {
		post: async dadosObj => {
			return await api.post(`/addressType/insert`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	update: {
		put: async dadosObj => {
			return await api.put(`/addressType/update`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	delete: async id => {
		return await api.delete(`/addressType/${id}`, config())
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	},
};

export { apiAddressType };

/*
** Tipos de endereços
** ================================================== */
export const apiAddressTypeGeral = async ({setLoadingGetTiposEnderecos, setStateTiposEnderecos, showMsg = true}) => {
	setLoadingGetTiposEnderecos(true);
	const ret = await apiAddressType.all.get({limit: 999});
	if(ret.status === 200) {
		setStateTiposEnderecos(formatarSelect({dados: ret.data}));
	} else if(showMsg) {
		msgs([ret]);
	}
	setLoadingGetTiposEnderecos(false);
	return ret;
}
