import Tip from "@cpnt/Tip/Tip";
import React from 'react';

// css
import './LinhaItem.css';

const LinhaItem = props => {
	const {
		titulo = '',
		desc = '',
		tip,
		multiline,
	} = props;

	return (
		<>
			<div className="cpnt-linhaitem">
				<strong>{titulo}{tip ? <Tip tip={<span className="q q-line"></span>} _defaults={{placement: 'right-center'}}>{tip}</Tip> : ''}:</strong>
				<span className={`margin-l-5 ${multiline ? 'cpnt-linhaitem-multiline' : ''}`}>{desc}</span>
			</div>
		</>
	);
};

export { LinhaItem };