// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormPorto_box-preview__A2P5r {\n\tdisplay: flex;\n\tflex: 1 1;\n\talign-items: center;\n}\n.FormPorto_box-preview-img__D\\+Hlb {\n\twidth: 150px;\n\tpadding-right: 15px;\n\tpadding: 5px 15px 5px 0;\n}\n.FormPorto_box-preview-img__D\\+Hlb img {\n\tdisplay: block;\n\twidth: 100%;\n\tborder-radius: 5px;\n}", "",{"version":3,"sources":["webpack://./src/pages/Logged/Portos/FormPorto/FormPorto.module.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,SAAO;CACP,mBAAmB;AACpB;AACA;CACC,YAAY;CACZ,mBAAmB;CACnB,uBAAuB;AACxB;AACA;CACC,cAAc;CACd,WAAW;CACX,kBAAkB;AACnB","sourcesContent":[".box-preview {\n\tdisplay: flex;\n\tflex: 1;\n\talign-items: center;\n}\n.box-preview-img {\n\twidth: 150px;\n\tpadding-right: 15px;\n\tpadding: 5px 15px 5px 0;\n}\n.box-preview-img img {\n\tdisplay: block;\n\twidth: 100%;\n\tborder-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box-preview": "FormPorto_box-preview__A2P5r",
	"box-preview-img": "FormPorto_box-preview-img__D+Hlb"
};
export default ___CSS_LOADER_EXPORT___;
