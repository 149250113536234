import React, { useContext } from 'react';

// Store
import StoreContext from '@context/StoreContext';

// Config
import { TITULO_PAGINA_PREFIXO, TITULO_PAGINA_DEFAULT } from '@config/config';

const LayoutBlank = (props) => {

	const [store, setStore] = useContext(StoreContext);

	var titulo = TITULO_PAGINA_DEFAULT;
	if(props.meta.title) {
		titulo = `${TITULO_PAGINA_PREFIXO}${props.meta.title}`;
	}

	window.document.title = titulo;

	const Cpnt = props.component;

	return (
		<div className={`container container-blank ${props.classContainer ? props.classContainer : ''}`}>
			<Cpnt {...props} />
		</div>
	);
}

export default LayoutBlank;
