import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiRains = {
	insert: {
		post: async (dadosObj, dadosGet) => {
			var dadosEnviar = qs.stringify(dadosGet, { encode: false });
			return await api.post(`/rains/insert?${dadosEnviar}`, dadosObj, {
				...config(),
				'Content-Type': 'multipart/form-data',
				'timeout': 600000,
			})
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
};

export { apiRains };