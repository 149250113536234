/*
** Funções de componentes
** ================================================== */
import _ from 'lodash';
import { STATUS_DEFAULT, STATUS_DEFAULT_TF } from "@config/objetos";

export const formatarSelect = (dadosObj = {}) => {
	// dadosArr, keyIcone = false, caminhoFotoAuto = true, iconClass = false
	const {
		dados = [],
		keyValue = 'id',
		keyName = 'name',
		keyIcone,
		iconClass,
		caminhoFotoDefault = true,
	} = dadosObj;

	var retorno = [];
	if(typeof(dados) && dados.length) {
		dados.map((val) => {
			var obj = {
				...val,
				value: val[keyValue],
				label: val[keyName],
			};
			if(keyIcone) {
				if(val[keyIcone] !== undefined) {
					if(caminhoFotoDefault) {
						obj.icon = val[keyIcone];
						// obj.icon = caminhoFoto(val[keyIcone]).url;
					} else {
						obj.icon = val[keyIcone];
					}
					if(iconClass) {
						obj.iconClass = iconClass;
					}
				}
			}
			retorno.push(obj);
		});
	}
	return retorno;
}

export const statusDefault = status => {
	var statusNome = '(sem status)';
	var cont = 0;
	if(status || status === 0) {
		var statusNomeObj = _.find(STATUS_DEFAULT, {value: status});
		if(statusNomeObj && statusNomeObj.label) {
			statusNome = statusNomeObj.label;
			cont++;
		}
	}
	if(cont === 0) {
		if(status || status === false) {
			var statusNomeTfObj = _.find(STATUS_DEFAULT_TF, {value: status});
			if(statusNomeTfObj && statusNomeTfObj.label) {
				statusNome = statusNomeTfObj.label;
			}
		}
	}
	return statusNome;
}
