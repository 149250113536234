import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';

// Store
import StoreContext from '@context/StoreContext';

// Screens
import ScreensAdmin from '@role/Screens/ScreensAdmin';
import ScreensGerais from '@role/Screens/ScreensGerais';

// CSS
import '@css/default.css';
import '@css/fonts.css';
import '@css/libs/react-toastify/react-toastify.css';
import 'react-toastify/dist/ReactToastify.css';

// Funções úteis
import isLogado from '@func/auth';

// Temp
import ScreensSemRestricoes, { ROTAS_SEM_RESTRICOES } from "@role/Screens/ScreensSemRestricoes";
import ScreensUsuario from "@role/Screens/ScreensUsuarios";

const Rotas = (props) => {

	const logado = isLogado();
	const location = useLocation();

	var dados = {
		token: false,
		user: {},
		config: {},
		...logado,
	};

	/*
	** Store
	** ================================================== */
	const [store, setStore] = useState(dados);

	const isTelaSemRestricao = () => {
		var rotaLivre = false;
		if(location.pathname && location.pathname !== '' && location.pathname !== '/') {
			ROTAS_SEM_RESTRICOES.map(val => {
				if(val.path === location.pathname) {
					rotaLivre = true;
				}
			})
		}
		return rotaLivre;
	}

	return (
		<>
			<StoreContext.Provider value={[store, setStore]}>
				{isTelaSemRestricao() ? (
					<ScreensSemRestricoes />
				) : (
					dados.token && dados.token !== '' && dados.user && dados.user !== '' ? (
						<>
							{dados.user.role === 1 && <ScreensAdmin />}
							{dados.user.role === 2 && <ScreensUsuario />}
							{(!dados.user.role || (dados.user.role <= 0) || (dados.user.role >= 4)) && <ScreensGerais />}
						</>
					) : (
						<ScreensGerais />
					)
				)}

				<ToastContainer
					position="bottom-right"
					autoClose={6000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					transition={Slide}
				/>
			</StoreContext.Provider>
		</>
	)
}

export default Rotas;