import React from 'react';

// CSS
import './Info2.css';

// Imagens
import iconeSucesso from '@img/icone-sucesso.png';
import iconeErro from '@img/icone-erro.png';
import iconeInfo from '@img/icone-info.png';
import iconeAlerta from '@img/icone-alerta.png';
import iconeLoading from '@img/loading-azul.svg';
import Icone from '../Icone/Icone';

const Info2 = props => {

	const {
		icone,
		titulo = '',
		desc = '',
		alt,
		iconeCor,
	} = props;

	var iconeExibir = icone;

	if(icone && typeof(icone) !== 'string') {
		iconeExibir = icone;
	} else if(icone === 'sucesso') {
		iconeExibir = iconeSucesso;
	} else if(icone === 'erro') {
		iconeExibir = iconeErro;
	} else if(icone === 'loading') {
		iconeExibir = iconeLoading;
	} else if(icone === 'info') {
		iconeExibir = iconeInfo;
	} else if(icone === 'alerta') {
		iconeExibir = iconeAlerta;
	}

	return (
		<div className={`cpnt-info2-conteudo`}>
			<div className="cpnt-info2-wrap">

				<div className="cpnt-info2-inner">
					<div className={`cpnt-info2-icone-wrap ${alt ? 'cpnt-info2-icone-wrap-alt' : ''}`}>
						<div className="cpnt-info2-icone">
							<Icone img={iconeExibir} tamanho="50px" {...(iconeCor ? {cor: iconeCor} : {})} />
						</div>
					</div>

					<div className={`cpnt-info2-info`}>
						<div className="cpnt-info2-titulo">{titulo}</div>
						<div className="cpnt-info2-desc">{desc}</div>
					</div>

				</div>

			</div>
		</div>
	)
}

export default Info2;