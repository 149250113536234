import React from 'react';
import Pagination from 'react-js-pagination';
import { scroller } from 'react-scroll';

// css
import './Paginacao.css';

// Imagens
import imgIconePrevAll from './angles-left.svg';
import imgIconeNextAll from './angles-right.svg';
import imgIconePrev from './chevron-left.svg';
import imgIconeNext from './chevron-right.svg';

// Componentes
import Icone from '@cpnt/Icone/Icone';
import { isNumeric } from "@func/numbers";
import Loading from '../Loading/Loading';

const Paginacao = props => {
	var {
		wrap = '',
		className = '',
		totalItens,
		totalPaginas,
		qtdItensPorPagina,
		paginaAtual = 0,
		inativo,
		loading,
		changeFiltro,
	} = props;

	if(isNumeric(totalPaginas)) totalPaginas = Number(totalPaginas);
	if(isNumeric(totalItens)) totalItens = Number(totalItens);
	if(isNumeric(paginaAtual)) paginaAtual = Number(paginaAtual);
	if(isNumeric(qtdItensPorPagina)) qtdItensPorPagina = Number(qtdItensPorPagina);

	const definirPagina = e => {
		changeFiltro('page', e);
		scrollTo();
	}

	const definirPaginaInput = e => {
		var value = Number(e.target.value);
		if(!isNaN(value)) {
			if(value >= totalPaginas) {
				value = totalPaginas;
			} else if(value <= 0) {
				value = 1;
			}
			changeFiltro('page', value);
		}
	}

	const scrollTo = () => {
		// scroller.scrollTo('tabela-container', {
		// 	duration: 300,
		// 	delay: 0,
		// 	smooth: 'ease',
		// 	offset: 1,
		// });
	}

	return (
		<div className={`cpnt-paginacao ${wrap} ${inativo ? 'opct-5' : ''}`}>
			{loading && <Loading absolute alt />}
			{inativo && <div className="fill z-index-10"></div>}
			<div className={`cpnt-paginacao-box ${className}`}>
				<div className="cpnt-paginacao-info-paginas">
					<div className="cpnt-paginacao-info-texto">
						Página
					</div>
					<div className="cpnt-paginacao-info-input">
						<input
							type="text"
							value={paginaAtual}
							onChange={definirPaginaInput}
						/>
					</div>
					<div className="cpnt-paginacao-info-btn">
						de {totalPaginas || 1}
					</div>
				</div>
				<div className="cpnt-paginacao-componente">
					<Pagination
						activePage={paginaAtual}
						itemsCountPerPage={qtdItensPorPagina}
						innerClass="cpnt-paginacao-lista"
						activeClass="cpnt-paginacao-item-ativo"
						linkClass="cpnt-paginacao-btn-next-prev-box"
						totalItemsCount={totalItens || 0}
						pageRangeDisplayed={5}
						onChange={definirPagina}
						prevPageText={
							<div className="flex flex-center">
								<Icone
									img={imgIconePrev}
									tamanho="16px"
								/>
								<span className="cpnt-paginacao-texto-prev-next padding-b-3 padding-l-5 fs-14">
									Anterior
								</span>
							</div>
						}
						nextPageText={
							<div className="flex flex-center">
								<span className="cpnt-paginacao-texto-prev-next padding-b-3 padding-r-5 fs-14">
									Próximo
								</span>
								<Icone
									img={imgIconeNext}
									tamanho="16px"
								/>
							</div>
						}
						firstPageText={<Icone img={imgIconePrevAll} tamanho="16px" />}
						lastPageText={<Icone img={imgIconeNextAll} tamanho="16px" />}
					/>
				</div>
			</div>
		</div>
	);
};

export default Paginacao;