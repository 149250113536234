import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiNews = {
	all: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/news/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	getOne: {
		get: async id => {
			return await api.get(`/news/getOne/${id}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	insert: {
		post: async (dadosBody, dadosGet) => {
			// var dadosEnviar = qs.stringify(dadosGet, { encode: false });
			return await api.post(`/news/insert`, dadosBody, {
				...config(),
				'Content-Type': 'multipart/form-data',
				'timeout': 600000,
			})
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	update: {
		put: async dadosObj => {
			return await api.put(`/news/update`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	delete: async id => {
		return await api.delete(`/news/${id}`, config())
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	},
};

export { apiNews };