// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("@img/x-branco.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonX_btn-x__HO00E {\n\tdisplay: inline-flex;\n\twidth: 30px;\n\theight: 30px;\n\tborder-radius: 50%;\n\tcursor: pointer;\n\tbackground: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n\tbackground-color: var(--color-red);\n\tbackground-size: 50%;\n}\n", "",{"version":3,"sources":["webpack://./src/components/ButtonX/ButtonX.module.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,eAAe;CACf,2EAA4D;CAC5D,kCAAkC;CAClC,oBAAoB;AACrB","sourcesContent":[".btn-x {\n\tdisplay: inline-flex;\n\twidth: 30px;\n\theight: 30px;\n\tborder-radius: 50%;\n\tcursor: pointer;\n\tbackground: url('@img/x-branco.png') no-repeat center center;\n\tbackground-color: var(--color-red);\n\tbackground-size: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn-x": "ButtonX_btn-x__HO00E"
};
export default ___CSS_LOADER_EXPORT___;
