import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import React from 'react';
import ReactDOMServer from 'react-dom/server';
import { Link } from 'react-router-dom';
import { toast as msgToast } from 'react-toastify';

// Config
import { EMAIL_CONTATO } from '@config/config';

/*
** Funções de JSX
** ================================================== */

export const msgs = (arrRetornoApi, exibirSucesso = false) => {

	var camposInvalidosRetorno = [];

	if(arrRetornoApi.length > 0) {
		arrRetornoApi.map((valueRequest) => {
			if(valueRequest.status >= 200 && valueRequest.status <= 299) {
				if(exibirSucesso) {
					msgToast.success(valueRequest.message);
				}
			} else if(valueRequest.status >= 400) {
				// Campos inválidos
				if(typeof(valueRequest.invalidFields) === 'object') {
					var msgAgrupadas = [];
					valueRequest.invalidFields.map((value, key) => {
						camposInvalidosRetorno.push([value.param]);
						msgAgrupadas.push(<div key={key} className={`${valueRequest.invalidFields.length === 1 ? '' : 'msgs-lista-item msgs-lista-item-error'}`}>{value.msg}</div>);
						return false;
					});
					msgToast.error(<div className="msgs-lista">{msgAgrupadas}</div>);
				} else if(valueRequest.status === 401) {
					msgToast.error(<div className="msgs-lista"><div>{valueRequest.error}</div><div className="margin-t-15">Caso o erro persista, tente deslogar e logar novamente.</div><div className="margin-t-15">Se preferir, <Link className="strong a underline" to="/logout">clique aqui</Link> para acessar a tela de login.</div></div>);
				} else if(valueRequest.error !== undefined) {
					msgToast.error(<div className="msgs-lista">{valueRequest.error}</div>);
				} else if(valueRequest.status >= 400 && valueRequest.status <= 499) {
					msgToast.error(<div className="msgs-lista">{valueRequest.message}</div>);
				} else if(valueRequest.status >= 500) {
					if(valueRequest.error) {
						msgToast.error(<div className="msgs-lista">{valueRequest.error}</div>, {autoClose: false});
					} else if(valueRequest.exception) {
						msgToast.error(<div className="msgs-lista">{valueRequest.exception}</div>, {autoClose: false});
					} else {
						msgToast.error(<div className="msgs-lista">Houve algum problema ao fazer a sua requisição para a API. Caso o erro persista, por favor informe o erro através do e-mail: {EMAIL_CONTATO}.</div>, {autoClose: false});
					}
				}
			} else if(valueRequest.status === 0) {
				msgToast.error(valueRequest.message, {autoClose: false});
			} else {
				msgToast.warning(valueRequest.error);
			}
		});
	}

	return camposInvalidosRetorno;
}

export const html = jsx => {
	return ReactDOMServer.renderToStaticMarkup(jsx);
}

export const jsxToHtml = jsx => {
	return ReactDOMServer.renderToStaticMarkup(jsx);
}

export const sanitize = (htmlString, replace = {}, opts = {}) => {
	if(typeof(htmlString) === 'string') {
		var ok = htmlString.replace(/(^|;)\s*font-[^;]+/g,"");
		return DOMPurify.sanitize(ok);
	}
	return '';
}

export const htmlToJsx = (htmlString, replace = {}, opts = {}) => {
	return parse(sanitize(htmlString), {
		...replace,
		...opts,
	});
}
