import React, { useEffect, useRef, useState } from 'react';

// css
import './Enderecos.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Button from "@cpnt/Button/Button";
import { CirculoLetras } from "@cpnt/CirculoLetras/CirculoLetras";
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import Input from "@cpnt/Input/Input";
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";
import PortalEnderecoDetalhes from "@cpnt/Portais/PortaisEnderecos/PortalEnderecoDetalhes/PortalEnderecoDetalhes";
import PortalEnderecoFormCadastro from "@cpnt/Portais/PortaisEnderecos/PortalEnderecoFormCadastro/PortalEnderecoFormCadastro";
import PortalEnderecoFormEdicao from "@cpnt/Portais/PortaisEnderecos/PortalEnderecoFormEdicao/PortalEnderecoFormEdicao";
import { Select } from "@cpnt/Select/Select";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";

// Objetos
import { FILTROS_RESULTADOS, STATUS_FILTRO } from "@config/objetos";

// Func
import { statusDefault } from "@func/components";
import { msgs } from "@func/jsx";
import { msgStatus, tratarFiltrosApi } from "@func/requests";
import { initSetFiltros, setFiltrosURL } from "@func/routeRequest";
import { msg } from "@func/utils";

const Enderecos = props => {

	/*
	** Filtros
	** ================================================== */
	const filtrosDefault = {
		limit: 20,
		page: 1,
		search: '',
		address_type: '',
	};
	const [stateFiltros, setStateFiltros] = useState(filtrosDefault);

	// Ref filtros
	const refRequestFeito = useRef(false);
	const refFiltrosAtuais = useRef(false);
	const refGetBackup = useRef(window.location.search);
	const refIniciado = useRef(false);


	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastro: {},
		edicao: {},
		detalhes: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	/*
	** States
	** ================================================== */
	const [stateEnderecos, setStateEnderecos] = useState([]);
	const [_stateEnderecos, _setStateEnderecos] = useState({});
	const [stateTiposEnderecos, setStateTiposEnderecos] = useState([]);

	/*
	** Loading
	** ================================================== */
	const [loadingGetEnderecos, setLoadingGetEnderecos] = useState(false);
	const [loadingGetTiposEnderecos, setLoadingGetTiposEnderecos] = useState(false);

	/*
	** Init - define filtros
	** ================================================== */
	useEffect(() => {
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
		apiGeral.addressType({setLoadingGetTiposEnderecos, setStateTiposEnderecos});
	}, []);

	useEffect(() => {
		if (!refGetBackup.current !== window.location.search) {
			getEnderecos();
		}
		refIniciado.current = true;
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, [window.location.search]);

	/*
	** API - Get Enderecos
	** ================================================== */
	const getEnderecos = async () => {
		if(loadingGetEnderecos) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = tratarFiltrosApi({
			limit: refFiltrosAtuais.current.limit,
			search: refFiltrosAtuais.current.search,
			address_type: refFiltrosAtuais.current.address_type,
			page: refFiltrosAtuais.current.page,
		});

		setLoadingGetEnderecos(true);

		const ret = await api.address.all.get(dadosEnviar);
		_setStateEnderecos(msgStatus(ret));

		refRequestFeito.current = true;

		if(ret.status === 200) {
			setStateEnderecos(ret.data);
		} else {
			msgs([ret]);
		}

		setLoadingGetEnderecos(false);
	}

	const changeFiltro = (filterName, filterValue) => {
		var page = stateFiltros.page;
		if(filterName !== 'page') { page = 1; } else { page = filterValue; }
		setStateFiltros({ ...stateFiltros, [filterName]: filterValue, page: page });
		refFiltrosAtuais.current = {...stateFiltros, [filterName]: filterValue, page: page};
		setFiltrosURL(refFiltrosAtuais);
		refRequestFeito.current = false;
	};

	return (
		<>
			<div className={`pg-enderecos`}>
				<div>
					<FiltrosContainer>
						<div className="titulo cpnt-baract-titulo color-1">
							Endereços
						</div>
						<FiltrosWrap>
							<form
								onSubmit={e => e.preventDefault()}
								className="cpnt-baract-filtros"
							>
								<div className="input-box flex-1 box-1-980">
									<Input
										label="Pesquisar endereços"
										_={{
											value: stateFiltros.search,
        									onChange: e => changeFiltro('search', e.target.value)
										}}
										debounce
									/>
								</div>
								<div className="input-box flex-1 box-1-980">
									<Select
										label="Tipo"
										_={{
											value: stateFiltros.address_type,
        									onChange: e => changeFiltro('address_type', e ? e.value : ''),
											options: stateTiposEnderecos,
											isClearable: true,
										}}
										loading={loadingGetTiposEnderecos}
									/>
								</div>
								<div className="input-box flex-1 box-1-980 input-box-btnlimpar">
									<Select
										label="Resultados"
										_={{
											value: stateFiltros.limit,
        									onChange: e => changeFiltro('limit', e.value),
											options: FILTROS_RESULTADOS,
										}}
										wrap="flex-1"
									/>
								</div>
							</form>
						</FiltrosWrap>
						<Button
							_={{
								value: 'Adicionar endereço',
								className: 'btn btn-default-alt',
								onClick: e => {
									setPortais({
										...portais,
										cadastro: {
											show: true,
										}
									})
								},
							}}
						/>
					</FiltrosContainer>

					<div className="base">
						<div className="base-content">
							<div id="tabela-container">
								<TotalPaginacao metadata={_stateEnderecos} paginacao={stateFiltros} loading={loadingGetEnderecos} changeFiltro={changeFiltro} />
								<div className="table-wrap">
									<table className="tabela">
										<thead>
											<tr>
												<th className="col-id">
													ID
												</th>
												<th className="">
													Endereço
												</th>
												<th className="">
													E-mail
												</th>
												<th className="col-btn">
													CEP
												</th>
												<th className="col-telefone">
													Telefone
												</th>
												<th className="col-btn">
													Editar
												</th>
												<th className="col-btn">
													Visualizar
												</th>
											</tr>
										</thead>
										<tbody>
											<PlaceholderRender
												loading={loadingGetEnderecos}
												itens={stateEnderecos}
												request={_stateEnderecos}
												cpnt="PlaceholderEnderecos"
												qtdColunas={7}
											>
												{stateEnderecos.map((val, key) => {
													return (
														<tr key={key}>
															<td data-titulo="ID" className="col-id">
																<div className="td-wrap">
																	{val.id}
																</div>
															</td>
															<td data-titulo="Endereço">
																<div className="td-wrap">
																	{val.street}, {val.number || '(sem número)'} - {val.distric} / {val.state}
																</div>
															</td>
															<td data-titulo="E-mail">
																<div className="td-wrap">
																	{val.email}
																</div>
															</td>
															<td data-titulo="CEP">
																<div className="td-wrap">
																	{val.zip_code}
																</div>
															</td>
															<td data-titulo="Telefone">
																<div className="td-wrap">
																	{val.phone}
																</div>
															</td>
															<td data-titulo="Editar" className="">
																<Button
																	_={{
																		value: 'Editar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				edicao: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
															<td data-titulo="Visualizar" className="">
																<Button
																	_={{
																		value: 'Visualizar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				detalhes: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
														</tr>
													)
												})}
											</PlaceholderRender>
										</tbody>
									</table>
								</div>
								<TotalPaginacao metadata={_stateEnderecos} paginacao={stateFiltros} loading={loadingGetEnderecos} changeFiltro={changeFiltro} />
							</div>
						</div>
					</div>
				</div>

			</div>

			<PortalEnderecoDetalhes
				show={portais.detalhes.show}
				id={portais.detalhes.id}
				onClose={() => {
					setPortais({...portais, detalhes: {}})
				}}
			/>

			<PortalEnderecoFormCadastro
				show={portais.cadastro.show}
				onClose={() => {
					setPortais({...portais, cadastro: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastro: {}});
					getEnderecos();
				}}
				closeOnEsc={false}
			/>

			<PortalEnderecoFormEdicao
				show={portais.edicao.show}
				id={portais.edicao.id}
				onClose={() => {
					setPortais({...portais, edicao: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, edicao: {}});
					getEnderecos();
				}}
				closeOnEsc={false}
			/>
		</>
	);
};

export { Enderecos };
