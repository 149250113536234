import React, { useState } from 'react';
import Icone from '../Icone/Icone';

// css
import './FiltrosWrap.css';

// Imagens
import imgIconeHide from './angle-up.svg';
import imgIconeShow from './angle-down.svg';

const FiltrosWrap = props => {
	const {
		children,
		className = '',
		wrap = '',
		textoShowFiltros = 'Exibir filtros',
		textoHideFiltros = 'Ocultar filtros',
	} = props;

	const [stateShow, setStateShow] = useState(false);

	return (
		<div className={`cpnt-filtroswrap ${wrap}`}>
			<div className={`cpnt-filtroswrap-box ${className}`}>
				<div className="cpnt-filtros-wrap-btn-box">
					<div
						className="cpnt-filtroswrap-btn"
						onClick={e => {
							setStateShow(!stateShow);
						}}
					>
						<span>{stateShow ? textoHideFiltros : textoShowFiltros}</span>
						<Icone
							img={stateShow ? imgIconeHide : imgIconeShow}
							cor="#3CA56A"
							className="margin-l-10"
						/>
					</div>
				</div>
				<div className={`cpnt-filtroswrap-conteudo ${stateShow ? 'cpnt-filtroswrap-show' : ''}`}>
					{children}
				</div>
			</div>
		</div>
	);
};

export default FiltrosWrap;