import React from 'react';

// css
import c from './PortalNewsPreview.module.css';

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import { cssBg } from "@func/css";
import imgPlaceholder from './placeholder.jpg';

const PortalNewsPreview = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
		stateDados = {},
	} = props;

	return (
		<Portal
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
			classNameDefault=""
			classNameConteudo={c['portal-conteudo']}
			classNameX="cpnt-portal-close-alt"
			// classNameXExtra={c['x']}
		>
			<div className={wrap}>
				<div className={className}>
					<div className={c['wrapper']}>
						<div className={c['frame']}>
							<div className={c['container']}>
								<div className={c['imagem']} style={cssBg(stateDados.photo ? stateDados.photo : imgPlaceholder)}></div>
								<div className={c['box-info']}>
									<h3 className={c['titulo']}>{stateDados.title}</h3>
									<div className={c['desc']}>{stateDados.text}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
};

export default PortalNewsPreview;