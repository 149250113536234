import { SolicitarExclusaoDados } from "@page/General/SolicitarExclusaoDados/SolicitarExclusaoDados";
import LayoutInicio from "@role/Layouts/LayoutInicio/LayoutInicio";
import { useRoutes } from 'react-router-dom';

// Screens
export const ROTAS_SEM_RESTRICOES = [
	{
		path: '/personal-data-deletion-request',
		element:
		<LayoutInicio
			meta={{
				title: 'Personal Data Deletion Request',
			}}
			component={SolicitarExclusaoDados}
		/>,
	},
];

function ScreensSemRestricoes() {
	const rotasGerais = useRoutes(ROTAS_SEM_RESTRICOES);
	return rotasGerais;
}

export default ScreensSemRestricoes;