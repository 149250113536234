import React, { useState, useEffect } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalPortosFormCadastro.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormPorto from "@page/Logged/Portos/FormPorto/FormPorto";
import { formatarSelect } from "@func/components";

const PortalPortosFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		name: '',
		description: '',
		id_state: '',
		active: 1,
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetEstados, setLoadingGetEstados] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateEstados, setStateEstados] = useState([]);
	const [stateFoto, setStateFoto] = useState(null);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		apiGeral.states({setLoadingGetEstados, setStateEstados});
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			name: stateDados.name,
			description: stateDados.description,
			id_state: stateDados.id_state,
			active: stateDados.active,
		};

		if(camposInvalidos(dadosEnviar, [
			'name',
			'id_state',
		], {
			id_state: 'Localização',
		})) return;

		var dadosFormData = new FormData();

		if(stateFoto && stateFoto !== '') {
			dadosFormData.append('photo', stateFoto);
		}

		dadosFormData.append('name', dadosEnviar.name);
		dadosFormData.append('description', dadosEnviar.description);
		dadosFormData.append('id_state', dadosEnviar.id_state);
		dadosFormData.append('active', dadosEnviar.active);

		setLoadingCadastrar(true);

		const ret = await api.ports.insert.post(dadosFormData);

		if(ret.status === 200) {
			msg.success('Porto cadastrado com sucesso.');
			setStateFoto(null);
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Cadastrar porto"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormPorto
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				stateEstados={stateEstados}
				loadingGetEstados={loadingGetEstados}
				stateFoto={stateFoto}
				setStateFoto={setStateFoto}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Cadastrar porto',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalPortosFormCadastro;