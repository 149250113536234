import ContabilizarPaginas from "@cpnt/ContabilizarPaginas/ContabilizarPaginas";
import Paginacao from "@cpnt/Paginacao/Paginacao";
import React from 'react';

// css
import './TotalPaginacao.css';

const TotalPaginacao = props => {
	const {
		metadata = {},
		paginacao = {},
		loading,
		resultados,
		changeFiltro,
	} = props;

	var paginas = metadata.qtdPages;

	if(metadata.qtdPaginas) paginas = metadata.qtdPaginas;
	if(metadata.qtdPages) paginas = metadata.qtdPages;

	return (
		<div className="cpnt-totalepaginacao">
			<ContabilizarPaginas
				limite={paginacao.limit}
				paginaAtual={paginacao.page}
				qtdPaginas={paginas}
				total={metadata.qtdRecords}
				resultados={resultados}
			/>
			<Paginacao
				paginaAtual={paginacao.page}
				totalItens={metadata.qtdRecords}
				totalPaginas={paginas}
				qtdItensPorPagina={paginacao.limit}
				inativo={paginas > 1 ? false : true}
				loading={loading}
				changeFiltro={changeFiltro}
			/>
		</div>
	);
};

export default TotalPaginacao;