import { apiAddress } from "./requests/address";
import { apiAddressType, apiAddressTypeGeral } from "./requests/addressType";
import { apiBerths } from "./requests/berths";
import { apiCargos } from "./requests/cargos";
import { apiCompanies } from "./requests/companies";
import { apiExpenses } from "./requests/expenses";
import { apiLineup } from "./requests/lineup";
import { apiStatistics } from "./requests/statistics";
import { apiRains } from "./requests/rains";
import { apiLogin } from "./requests/login";
import { apiPDAs } from "./requests/pdas";
import { apiPorts } from "./requests/ports";
import { apiRedefinition } from "./requests/redefinition";
import { apiBerthsGeral, apiCargosGeral, apiCompaniesGeral, apiCountriesGeral, apiOperationsTypeGeral, apiPortsGeral, apiSelect, apiStatesGeral, apiVesselsGeral } from "./requests/select";
import { apiUsers } from "./requests/users";
import { apiNews } from "./requests/news";
import { apiVessels } from "./requests/vessels";
import { apiTrackingVessels } from "./requests/trackingVessels";

const api = {
	companies: apiCompanies,
	login: apiLogin,
	users: apiUsers,
	ports: apiPorts,
	select: apiSelect,
	cargos: apiCargos,
	redefinition: apiRedefinition,
	vessels: apiVessels,
	lineup: apiLineup,
	statistics: apiStatistics,
	rains: apiRains,
	pdas: apiPDAs,
	expenses: apiExpenses,
	berths: apiBerths,
	address: apiAddress,
	addressType: apiAddressType,
	news: apiNews,
	trackingVessels: apiTrackingVessels,
};

export { api };

const apiGeral = {
	states: apiStatesGeral,
	countries: apiCountriesGeral,
	operationsType: apiOperationsTypeGeral,
	companies: apiCompaniesGeral,
	vessels: apiVesselsGeral,
	cargos: apiCargosGeral,
	berths: apiBerthsGeral,
	ports: apiPortsGeral,
	addressType: apiAddressTypeGeral,
};

export { apiGeral };
