import { useRef } from 'react';
import _ from 'lodash';

// Config
import { isNumeric } from "./numbers";

export const consoleFormData = formdataObj => {
	console.log("%c------------------------------------------------", 'color: red');
	for(var pair of formdataObj.entries()) {
		if(pair[0] == 'acao') {
			var cor = 'red';
		} else {
			var cor = '#0074E8';
		}
		console.log('%cchave:%c '+pair[0]+ '%c - %cvalor:%c '+ pair[1], 'color: '+cor, 'color: #DD00A9', 'color: #000', 'color: #0074E8', 'color: #DD00A9');
	}
	console.log("%c------------------------------------------------", 'color: red');
}

export const validar200 = dados => {
	var validado = true;
	dados.map(valueRetorno => {
		if(valueRetorno.status !== 200) {
			validado = false;
		}
		return valueRetorno;
	});
	return validado;
}

export const stringPesquisa = string => {
	if(string === null || string === undefined) {
		string = '';
	}
	if(isNumeric(string)) {
		return string.toString();
	}
	return _.deburr(string.toString().toLowerCase());
}

/*
** Verifica o retorno do get da API se tem mensagens
** ================================================== */
export const msgStatus = (ret, extraParam = {}) => {
	var retorno = {};

	if(ret && typeof(ret) === 'object') {
		if(ret.status === 403) {
			retorno = {
				status: ret.status,
				msg: 'Você não tem permissão para acessar essas informações.',
			}
		} else if(ret.status === 400) {
			retorno = {
				status: ret.status,
				msg: ret.error || 'Nenhum conteúdo encontrado',
			}
		} else if(ret.status !== 200) {
			var temMsg = _.get(ret, 'invalidFields[0].msg');
			if(temMsg && temMsg !== '') {
				retorno = {
					status: ret.status,
					msg: temMsg,
				};
			} else if(ret.msg) {
				retorno = {
					status: ret.status,
					msg: ret.msg,
				};
			} else {
				retorno = {
					status: ret.status,
					msg: ret.statusText,
				};
			}
		} else if(ret.status === 200) {
			retorno = {
				status: ret.status,
				msg: ret.statusText,
			};
		}
	} else {
		retorno = {
			status: 0,
			msg: 'Não foi possível fazer a requisição.',
		};
	}

	return {
		...retorno,
		...ret.metadata,
		...extraParam,
	};
}

export const tratarFiltrosApi = dadosObj => {
	if(dadosObj.page && dadosObj.page !== '') {
		var page = Number(dadosObj.page);
		page = page - 1;
		dadosObj.page = page;
	}
	var retorno = {};
	Object.keys(dadosObj).map((val, key, string) => {
		if(dadosObj[val] !== '') {
			retorno[val] = dadosObj[val];
		}
	});
	return retorno;
}
