import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FormBerco.css';

// Routes
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";
import { msg } from "@func/utils";
import { valueSelect } from "@cpnt/Select/selectUtils";

const FormBerco = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isCadastro,
		stateDados,
		setStateDados,
		after,
		onSuccessStatus,
		onErrorStatus,
		statePortos,
		loadingGetPortos,
	} = props;

	var nomePorto = '';
	if(!isCadastro) {
		nomePorto = valueSelect(stateDados.id_port, statePortos);
		if(nomePorto) {
			nomePorto = nomePorto.label;
		}
	}

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="flex flex-wrap">
						<div className="input-box box-1">
							<Input
								label="Nome do berço"
								req
								_={{
									value: stateDados.name,
									onChange: e => setStateDados({
										...stateDados,
										name: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-1">
							{isCadastro ? (
								<Select
									label="Porto"
									req
									_={{
										value: stateDados.id_port,
										onChange: e => setStateDados({
											...stateDados,
											id_port: e.value,
										}),
										options: statePortos,
									}}
									wrap="flex-1"
									loading={loadingGetPortos}
								/>
							) : (
								<Input
									label="Porto"
									tip="Para manter a consistência dos dados, não é permitido alterar o porto associado a esse berço."
									_={{
										disabled: true,
										value: nomePorto,
									}}
									onClick={() => msg.warning('Para manter a consistência dos dados, não é permitido alterar o porto associado a esse berço.')}
								/>
							)}
						</div>
					</div>
					{!isCadastro ? (
						<div className="input-box box-1">
							<Checkbox
								label="Status"
								_={{
									checked: stateDados.active,
									onChange: e => {
										setStateDados({
											...stateDados,
											active: e.target.checked ? 1 : 0,
										});
									},
								}}
								classNameWrap="padding-t-15"
							/>
						</div>
					) : null}
				</div>

				{after}
			</form>
		</>
	);
};

export default FormBerco;