// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./x-branco.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InputFile_box__nAe6m {\n\tdisplay: block;\n\twidth: 100%;\n\tpadding: 12px 10px;\n\tbackground-color: #FFF;\n\tborder-radius: 5px;\n\tcolor: #1A1A1A;\n\tborder: 1px solid #999999;\n\tfont-size: 16px;\n\tfont-family: inherit;\n\toutline: none;\n\tbox-sizing: border-box;\n\tposition: relative;\n}\n.InputFile_x__qXFO1 {\n\tposition: absolute;\n\tright: 10px;\n\ttop: 50%;\n\twidth: 26px;\n\theight: 26px;\n\tborder-radius: 50%;\n\ttransform: translateY(-50%);\n\tcursor: pointer;\n\tbackground: #F22 url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n\tbackground-size: 60%;\n}\n.InputFile_label__TWfVr {\n\tfont-size: 15px;\n\tmargin-bottom: 2px;\n}\n.InputFile_container__u0Mhq {\n\tflex: 1 1;\n}", "",{"version":3,"sources":["webpack://./src/components/InputFile/InputFile.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,WAAW;CACX,kBAAkB;CAClB,sBAAsB;CACtB,kBAAkB;CAClB,cAAc;CACd,yBAAyB;CACzB,eAAe;CACf,oBAAoB;CACpB,aAAa;CACb,sBAAsB;CACtB,kBAAkB;AACnB;AACA;CACC,kBAAkB;CAClB,WAAW;CACX,QAAQ;CACR,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,2BAA2B;CAC3B,eAAe;CACf,gFAA8D;CAC9D,oBAAoB;AACrB;AACA;CACC,eAAe;CACf,kBAAkB;AACnB;AACA;CACC,SAAO;AACR","sourcesContent":[".box {\n\tdisplay: block;\n\twidth: 100%;\n\tpadding: 12px 10px;\n\tbackground-color: #FFF;\n\tborder-radius: 5px;\n\tcolor: #1A1A1A;\n\tborder: 1px solid #999999;\n\tfont-size: 16px;\n\tfont-family: inherit;\n\toutline: none;\n\tbox-sizing: border-box;\n\tposition: relative;\n}\n.x {\n\tposition: absolute;\n\tright: 10px;\n\ttop: 50%;\n\twidth: 26px;\n\theight: 26px;\n\tborder-radius: 50%;\n\ttransform: translateY(-50%);\n\tcursor: pointer;\n\tbackground: #F22 url('./x-branco.png') no-repeat center center;\n\tbackground-size: 60%;\n}\n.label {\n\tfont-size: 15px;\n\tmargin-bottom: 2px;\n}\n.container {\n\tflex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "InputFile_box__nAe6m",
	"x": "InputFile_x__qXFO1",
	"label": "InputFile_label__TWfVr",
	"container": "InputFile_container__u0Mhq"
};
export default ___CSS_LOADER_EXPORT___;
