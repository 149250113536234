import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiRedefinition = {
	callHash: {
		put: async dadosObj => {
			return await api.put(`/redefinition/callHash`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	validateHash: {
		put: async dadosObj => {
			return await api.put(`/redefinition/validateHash`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	changePassword: {
		put: async dadosObj => {
			return await api.put(`/redefinition/changePassword`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	user: {
		password: {
			put: async dadosObj => {
				return await api.put(`/redefinition/user/password`, dadosObj, config())
				.then(retorno => {
					return validarRetorno(retorno);
				})
				.catch(retorno => {
					return validarRetorno(retorno);
				});
			},
		},
	}
};

export { apiRedefinition };