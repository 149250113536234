/*
** Funções de formatar dados
** ================================================== */

import { converterRealParaNumero } from "./convert";
import { trimRight } from "./strings";

export const formatarTelefone = (numero) => {
	if(!numero) {
		return '';
	}
	var length = numero.length;
	var telefoneFormatado = numero;
	if (length === 10) {
		telefoneFormatado = '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 6) + '-' + numero.substring(7, 10);
	} else if (length === 11) {
		telefoneFormatado = '(' + numero.substring(0, 2) + ') ' + numero.substring(2, 7) + '-' + numero.substring(7, 11);
	} else if (length === 8) {
		telefoneFormatado = numero.substring(0, 4) + '-' + numero.substring(4, 8);
	} else if (length === 9) {
		telefoneFormatado = numero.substring(0, 5) + '-' + numero.substring(5, 9);
	}
	return telefoneFormatado;
}

export const formatarCNPJ = (cnpj) => {
	if(cnpj && typeof(cnpj) === 'string') {
		var _cnpj = cnpj.replace(/\D/g, '');
		return _cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3/\$4-\$5");
	}
	return cnpj;
}

export const formatarCPF = (cpf) => {
	if(cpf && typeof(cpf) === 'string') {
		var _cpf = cpf.replace(/\D/g, '');
		return _cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3-\$4");
	}
	return cpf;
}

export const formatarDinheiro = (val, prefixo = '') => {
	var valor = val;
	if(typeof(val) !== 'number') {
		valor = converterRealParaNumero(val);
	}
	if(isNaN(valor)) {
		return `${prefixo}0,00`;
	}
	if(valor && valor !== null && valor !== undefined && valor !== '') {
		return prefixo+parseFloat(valor).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
	}
	return `${prefixo}0,00`;
}

export const urlExibicao = url => {
	if(url.startsWith('http://')) {
		url = url.slice('http://'.length);
	} else if(url.startsWith('https://')) {
		url = url.slice('https://'.length);
	}
	return trimRight(url, '/');
}
