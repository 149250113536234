import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalUsuariosFormCadastro.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormEmpresa from "@page/Logged/Empresas/FormEmpresa/FormEmpresa";
import FormUsuario from "@page/Logged/Usuarios/FormUsuario/FormUsuario";
import { formatarSelect } from "@func/components";

const PortalUsuariosFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-500',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id_company: 0,
		name: '',
		email: '',
		active: 1,
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateEmpresas, setStateEmpresas] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			inicializarGets();
		}
	}

	const apiGeralGetCompanies = async () => {
		apiGeral.companies({setLoadingGetEmpresas, setStateEmpresas});
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetEmpresas = await apiGeral.companies({setLoadingGetEmpresas, setStateEmpresas});
		if(retGetEmpresas.status === 200) {
			setStateEmpresas([
				{
					value: 0,
					label: <span style={{fontWeight: 600}}>Não associar a nenhuma empresa</span>,
				},
				...formatarSelect({dados: retGetEmpresas.data}),
			]);
		}
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id_company: stateDados.id_company,
			name: stateDados.name,
			email: stateDados.email,
			active: stateDados.active,
			role: 1,
		};

		if(dadosEnviar.id_company === 0) {
			delete dadosEnviar.id_company;
		}

		if(camposInvalidos(dadosEnviar, [
			'name',
			'email',
		])) return;

		setLoadingCadastrar(true);

		const ret = await api.users.insert.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Usuário cadastrado com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Cadastrar usuário"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormUsuario
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				stateEmpresas={stateEmpresas}
				loadingGetEmpresas={loadingGetEmpresas}
				getCompanies={apiGeralGetCompanies}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Cadastrar usuário',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalUsuariosFormCadastro;