import React, { useContext, useState } from 'react';

// Libraries
import { Link, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import c from './SolicitarExclusaoDados.module.css';
import { EMAIL_CONTATO } from "@config/config";
import Portal from "@cpnt/Portal/Portal";

// Components

// Functions

const SolicitarExclusaoDados = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();

	const [showDataPortal, setShowDataPortal] = useState(false);
	const [showTrafficPortal, setShowTrafficPortal] = useState(false);
	const [showEmailPortal, setShowEmailPortal] = useState(false);

	return (
		<>
			<div className={c['container']}>
				<h1 className="title">Request Deletion of Personal Data</h1>
				<div className="desc">
					<p>You can request the deletion of your personal data by contacting us. We will get back to you as soon as possible.</p>
					<h2>How to request deletion:</h2>
					<p>Send an email to <strong><a href={`mailto:${EMAIL_CONTATO}?Subject=Data Deletion Request&body=Hello, I would like to request the removal of my personal data. I am aware that after deletion, it will not be possible to recover any information associated with my account.`}>{EMAIL_CONTATO}</a></strong> with the subject <strong>Data Deletion Request</strong>. In the body of the email, provide the details used in your registration such as email, full name, phone number, etc.</p>
					<p>We will perform some validations to confirm that it is really you who is requesting the deletion of the account, in order to prevent others from requesting deletion by mistake or without prior authorization. After all confirmations, your data will be permanently deleted from our system.</p>
					<p>Please note that after deletion, it will not be possible to recover your account, and any information associated with it will also be lost.</p>
					<h2>For more information, see the topics below:</h2>
					<ul className={`${c['lista']}`}>
						<li>
							<span onClick={() => setShowDataPortal(true)} className="link">What data is collected for the app registration?</span>
						</li>
						<li>
							<span onClick={() => setShowTrafficPortal(true)} className="link">What data is transmitted in the app?</span>
						</li>
						<li>
							<span onClick={() => setShowEmailPortal(true)} className="link">How should I fill out the email to request deletion?</span>
						</li>
					</ul>
				</div>
				<div className="sep-t margin-t-30"></div>
				<div className="input-box-btn align-center">
					<Link to="/login">Access BackOffice</Link>
				</div>
			</div>

			<Portal
				titulo="What data is collected for the app registration?"
				show={showDataPortal}
				onClose={() => setShowDataPortal(false)}
				className="w-700"
			>
				<div>Below is the list of personal data collected mandatory for our app:</div>
				<ul>
					<li>Name</li>
					<li>Email</li>
					<li>Phone number</li>
					<li>Position</li>
				</ul>
			</Portal>

			<Portal
				titulo="What data is transmitted in the app?"
				show={showTrafficPortal}
				onClose={() => setShowTrafficPortal(false)}
				className="w-700"
			>
				<div>Below is the list of personal data transmitted in our app:</div>
				<ul>
					<li>Name</li>
					<li>Email</li>
					<li>Phone number</li>
					<li>Position</li>
				</ul>
				<div>In addition to data necessary for the communication of the app with our backend, where some parameters are sent to filter the data on the respective screens.</div>
			</Portal>

			<Portal
				titulo="How should I fill out the email to request deletion?"
				show={showEmailPortal}
				onClose={() => setShowEmailPortal(false)}
				className="w-700"
			>
				<div>Below is an example of how to fill out the email requesting the deletion of your personal data:</div>

				<div className={`${c['info-email']} box-txt`}>
					<h3 className="margin-b-15">Email Subject:</h3>
					<div>Data Deletion Request</div>

					<h3 className="margin-b-15">Example message:</h3>
					<div>Hello, I would like to request the removal of my personal data. I am aware that, after deletion, it will not be possible to recover any information associated with my account.</div>
					<div className="margin-t-15 margin-b-15">Here are my registration details:</div>
					<div><strong>Name:</strong> Name used in registration</div>
					<div><strong>Email:</strong> Email used in registration</div>
					<div><strong>Phone number:</strong> Phone number used in registration</div>
					<div><strong>Position:</strong> Position provided in registration</div>
				</div>

				<h3>Important Notice</h3>
				<p>We will perform some validations to confirm that it is really you who is requesting the deletion of the account, in order to prevent others from requesting deletion by mistake or without prior authorization. After all confirmations, your data will be permanently deleted from our system.</p>
				<p>Please note that after deletion, it will not be possible to recover your account, and any information associated with it will also be lost.</p>
			</Portal>
		</>
	)
}

export { SolicitarExclusaoDados };
