import React, { useContext, useState } from 'react';

// Libs
import { NavLink, useLocation } from "react-router-dom";

// Store
import StoreContext from '@context/StoreContext';

// CSS
import './MainMenu.css';

// Componentes
import Icone from "@cpnt/Icone/Icone";
import { temKeys } from "@func/arrsObjs";

// Imagens
import imgIconeEmpresas from '@svg/outros/empresas.svg';
import imgIconeUsuarios from '@svg/outros/usuarios.svg';
import imgIconePortos from '@svg/outros/portos.svg';
import imgIconeCargos from '@svg/outros/cargos.svg';
import imgIconeNavios from '@svg/outros/navios.svg';
import imgIconeAcompanhamento from '@svg/outros/crosshair.svg';
import imgIconePdas from '@svg/outros/pdas.svg';
import imgIconeDefault from '@svg/outros/pdas.svg';
import imgIconeLineUp from '@svg/outros/lineup.svg';
import imgIconeBerco from '@svg/outros/bercos.svg';
import imgIconeBercoAlt from '@svg/outros/bercos-alt.png';
import imgIconeEndereco from '@svg/outros/enderecos.svg';
import imgIconeEnderecoAlt from '@svg/outros/enderecos-alt.png';
import imgIconeUploads from '@svg/regular/cloud-arrow-up.svg';
import imgIconeNews from '@svg/regular/newspaper.svg';

import Portal from "@cpnt/Portal/Portal";

const MainMenu = props => {
	const [store, setStore] = useContext(StoreContext);
	const location = useLocation();
	const { menu } = props.menu;

	const icones = {
		empresas: imgIconeEmpresas,
		usuarios: imgIconeUsuarios,
		portos: imgIconePortos,
		cargos: imgIconeCargos,
		navios: imgIconeNavios,
		acompanhamento: imgIconeAcompanhamento,
		pdas: imgIconePdas,
		lineup: imgIconeLineUp,
		bercos: imgIconeBerco,
		bercosAlt: imgIconeBercoAlt,
		enderecos: imgIconeEndereco,
		enderecosAlt: imgIconeEnderecoAlt,
		uploads: imgIconeUploads,
		news: imgIconeNews,
	}

	const [showPortalPDA, setShowPortalPDA] = useState(false);

	return (
		<>
			<div className="cpnt-mainmenu">
				<div className="base">
					<div className="base-content">
						<div className="cpnt-mainmenu-inner">
							<ul className="cpnt-mainmenu-lista">
								{menu && menu.map((val, key) => {
									if(val === undefined || !temKeys(val)) return null;
									// const img = require(`@svg/outros/${val.icon}`);
									const img = icones[val.icon] ? icones[val.icon] : imgIconeDefault;
									var cor = location.pathname === val.link ? '#3CA56A' : '#999999';
									var ativo = '';
									if(val.link === '/empresas' && location.pathname === '/') {
										cor = '#3CA56A';
										ativo = 'active';
									}
									return (
										<li key={key}>
											<NavLink
												to={val.link} onClick={() => {
													setStore({
														...store,
														config: {
															...store.config,
															hideSidebar: false,
														}
													})
												}}
												className={`cpnt-mainmenu-link ${ativo}`}
											>
												<span className="cpnt-mainmenu-icone">
													<Icone img={img} cor={cor} tamanho="26px" />
												</span>
												<span className={`cpnt-mainmenu-texto`}>{val.title}</span>
											</NavLink>
										</li>
									)
								})}
							</ul>
							<div className="cpnt-mainmenu-btn">
								{/* <Button
									_={{
										value: 'Novo PDA',
										className: 'btn btn-default btn-small',
										onClick: () => setShowPortalPDA(true)
									}}
								/> */}
							</div>
						</div>
					</div>
				</div>
			</div>

			<Portal
				show={showPortalPDA}
				onClose={() => setShowPortalPDA(false)}
				className="w-1000"
			>
				Portal PDA
			</Portal>
		</>
	)
}

export default MainMenu;
