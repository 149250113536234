export const _API_TIPO_CARGO_COMMODITY = [
	{
		label: 'Grãos',
		value: 'GRAINS',
	},
	{
		label: 'Líquido',
		value: 'LIQUID',
	},
];

export const _API_SELECT_STATUS_NAVIOS = [
	{
		label: 'Berthed',
		value: 'BERTHED',
	},
	{
		label: 'Arriving',
		value: 'ARRIVING',
	},
	{
		label: 'Sailed',
		value: 'SAILED',
	},
	{
		label: 'Arrived',
		value: 'ARRIVED',
	},
];
