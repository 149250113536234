import React, { useState, useEffect } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalCargosFormCadastro.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormCargo from "@page/Logged/Cargos/FormCargo/FormCargo";
import { formatarSelect } from "@func/components";
import { _API_TIPO_CARGO_COMMODITY } from "@config/_tempSelect";

const PortalCargosFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-600',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		name: '',
		cargo_type: '',
		active: 1,
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetCargoType, setLoadingGetCargoType] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [stateCargoType, setStateCargoType] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		// apiGeral.states({setLoadingGetCargoType, setStateCargoType});
		setStateCargoType(_API_TIPO_CARGO_COMMODITY);
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			name: stateDados.name,
			cargo_type: stateDados.cargo_type,
			active: stateDados.active,
		};

		if(camposInvalidos(dadosEnviar, [
			'name',
			'cargo_type',
		])) return;

		setLoadingCadastrar(true);

		const ret = await api.cargos.insert.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Cargo cadastrado com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Cadastrar cargo"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormCargo
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				stateCargoType={stateCargoType}
				loadingGetCargoType={loadingGetCargoType}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Cadastrar cargo',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalCargosFormCadastro;