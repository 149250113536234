import React, { useState, useEffect } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalNaviosFormCadastro.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormNavio from "@page/Logged/Navios/FormNavio/FormNavio";
import { formatarSelect } from "@func/components";
import { _API_TIPO_CARGO_COMMODITY } from "@config/_tempSelect";

const PortalNaviosFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-900',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		name: '',
		status: '',
		description: '',
		id_country: '',
		deadweight: '',
		gross_register_tonnage: '',
		length_overall: '',
		active: 1,
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [statePaises, setStatePaises] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			inicializarGets();
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		apiGeral.countries({setLoadingGetPaises, setStatePaises});
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			name: stateDados.name,
			status: stateDados.status,
			description: stateDados.description,
			id_country: stateDados.id_country,
			deadweight: stateDados.deadweight,
			gross_register_tonnage: stateDados.gross_register_tonnage,
			length_overall: stateDados.length_overall,
			active: stateDados.active,
		};

		if(camposInvalidos(dadosEnviar, [
			'name',
			'status',
			'id_country',
			'deadweight',
			'gross_register_tonnage',
			'length_overall',
		], {
			status: 'Status do navio',
		})) return;

		setLoadingCadastrar(true);

		const ret = await api.vessels.insert.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Navio cadastrado com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Cadastrar navio"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormNavio
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				statePaises={statePaises}
				loadingGetPaises={loadingGetPaises}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Cadastrar navio',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalNaviosFormCadastro;