import React, { useEffect, useState } from 'react';
import passwordValidator from 'password-validator';

// css
import './CriteriosSenha.css';

// Compoenents
import Icone from '../Icone/Icone';

// Imagens
import imgCheck from './circle-check-s.svg';
import imgUncheck from './circle-check.svg';
import imgErro from './circle-x.svg';

export const validarSenha = senha => {

	var schema = new passwordValidator();

	schema
	.is().min(8)
	.is().max(20)
	.has().uppercase()
	// .has().lowercase()
	.has().digits(1)
	.has().symbols()
	.has().not().spaces()
	.is().not().oneOf([
		'Passw0rd',
		'Password123',
		'P@assw0rd',
		'12345678',
	]);

	return {
		valido: schema.validate(senha),
		criterios: schema.validate(senha, { list: true }),
	}
}

const CriteriosSenha = props => {
	const {
		className = '',
		wrap = '',
		senha,
		stateCriterios,
		setStateCriterios,
		corCheck = '#229d3e',
		corUncheck = '#CCC',

		// Textos
		minTexto = 'No mínimo 8 caracteres',
		maxTexto = 'No máximo 20 caracteres',
		lowercaseTexto = 'No mínimo 1 letra minúscula',
		uppercaseTexto = 'No mínimo 1 letra maiúscula',
		spacesTexto = 'Não deve conter espaços',
		symbolsTexto = 'No mínimo 1 caractere especial',
		digitsTexto = 'No mínimo 1 dígito',
		oneOfTexto = 'Não utilize senhas comuns (utilize outra senha)',
	} = props;

	useEffect(() => {
		var validacaoSenha = validarSenha(senha || '');
		setStateCriterios(validacaoSenha.criterios);
	}, [senha]);

	return (
		<div className={`cpnt-critsenha ${wrap}`}>
			<div className={`cpnt-critsenha-box ${className}`}>

				{/* Quantidade mínima */}
				<div className="cpnt-critsenha-item">
					<Icone
						img={stateCriterios.includes('min') ? imgUncheck : imgCheck}
						cor={stateCriterios.includes('min') ? corUncheck : corCheck}
					/>
					<div className="cpnt-critsenha-texto">{minTexto}</div>
				</div>

				{/* Uma maiúscula */}
				<div className="cpnt-critsenha-item">
					<Icone
						img={stateCriterios.includes('uppercase') ? imgUncheck : imgCheck}
						cor={stateCriterios.includes('uppercase') ? corUncheck : corCheck}
					/>
					<div className="cpnt-critsenha-texto">{uppercaseTexto}</div>
				</div>

				{/* Uma digito */}
				<div className="cpnt-critsenha-item">
					<Icone
						img={stateCriterios.includes('digits') ? imgUncheck : imgCheck}
						cor={stateCriterios.includes('digits') ? corUncheck : corCheck}
					/>
					<div className="cpnt-critsenha-texto">{digitsTexto}</div>
				</div>

				{/* Um caractere especial */}
				<div className="cpnt-critsenha-item">
					<Icone
						img={stateCriterios.includes('symbols') ? imgUncheck : imgCheck}
						cor={stateCriterios.includes('symbols') ? corUncheck : corCheck}
					/>
					<div className="cpnt-critsenha-texto">{symbolsTexto}</div>
				</div>

				{/* Uma minúscula */}
				{/* <div className="cpnt-critsenha-item">
					<Icone
						img={stateCriterios.includes('lowercase') ? imgUncheck : imgCheck}
						cor={stateCriterios.includes('lowercase') ? corUncheck : corCheck}
					/>
					<div className="cpnt-critsenha-texto">{lowercaseTexto}</div>
				</div> */}

				{/* Quantidade máxima */}
				{stateCriterios.includes('max') ? (
					<div className="cpnt-critsenha-item">
						<Icone
							img={imgErro}
							cor="#E74C3C"
						/>
						<div className="cpnt-critsenha-texto">{maxTexto}</div>
					</div>
				) : null}

				{/* Espaços */}
				{stateCriterios.includes('spaces') ? (
					<div className="cpnt-critsenha-item">
						<Icone
							img={imgErro}
							cor="#E74C3C"
						/>
						<div className="cpnt-critsenha-texto">{spacesTexto}</div>
					</div>
				) : null}

				{/* Evitar passwords comuns */}
				{stateCriterios.includes('oneOf') ? (
					<div className="cpnt-critsenha-item">
						<Icone
							img={imgErro}
							cor="#E74C3C"
						/>
						<div className="cpnt-critsenha-texto">{oneOfTexto}</div>
					</div>
				) : null}

			</div>
		</div>
	);
};

export default CriteriosSenha;