import React, { useRef, useState } from 'react';

// css
import c from './Uploads.module.css';

// API
import { api } from "@api/api";

// Componentes
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";

// Func
import { ButtonUpload } from "@cpnt/ButtonUpload/ButtonUpload";
import Info2 from "@cpnt/Info2/Info2";
import { msgs } from "@func/jsx";
import { msg } from "@func/utils";
import Button from "@cpnt/Button/Button";
import InputFile from "@cpnt/InputFile/InputFile";
import { MESES_NUMERO } from "@config/objetos";
import { apenasNumeros } from "@func/regex";
import { camposInvalidos } from "@func/validate";

const Uploads = props => {

	const refInputLineup = useRef();
	const refInputEstatistica = useRef();
	const [stateFileLineup, setStateFileLineup] = useState(null);
	const [stateFileEstatistica, setStateFileEstatistica] = useState(null);
	const [stateFilePeriodosChuva, setStateFilePeriodosChuva] = useState(null);

	/*
	** Loading
	** ================================================== */
	const [loadingGetGraficos, setLoadingGetGraficos] = useState(false);
	const [loadingEnviarArquivo, setLoadingEnviarArquivo] = useState(false);

	const [stateTipoUpload, setStateTipoUpload] = useState(null);

	const camposDefaultLineup = {
		type: null,
	};

	const camposDefaultEstatisticas = {
		type: null,
		month: '',
		year: '',
	};

	const camposDefaultPeriodosChuva = {
		month: '',
		year: '',
	};

	const [stateDadosLineup, setStateDadosLineup] = useState(camposDefaultLineup);
	const [stateDadosEstatistica, setStateDadosEstatistica] = useState(camposDefaultEstatisticas);
	const [stateDadosPeriodosChuva, setStateDadosPeriodosChuva] = useState(camposDefaultPeriodosChuva);

	const importarArquivoExcelLineup = async () => {
		if(loadingEnviarArquivo) {
			msg.warning('O arquivo está sendo enviado, por favor aguarde... ');
			return;
		}

		if(camposInvalidos(stateDadosLineup, [
			'type',
		], {
			type: 'Tipo',
		})) return;

		if(!stateFileLineup || stateFileLineup === '') {
			msg.error('É necessário selecionar um arquivo .xlsx');
			return;
		}

		var dadosFormData = new FormData();
		dadosFormData.append('file', stateFileLineup);
		// dadosFormData.append('type', stateDadosLineup.type);

		setLoadingEnviarArquivo(true);

		const ret = await api.lineup.insert.post(dadosFormData, {type: stateDadosLineup.type});

		if(ret.status === 200) {
			msg.success('LineUp enviado com sucesso.');

			setStateDadosLineup(camposDefaultLineup);
			setStateFileLineup(null);
		} else {
			msgs([ret]);
		}

		setLoadingEnviarArquivo(false);
	}

	/*
	** API - Estatísticas
	** ================================================== */
	const importarArquivoExcelEstatisticas = async () => {
		if(loadingEnviarArquivo) {
			msg.warning('O arquivo está sendo enviado, por favor aguarde... ');
			return;
		}

		if(camposInvalidos(stateDadosEstatistica, [
			'type',
			'month',
			'year',
		], {
			type: 'Tipo',
			month: 'Mês',
			year: 'Ano',
		})) return;

		if(!stateFileEstatistica || stateFileEstatistica === '') {
			msg.error('É necessário selecionar um arquivo .xlsx');
			return;
		}

		var dadosFormData = new FormData();

		dadosFormData.append('file', stateFileEstatistica);
		// dadosFormData.append('type', stateDadosEstatistica.type);
		// dadosFormData.append('month', stateDadosEstatistica.month);
		// dadosFormData.append('year', stateDadosEstatistica.year);

		setLoadingEnviarArquivo(true);

		const ret = await api.statistics.insert.post(dadosFormData, {
			type: stateDadosEstatistica.type,
			month: stateDadosEstatistica.month,
			year: stateDadosEstatistica.year,
		});

		if(ret.status === 200) {
			msg.success('Estatística enviada com sucesso.');

			setStateDadosEstatistica(camposDefaultEstatisticas);
			setStateFileEstatistica(null);
		} else {
			msgs([ret]);
		}

		setLoadingEnviarArquivo(false);
	}

	/*
	** API - Estatísticas
	** ================================================== */
	const importarArquivoExcelPeriodosChuvas = async () => {
		if(loadingEnviarArquivo) {
			msg.warning('O arquivo está sendo enviado, por favor aguarde... ');
			return;
		}

		if(!stateFilePeriodosChuva || stateFilePeriodosChuva === '') {
			msg.error('É necessário selecionar um arquivo .xlsx');
			return;
		}

		if(camposInvalidos(stateDadosPeriodosChuva, [
			'month',
			'year',
		], {
			month: 'Mês',
			year: 'Ano',
		})) return;

		var dadosFormData = new FormData();

		dadosFormData.append('file', stateFilePeriodosChuva);

		setLoadingEnviarArquivo(true);

		const ret = await api.rains.insert.post(dadosFormData, {
			month: stateDadosPeriodosChuva.month,
			year: stateDadosPeriodosChuva.year,
		});

		if(ret.status === 200) {
			msg.success('Período de chuva enviado com sucesso.');

			setStateDadosPeriodosChuva(camposDefaultPeriodosChuva);
			setStateFilePeriodosChuva(null);
		} else {
			msgs([ret]);
		}

		setLoadingEnviarArquivo(false);
	}


	const renderTipoUpload = () => {

		if(stateTipoUpload === 'lineup') {
			return (
				<div className={c['box-upload']}>
					<h3 className="margin-b-0">Enviar arquivo de LineUp</h3>
					<div>Selecione o arquivo no formato <strong>.xlsx</strong></div>

					<div className="inputs-box w-400 margin-t-15">
						<div className="input-box">
							<Select
								label="Tipo"
								_={{
									value: stateDadosLineup.type,
									onChange: e => {
										setStateDadosLineup({
											...stateDadosLineup,
											type: e.value,
										});
									},
									options: [
										{value: 'grains', label: 'Grains'},
										{value: 'fertilizers', label: 'Fertilizers'},
										{value: 'sugar', label: 'Sugar'},
									],
								}}
							/>
						</div>
						<div className="input-box">
							<InputFile
								label="Arquivo Excel"
								value={stateFileLineup}
								setValue={setStateFileLineup}
							/>
						</div>
						<div className="input-box margin-t-15">
							<Button
								_={{
									value: 'Enviar arquivo',
									type: 'button',
									onClick: importarArquivoExcelLineup
								}}
								loading={loadingEnviarArquivo}
							/>
						</div>
					</div>
				</div>
			)
		}

		if(stateTipoUpload === 'estatisticas') {
			return (
				<div className={c['box-upload']}>
					<h3 className="margin-b-0">Enviar arquivo de Estatística</h3>
					<div>Selecione o arquivo no formato <strong>.xlsx</strong></div>

					<div className="inputs-box w-400 margin-t-15">
						<div className="input-box">
							<Select
								label="Tipo"
								_={{
									value: stateDadosEstatistica.type,
									onChange: e => {
										setStateDadosEstatistica({
											...stateDadosEstatistica,
											type: e.value,
										});
									},
									options: [
										{value: 'grains', label: 'Grains'},
										{value: 'fertilizers', label: 'Fertilizers'},
										{value: 'sugar', label: 'Sugar'},
									],
								}}
							/>
						</div>
						<div className="input-box">
							<Select
								label="Mês"
								_={{
									value: stateDadosEstatistica.month,
									onChange: e => {
										setStateDadosEstatistica({
											...stateDadosEstatistica,
											month: e.value,
										})
									},
									options: MESES_NUMERO,
								}}
								wrap="flex-1"
							/>
						</div>
						<div className="input-box">
							<Input
								label="Ano"
								_={{
									value: stateDadosEstatistica.year,
									maxLength: 4,
									onChange: e => {
										setStateDadosEstatistica({
											...stateDadosEstatistica,
											year: apenasNumeros(e.target.value),
										})
									},
								}}
							/>
						</div>
						<div className="input-box">
							<InputFile
								label="Arquivo Excel"
								value={stateFileEstatistica}
								setValue={setStateFileEstatistica}
							/>
						</div>
						<div className="input-box margin-t-15">
							<Button
								_={{
									value: 'Enviar arquivo',
									type: 'button',
									onClick: importarArquivoExcelEstatisticas
								}}
								loading={loadingEnviarArquivo}
							/>
						</div>
					</div>
				</div>
			)
		}

		if(stateTipoUpload === 'chuvas') {
			return (
				<div className={c['box-upload']}>
					<h3 className="margin-b-0">Enviar arquivo de Períodos de Chuva</h3>
					<div>Selecione o arquivo no formato <strong>.xlsx</strong></div>

					<div className="inputs-box w-400 margin-t-15">
						<div className="input-box">
							<Select
								label="Mês"
								_={{
									value: stateDadosPeriodosChuva.month,
									onChange: e => {
										setStateDadosPeriodosChuva({
											...stateDadosPeriodosChuva,
											month: e.value,
										})
									},
									options: MESES_NUMERO,
								}}
								wrap="flex-1"
							/>
						</div>
						<div className="input-box">
							<Input
								label="Ano"
								_={{
									value: stateDadosPeriodosChuva.year,
									maxLength: 4,
									onChange: e => {
										setStateDadosPeriodosChuva({
											...stateDadosPeriodosChuva,
											year: apenasNumeros(e.target.value),
										})
									},
								}}
							/>
						</div>
						<div className="input-box">
							<InputFile
								label="Arquivo Excel"
								value={stateFilePeriodosChuva}
								setValue={setStateFilePeriodosChuva}
							/>
						</div>
						<div className="input-box margin-t-15">
							<Button
								_={{
									value: 'Enviar arquivo',
									type: 'button',
									onClick: importarArquivoExcelPeriodosChuvas
								}}
								loading={loadingEnviarArquivo}
							/>
						</div>
					</div>
				</div>
			)
		}

		return (
			<div>
				<h3 className="margin-b-0">Tipo inválido</h3>
				<div>Selecione um tipo válido no menu acima.</div>
			</div>
		)
	}

	return (
		<>
			<div className={`pg-uploads`}>
				<div>
					<FiltrosContainer>
						<div className="titulo cpnt-baract-titulo color-1">
							Uploads
						</div>
					</FiltrosContainer>

					<div className="base">
						<div className="base-content">
							<div className="flex flex-bottom margin-t-20">
								<div>
									<Select
										label="Tipo de upload"
										_={{
											value: stateTipoUpload,
											onChange: e => {
												setStateTipoUpload(e.value);
											},
											options: [
												{label: 'LineUp', value: 'lineup'},
												{label: 'Estatísticas', value: 'estatisticas'},
												{label: 'Períodos de chuva', value: 'chuvas'},
											],
										}}
										classNameWrap="w-300"
									/>
								</div>
								<div className="margin-l-50">
									{!stateTipoUpload ? (
										<Info2
											icone="info"
											titulo="Tipo de upload"
											desc="Selecione o tipo para visualizar os campos correspondentes"
											iconeCor="#2596e1"
										/>
									) : null}
								</div>
							</div>

							{stateTipoUpload ? (
								renderTipoUpload()
							) : null}
						</div>
					</div>
				</div>

			</div>

		</>
	);
};

export { Uploads };
