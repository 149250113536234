import React from 'react';

// Componentes
import { LinhaItem } from "@cpnt/LinhaItem/LinhaItem";

// Funções
import { dataApiParaDataHoraMinSeg } from "@func/date";

const DetalhesBerco = props => {
	const {
		stateDados,
		after,
	} = props;

	return (
		<>
			<div>
				<LinhaItem titulo="Nome do berço" desc={stateDados.name} />
				<LinhaItem titulo="Nome do porto" desc={stateDados.port_name} />
				<LinhaItem titulo="Status" desc={(stateDados.active ? 'Ativo' : <span className="color-red">Inativo</span>)} />
				<LinhaItem titulo="ID do berço" desc={stateDados.id} />
				<LinhaItem titulo="Cadastrado em" desc={dataApiParaDataHoraMinSeg(stateDados.created_at)} />
			</div>
			{after}
		</>

	);
};

export { DetalhesBerco };
