import React from 'react';

// css
import './PortalConfirmacao.css';

// Componentes
import Portal from '@cpnt/Portal/Portal';
import Button from "@cpnt/Button/Button";

const PortalConfirmacao = props => {
	const {
		show,
		wrap = '',
		onClose,
		closeOnEsc = true,
		className = '',
		x = true,
		portalClassName = 'cpnt-portalconfirm-container',

		// Titulo
		titulo = 'Deseja realmente continuar?',
		classNameTitulo = 'cpnt-portalconfirm-titulo',

		desc = 'Essa ação não pode ser desfeita.',
		btns,
		footer,

		// Botões
		classNameBtns = 'cpnt-portalconfirm-btns',

		// Cancelar
		showCancelar = true,
		btnCancelarTexto = 'Cancelar',
		onClickCancelar = () => null,
		classNameBtnCancelar = 'btn btn-1',
		loadingCancelar,

		// Confirmar
		showConfirmar = true,
		btnConfirmarTexto = 'Confirmar',
		onClickConfirmar = () => null,
		classNameBtnConfirmar = 'btn btn-default btn-red',
		loadingConfirmar,
	} = props;

	return (
		<Portal
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
			classNameDefault="cpnt-portalconfirm-portal"
			classNameX="x"
			x={x}
		>
			<div className={`cpnt-portalconfirm ${wrap}`}>
				<div className={`cpnt-portalconfirm-box ${className}`}>
					{titulo ? (
						<div className={`${classNameTitulo} cpnt-portalconfirm-titulo-default`}>
							{typeof(titulo) === 'string' ? (
								<h3 className="margin-0">{titulo}</h3>
							) : (
								titulo
							)}
						</div>
					) : null}

					{desc ? (
						<div className="cpnt-portalconfirm-desc">
							{desc}
						</div>
					) : null}

					{!btns ? (
						<div className={`${classNameBtns} cpnt-portalconfirm-btns-default`}>
							{showCancelar ? (
								<Button
									_={{
										value: btnCancelarTexto,
										onClick: onClickCancelar,
										className: classNameBtnCancelar,
									}}
									loading={loadingCancelar}
								/>
							) : null}
							{showConfirmar ? (
								<Button
									_={{
										value: btnConfirmarTexto,
										onClick: onClickConfirmar,
										className: classNameBtnConfirmar,
									}}
									loading={loadingConfirmar}
									classNameWrap="margin-l-15"
								/>
							) : null}
						</div>
					) : null}

					{btns ? (
						<div className={`${classNameBtns} cpnt-portalconfirm-btns-default`}>
							{btns}
						</div>
					) : null}

					{footer}
				</div>
			</div>
		</Portal>
	);
};

export default PortalConfirmacao;