import React from 'react';

// css
import './FormPDADespesas.css';

// Routes
import Input from "@cpnt/Input/Input";
import { dataApiParaDataMesDiaAno } from "@func/date";
import { formatarDinheiro } from "@func/format";

const FormPDADespesas = props => {

	const {
		onSubmit,
		stateDados,
		setStateDados,
		stateDadosDespesas,
		setStateDadosDespesas,
		after,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<h3 className="margin-b-10 margin-t-0">Dados do PDA</h3>
				<div className="box-tabela flex flex-wrap">
					<div className="input-box box-2 box-1-980">
						<div className="strong">Nome da empresa</div>
						<div>{stateDados.company_name}</div>
					</div>
					<div className="input-box box-2 box-1-980">
						<div className="strong">ID do PDA</div>
						<div>{stateDados.id}</div>
					</div>
					<div className="input-box box-2 box-1-980">
						<div className="strong">Data de criação</div>
						<div>{dataApiParaDataMesDiaAno(stateDados.created_at)}</div>
					</div>
					<div className="input-box box-2 box-1-980">
						<div className="strong">Rate USD</div>
						<div>{stateDados.rate_usd}</div>
					</div>
				</div>

				<h3 className="margin-b-10">Dados das despesas</h3>
				<div className="inputs-box box">
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Pilotage expenses"
								_={{
									value: stateDadosDespesas.pilotage_expense,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										pilotage_expense: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.pilotage_expense, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Tug hire"
								_={{
									value: stateDadosDespesas.tug_hire,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										tug_hire: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.tug_hire, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Launch hire"
								_={{
									value: stateDadosDespesas.launch_hire,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										launch_hire: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.launch_hire, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Mooring/unmooring"
								_={{
									value: stateDadosDespesas.mooring,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										mooring: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.mooring, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Linesman"
								_={{
									value: stateDadosDespesas.linesmen,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										linesmen: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.linesmen, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Wharfage"
								_={{
									value: stateDadosDespesas.wharfage,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										wharfage: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.wharfage, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Gangway watchman"
								_={{
									value: stateDadosDespesas.gangway_watchmen,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										gangway_watchmen: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.gangway_watchmen, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Light dues"
								_={{
									value: stateDadosDespesas.light_dues,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										light_dues: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.light_dues, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Entering & Clearing"
								_={{
									value: stateDadosDespesas.entering_cleaning,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										entering_cleaning: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.entering_cleaning, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Car hire"
								_={{
									value: stateDadosDespesas.car_hire,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										car_hire: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.car_hire, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Contribution"
								_={{
									value: stateDadosDespesas.contribution,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										contribution: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.contribution, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Pettv expenses (sundries)"
								_={{
									value: stateDadosDespesas.petty,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										petty: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.petty, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Garbage"
								_={{
									value: stateDadosDespesas.garbage,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										garbage: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.garbage, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Fedefal tax"
								_={{
									value: stateDadosDespesas.federal_tax,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										federal_tax: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.federal_tax, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Municipal tax"
								_={{
									value: stateDadosDespesas.municipal_tax,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										municipal_tax: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.municipal_tax, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Port system fee"
								_={{
									value: stateDadosDespesas.port_system_fee,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										port_system_fee: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.port_system_fee, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="input-box box-1">
							<Input
								label="Agency fee"
								_={{
									value: stateDadosDespesas.agency_fee,
									onChange: e => setStateDadosDespesas({
										...stateDadosDespesas,
										agency_fee: e,
									}),
								}}
								after={formatarDinheiro(stateDados.expensesBRL.agency_fee, 'R$ ')}
								dinheiro
							/>
						</div>
					</div>
					<div className="flex box-1">
						<div className="sticky bottom-0 input-box box-1">
							<div className="flex flex-between padding-t-10">
								<div>
									<div className="strong">Total despesas em USD</div>
									<div>$ {typeof(stateDados.total_expenses) === 'number' ? stateDados.total_expenses.toFixed(2) : stateDados.total_expenses}</div>
								</div>
								<div>
									<div className="strong">Total despesas em BRL:</div>
									<div>{formatarDinheiro(stateDados.total_expensesBRL, 'R$ ')}</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{after}
			</form>
		</>
	);
};

export default FormPDADespesas;