import React from 'react';

// CSS
import c from './ButtonX.module.css';

const ButtonX = props => {

	const {
		_ = {},
		className = '',
		classNameWrap = 'flex',
		classNameExtra = '',
		loading,
	} = props;

	return (
		<div className={`${c['btn-x']} ${className} ${classNameWrap}`} {..._}>
		</div>
	)
}

export default ButtonX;