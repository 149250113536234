import Checkbox from "@cpnt/Checkbox/Checkbox";
import Radio from "@cpnt/Radio/Radio";
import React, { useState, useEffect } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

// CSS
import './Select3.css';

const Select3 = props => {

	const {
		wrap = '',
		// options = [],
		label,
		customLabel,
		classNameLabel = 'link',
		onClick = () => null,
		onChange = () => null,
		closeOnClick = true,
		closeOnChange = true,
		// posicao = 'bottom',
		loading,
		// optionsType,
		// classNameDropdown = '',
		// optionsChecked = {},
		showDropdown,
		// labelClass = ''
	} = props;

	/*
	** Força o fechamento ao atualizar
	** ================================================== */
	useEffect(() => {
        setShowMenuDropdown(showDropdown);
	}, [showDropdown]);

	/*
	** States
	** ================================================== */
	const [showMenuDropdown, setShowMenuDropdown] = useState(showDropdown);

	/*
	** Ao clicar
	** ================================================== */
	const onClickItem = dados => {
		onClick(dados);

		if(closeOnClick) {
			setShowMenuDropdown(false);
		}
	}

	/*
	** Ao trocar
	** ================================================== */
	const onChangeItem = dados => {
		onChange(dados);

		if(closeOnChange) {
			setShowMenuDropdown(false);
		}
	}

	/*
	** Fechar menu
	** ================================================== */
	const closeDropdown = () => {
        setShowMenuDropdown(false);
    }

	const ref = useDetectClickOutside({ onTriggered: closeDropdown });

	return (
		<div className={`cpnt-select3 ${wrap}`} ref={ref}>
			{loading && <div className="cpnt-select3-dropdown-loading-label"></div>}
			<div className="cpnt-select3-inner">
				{customLabel && <div className={`cpnt-select3-label ${classNameLabel}`} onClick={() => setShowMenuDropdown(!showMenuDropdown)}>{customLabel}</div>}
				{label && <div className={`cpnt-select3-label ${classNameLabel}`} onClick={() => setShowMenuDropdown(!showMenuDropdown)}>{label}</div>}
				{showMenuDropdown &&
					<Select3Lista {...props} onClick={onClickItem} onChange={onChangeItem} />
				}
			</div>
		</div>
	);
}

export const Select3Lista = props => {

	const {
		posicao = 'bottom',
		classNameDropdown = '',
		loading,
		options = [],
		classNameDefault = 'cpnt-select3-dropdown',
		tituloDropdown,
		titulo,
	} = props;

	return (
		<div className={`${classNameDefault} cpnt-select3-dropdown-${posicao} ${classNameDropdown}`}>
			{loading && <div className="cpnt-select3-dropdown-loading-dropdown"></div>}
			{titulo && <div className="cpnt-select3-titulo-lista">{titulo}</div>}
			{tituloDropdown && <div className="cpnt-select3-titulo-lista-dropdown">{tituloDropdown}</div>}
			{options.length ? (
				options.map((val, key) => {
					if(!val) {
						return null;
					}
					if(!val.label && (val.titulo || val.desc)) {
						return (
							<div
								key={key}
								className={`${val.classNameDefault ? `cpnt-select3-titulo-box` : ''} ${val.className ? val.className : ''}`}
							>
								{val.titulo && val.titulo !== '' ? (
									<div className={`cpnt-select3-titulo cpnt-select3-${val.classNameTitulo ? val.classNameTitulo : ''}`}>
										{val.titulo}
									</div>
								) : null}
								{val.desc && val.desc !== '' ? (
									<div className={`cpnt-select3-desc cpnt-select3-${val.classNameDesc ? val.classNameDesc : ''}`}>
										{val.desc}
									</div>
								) : null}
							</div>
						)
					} else {
						return (
							<div
								key={key}
							>
								{renderOptionsType({...props, ...{dados: val}})}
							</div>
						)
					}
				})
			) : (
				<div>Nenhuma opção informada.</div>
			)}
		</div>
	)
}

/*
** Tipo de option
** ================================================== */
const renderOptionsType = props => {

	const {
		optionsType,
		dados = {},
		onClick = () => null,
		onChange = () => null,
		labelClass = '',
		optionsChecked = {},
	} = props;

	// Default
	if(!optionsType) {
		return (
			<div
				onClick={e => onClick(dados, e)}
				className="cpnt-select3-item"
			>
				{dados.label}
				{dados.desc ? (
					<>{dados.desc}</>
				) : null}
			</div>
		);
	}

	// Radio
	if(optionsType === 'radio') {
		return (
			<Radio
				label={dados.label}
				labelClass={labelClass}
				_={{
					checked: dados.value === optionsChecked.value ? true : false,
					onChange: e => onChange(dados, e),
					onClick: e => onClick(dados, e),
				}}
				wrap="select3-input-box"
			/>
		)
	}

	// Checkbox
	if(optionsType === 'checkbox') {
		return (
			<Checkbox
				label={dados.label}
				_={{
					checked: dados.checked === optionsChecked.value ? true : false,
					onChange: e => onChange(dados, e),
					onClick: e => onClick(dados, e),
				}}
				wrap="select3-input-box"
			/>
		)
	}

}

export default Select3;