import React from 'react';

// css
import './CirculoLetras.css';
import { apenasLetras } from "@func/regex";

const CirculoLetras = props => {
	const {
		className = '',
		classNameWrap = '',
		texto = '',
		alt,
	} = props;

	var letras = '-';

	if(texto && typeof(texto) === 'string' && texto !== '') {
		var textoSplit = texto.trim().split(' ');
		letras = '';

		if(textoSplit.length > 1) {
			var letraTemp0 = apenasLetras(textoSplit[0]);
			if(letraTemp0[0]) {
				letras = letraTemp0[0];
			}

			var letraTemp1 = apenasLetras(textoSplit[1]);
			if(letraTemp1[0]) {
				letras += letraTemp1[0];
			}
		} else {
			var letraTemp = apenasLetras(textoSplit[0]);
			if(letraTemp[0]) {
				letras = letraTemp[0];
			}
		}

		letras = letras.toUpperCase();
	}

	return (
		<>
			<div className={`cpnt-circletras ${classNameWrap}`}>
				<div className={`cpnt-circletras-box ${className} ${alt ? 'cpnt-circletras-alt' : ''}`}>
					{letras}
				</div>
			</div>
		</>
	);
};

export { CirculoLetras };