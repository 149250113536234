import { LinhaItem } from "@cpnt/LinhaItem/LinhaItem";
import { dataApiParaDataHoraMinSeg } from "@func/date";
import React from 'react';

import imgPlaceholder from './placeholder.jpg';

const DetalhesPorto = props => {
	const {
		stateDados,
		after,
	} = props;

	const foto = () => {
		if(stateDados.photo && stateDados.photo !== '') {
			return stateDados.photo;
		}
		return imgPlaceholder;
	}

	return (
		<>
			<div>
				<div className="margin-b-20"><img src={foto()} alt="imagem-porto" className="block w-100p" /></div>
				<LinhaItem titulo="Nome" desc={stateDados.name} />
				<LinhaItem titulo="Estado" desc={stateDados.id_state} />
				<LinhaItem titulo="Descrição" desc={stateDados.description} multiline />
				<LinhaItem titulo="Status" desc={(stateDados.active ? 'Ativo' : <span className="color-red">Inativo</span>)} />
				<LinhaItem titulo="ID de cadastro" desc={stateDados.id} />
				<LinhaItem titulo="Cadastrado em" desc={dataApiParaDataHoraMinSeg(stateDados.created_at)} />
			</div>
			{after}
		</>

	);
};

export { DetalhesPorto };
