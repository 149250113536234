// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FormHold_box-loop__mtKgO {\n}\n.FormHold_box-loop__mtKgO > div {\n\tflex: 1 1;\n}\n.FormHold_box-item__dvyFV {\n\tdisplay: flex;\n\tgap: 20px;\n\talign-items: flex-end;\n}\n.FormHold_box-item__dvyFV + .FormHold_box-item__dvyFV {\n\tborder-top: 1px solid #CCC;\n\tmargin-top: 10px;\n\tpadding-top: 10px;\n}\n.FormHold_box-loop__mtKgO .FormHold_box-item__dvyFV .FormHold_btn-form-hold__ouuDA {\n\tpadding: 15px;\n}\n.FormHold_btn-x-box__4kY62 {\n\tmargin-bottom: 6px;\n}\n.FormHold_msg__dWyqW {\n\tmargin-top: 15px;\n\tpadding: 10px;\n\tborder-radius: 5px;\n\tborder: 1px solid #CCC;\n}", "",{"version":3,"sources":["webpack://./src/pages/Logged/Acompanhamentos/FormHold/FormHold.module.css"],"names":[],"mappings":"AAAA;AACA;AACA;CACC,SAAO;AACR;AACA;CACC,aAAa;CACb,SAAS;CACT,qBAAqB;AACtB;AACA;CACC,0BAA0B;CAC1B,gBAAgB;CAChB,iBAAiB;AAClB;AACA;CACC,aAAa;AACd;AACA;CACC,kBAAkB;AACnB;AACA;CACC,gBAAgB;CAChB,aAAa;CACb,kBAAkB;CAClB,sBAAsB;AACvB","sourcesContent":[".box-loop {\n}\n.box-loop > div {\n\tflex: 1;\n}\n.box-item {\n\tdisplay: flex;\n\tgap: 20px;\n\talign-items: flex-end;\n}\n.box-item + .box-item {\n\tborder-top: 1px solid #CCC;\n\tmargin-top: 10px;\n\tpadding-top: 10px;\n}\n.box-loop .box-item .btn-form-hold {\n\tpadding: 15px;\n}\n.btn-x-box {\n\tmargin-bottom: 6px;\n}\n.msg {\n\tmargin-top: 15px;\n\tpadding: 10px;\n\tborder-radius: 5px;\n\tborder: 1px solid #CCC;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box-loop": "FormHold_box-loop__mtKgO",
	"box-item": "FormHold_box-item__dvyFV",
	"btn-form-hold": "FormHold_btn-form-hold__ouuDA",
	"btn-x-box": "FormHold_btn-x-box__4kY62",
	"msg": "FormHold_msg__dWyqW"
};
export default ___CSS_LOADER_EXPORT___;
