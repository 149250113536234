import React from 'react';

// CSS
import c from './InputFile.module.css';

// Funções
import Input from "@cpnt/Input/Input";

const InputFile = props => {

	const {
		input,
		value,
		setValue,
		label,
	} = props;

	return (
		<div className={c['container']}>
			{!value || value === '' ? (
				<Input
					label={label}
					_={{
						...input,
						type: 'file',
						onChange: e => {
							setValue(e.target.files[0])
						},
					}}
				/>
			) : (
				<div>
					<div className={c['label']}>{label}</div>
					<div className={c['box']}>
						<div className={`${c['label']} text-one-line`}>{value?.name || 'Arquivo selecionado.'}</div>
						<div className={c['x']} onClick={() => setValue(null)}></div>
					</div>
				</div>
			)}
		</div>
	)
}

export default InputFile;