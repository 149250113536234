import React from 'react';

// CSS
import './Checkbox.css';

const Checkbox = props => {

	const {
		_,
		classNameWrap = '',
		classNameLabel = '',
		label,
		classNameSubLabel = '',
		invalid,
		smallLabel,
	} = props;

	return (
		<div className={`cpnt-checkbox ${classNameWrap}`}>
			<label className={classNameLabel}>
				<input
					type='checkbox'
					{..._}
				/>
				<span className="cpnt-checkbox-check"></span>
				<span className={`cpnt-checkbox-label ${classNameSubLabel} ${smallLabel ? 'cpnt-checkbox-label-small' : ''}`}>{label}</span>
			</label>
		</div>
	)
}

export default Checkbox;