import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FormCargo.css';

// Routes
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";

const FormCargo = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isCadastro,
		stateDados,
		setStateDados,
		after,
		stateCargoType,
		loadingGetCargoType,
		onSuccessStatus,
		onErrorStatus,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="flex flex-wrap">
						<div className="input-box box-1">
							<Input
								label="Nome do cargo/commodity"
								req
								_={{
									value: stateDados.name,
									onChange: e => setStateDados({
										...stateDados,
										name: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-1">
							<Select
								label="Tipo de cargo/commodity"
								req
								_={{
									value: stateDados.cargo_type,
									onChange: e => setStateDados({
										...stateDados,
										cargo_type: e.value,
									}),
									options: stateCargoType,
								}}
								wrap="flex-1"
								loading={loadingGetCargoType}
							/>
						</div>
					</div>
					{!isCadastro ? (
						<div className="input-box box-1">
							<Checkbox
								label="Status"
								_={{
									checked: stateDados.active,
									onChange: e => {
										setStateDados({
											...stateDados,
											active: e.target.checked ? 1 : 0,
										});
									},
								}}
								classNameWrap="padding-t-15"
							/>
						</div>
					) : null}
				</div>

				{after}
			</form>
		</>
	);
};

export default FormCargo;