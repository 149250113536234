import React, { useEffect, useRef, useState } from 'react';

// css
import './Acompanhamentos.css';

// API
import { api } from "@api/api";

// Componentes
import Button from "@cpnt/Button/Button";
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import Input from "@cpnt/Input/Input";
import { PlaceholderRender } from "@cpnt/Placeholder/Placeholder";
import PortalNaviosFormCadastro from "@cpnt/Portais/PortaisNavios/PortalNaviosFormCadastro/PortalNaviosFormCadastro";
import PortalNaviosFormEdicao from "@cpnt/Portais/PortaisNavios/PortalNaviosFormEdicao/PortalNaviosFormEdicao";
import TotalPaginacao from "@cpnt/TotalPaginacao/TotalPaginacao";

// Objetos

// Func
import { LabelNavio } from "@cpnt/LabelNavio/LabelNavio";
import PortalAcompanhamentosDetalhes from "@cpnt/Portais/PortaisAcompanhamentos/PortalAcompanhamentosDetalhes/PortalAcompanhamentosDetalhes";
import { dataApiParaDataMesDiaAno } from "@func/date";
import { msgs } from "@func/jsx";
import { msgStatus, tratarFiltrosApi } from "@func/requests";
import { initSetFiltros, setFiltrosURL } from "@func/routeRequest";
import { msg } from "@func/utils";

const Acompanhamentos = props => {

	/*
	** Filtros
	** ================================================== */
	const filtrosDefault = {
		limit: 20,
		page: 1,
		search: '',
		active: 2,
	};
	const [stateFiltros, setStateFiltros] = useState(filtrosDefault);

	// Ref filtros
	const refRequestFeito = useRef(false);
	const refFiltrosAtuais = useRef(false);
	const refGetBackup = useRef(window.location.search);
	const refIniciado = useRef(false);


	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastro: {},
		edicao: {},
		detalhes: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	/*
	** States
	** ================================================== */
	const [stateAcompanhamentoss, setStateAcompanhamentoss] = useState([]);
	const [_stateAcompanhamentoss, _setStateAcompanhamentoss] = useState({});

	/*
	** Loading
	** ================================================== */
	const [loadingGetAcompanhamentoss, setLoadingGetAcompanhamentoss] = useState(false);

	/*
	** Init - define filtros
	** ================================================== */
	useEffect(() => {
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, []);

	useEffect(() => {
		if (!refGetBackup.current !== window.location.search) {
			getAcompanhamentoss();
		}
		refIniciado.current = true;
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, [window.location.search]);

	/*
	** API - Get Acompanhamentos
	** ================================================== */
	const getAcompanhamentoss = async () => {
		if(loadingGetAcompanhamentoss) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = tratarFiltrosApi({
			limit: refFiltrosAtuais.current.limit,
			search: refFiltrosAtuais.current.search,
		});

		setLoadingGetAcompanhamentoss(true);

		const ret = await api.trackingVessels.all.get(dadosEnviar);

		_setStateAcompanhamentoss(msgStatus(ret));

		refRequestFeito.current = true;

		if(ret.status === 200) {
			setStateAcompanhamentoss(ret.data);
		} else {
			msgs([ret]);
		}

		setLoadingGetAcompanhamentoss(false);
	}

	const empresas = dados => {
		if(dados && typeof(dados) === 'string') {
			var dadosArr = dados.split(',');
			if(dadosArr && dadosArr.length) {
				return dadosArr.map((val, key) => {
					return (
						<div className="tag-min" key={key}>
							{val}
						</div>
					)
				})
			}
		}
		return '-';
	}

	const changeFiltro = (filterName, filterValue) => {
		setStateFiltros({ ...stateFiltros, [filterName]: filterValue });
		refFiltrosAtuais.current = {...stateFiltros, [filterName]: filterValue};
		setFiltrosURL(refFiltrosAtuais);
		refRequestFeito.current = false;
	};

	return (
		<>
			<div className={`pg-usuarios`}>
				<div>
					<FiltrosContainer>
						<div className="titulo cpnt-baract-titulo color-1">
							Acompanhamentoss
						</div>
						<FiltrosWrap>
							<form
								onSubmit={e => e.preventDefault()}
								className="cpnt-baract-filtros"
							>
								<div className="input-box flex-1 box-1-980">
									<Input
										label="Pesquisar"
										_={{
											value: stateFiltros.search,
        									onChange: e => changeFiltro('search', e.target.value)
										}}
										debounce
									/>
								</div>
							</form>
						</FiltrosWrap>
						{/* <Button
							_={{
								value: 'Adicionar acompanhamentos',
								className: 'btn btn-default-alt',
								onClick: e => {
									setPortais({
										...portais,
										cadastro: {
											show: true,
										}
									})
								},
							}}
						/> */}
					</FiltrosContainer>

					<div className="base">
						<div className="base-content">
							<div id="tabela-container">
								<TotalPaginacao metadata={_stateAcompanhamentoss} paginacao={stateFiltros} loading={loadingGetAcompanhamentoss} changeFiltro={changeFiltro} />
								<div className="table-wrap">
									<table className="tabela">
										<thead>
											<tr>
												<th className="">
													Vessel
												</th>
												<th>
													Charterer
												</th>
												<th>
													Port
												</th>
												<th>
													Berth
												</th>
												<th className="col-btn">
													Cargo type
												</th>
												<th className="">
													Empresas
												</th>
												<th className="col-min-10">
													Datas
												</th>
												<th className="col-btn">
													Status
												</th>
												<th className="col-btn">
													Visualizar
												</th>
											</tr>
										</thead>
										<tbody>
											<PlaceholderRender
												loading={loadingGetAcompanhamentoss}
												itens={stateAcompanhamentoss}
												request={_stateAcompanhamentoss}
												cpnt="PlaceholderAcompanhamentos"
												qtdColunas={9}
											>
												{stateAcompanhamentoss.map((val, key) => {
													return (
														<tr key={key}>
															<td data-titulo="Vessel">
																<div className="td-wrap">
																	{val.vessel || '-'}
																</div>
															</td>
															<td data-titulo="Charterer">
																<div className="td-wrap">
																	{val.charterer || '-'}
																</div>
															</td>
															<td data-titulo="Port">
																<div className="td-wrap">
																	{val.port || '-'}
																</div>
															</td>
															<td data-titulo="Berth">
																<div className="td-wrap">
																	{val.berth || '-'}
																</div>
															</td>
															<td data-titulo="Cargo type">
																<div className="td-wrap">
																	{val.cargo_type || '-'}
																</div>
															</td>
															<td data-titulo="Empresas">
																<div class="flex flex-wrap gap-5 padding-10-980">{empresas(val.extra_companies)}</div>
															</td>
															<td data-titulo="Datas">
																<div className="td-wrap align-center">
																	<div>
																		ETA: {dataApiParaDataMesDiaAno(val.eta)}
																	</div>
																	<div>
																		ETB: {dataApiParaDataMesDiaAno(val.etb)}
																	</div>
																	<div>
																		ETS: {dataApiParaDataMesDiaAno(val.ets)}
																	</div>
																</div>
															</td>

															<td data-titulo="Status do navio" className="relative">
																<div className="td-wrap align-center">
																	<LabelNavio {...val} />
																</div>
															</td>
															<td data-titulo="Visualizar" className="">
																<Button
																	_={{
																		value: 'Visualizar',
																		className: 'btn btn-default btn-min',
																		onClick: e => {
																			setPortais({
																				...portais,
																				detalhes: {
																					show: true,
																					id: val.id,
																				}
																			})
																		}
																	}}
																	classNameWrap="flex margin-auto padding-v-5"
																/>
															</td>
														</tr>
													)
												})}
											</PlaceholderRender>
										</tbody>
									</table>
								</div>
								<TotalPaginacao metadata={_stateAcompanhamentoss} paginacao={stateFiltros} loading={loadingGetAcompanhamentoss} changeFiltro={changeFiltro} />
							</div>
						</div>
					</div>
				</div>

			</div>

			<PortalAcompanhamentosDetalhes
				show={portais.detalhes.show}
				id={portais.detalhes.id}
				onClose={() => {
					setPortais({...portais, detalhes: {}})
				}}
			/>

		</>
	);
};

export { Acompanhamentos };
