import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalNewsFormCadastro.css';

// API
import { api } from "@api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import { FormNews } from "@page/Logged/News/FormNews/FormNews";

const PortalNewsFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-900',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		title: '',
		text: '',
		photo: '',
		active: '',
	};

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateFoto, setStateFoto] = useState(null);
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
		}
	}

	/*
	** Editar Berco
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			title: stateDados.title,
			text: stateDados.text,
			active: stateDados.active ? 1 : 0,
		};

		if(camposInvalidos(dadosEnviar, [
			'title',
			'text',
		], {
			text: 'Descrição',
		})) return;

		var dadosFormData = new FormData();

		if(stateFoto && stateFoto !== '') {
			dadosFormData.append('photo', stateFoto);
		}

		dadosFormData.append('title', dadosEnviar.title);
		dadosFormData.append('text', dadosEnviar.text);
		dadosFormData.append('active', dadosEnviar.active);

		setLoadingCadastrar(true);

		const ret = await api.news.insert.post(dadosFormData);

		if(ret.status === 200) {
			msg.success('News cadastrada com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
			setStateFoto(null)
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Cadastrar News"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormNews
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}
				stateFoto={stateFoto}
				setStateFoto={setStateFoto}
				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Cadastrar News',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalNewsFormCadastro;