import React, { useRef, useState, useEffect } from 'react';

import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import CurrencyInput from 'react-currency-input';
import { DebounceInput } from "react-debounce-input";
import InputMask from "react-input-mask";

// CSS
import './Input.css';

// Funções
import Tip from "@cpnt/Tip/Tip";
import Icone from "@cpnt/Icone/Icone";

import imgShowPassword from '@svg/duotone/eye.svg';

const Input = props => {

	const inputRef = useRef();

	const {
		_ = {},
		className = '',
		classNameCustomLabel = '',
		classNameLabel = '',
		classNameWrap = '',

		label,
		customLabel,

		cpfCnpj,
		debounce,
		dinheiro,
		dropDown,

		invalid,
		req,
		tip,
		toString,
		flex,
		after,
		onClick,
	} = props;

	const [stateType, setStateType] = useState(_.type ? _.type : 'text');

	useEffect(() => {
		setStateType(_.type);
	}, [_.type]);

	const changeType = (tipo1, tipo2) => {
		if(stateType === tipo1) {
			setStateType(tipo2);
		} else {
			setStateType(tipo1);
		}
	}

	if(!_.value) {
		_.value = '';
	}

	if(_.value !== undefined && _.value === null) {
		_.value = '';
	}

	if(_.defaultValue !== undefined && _.defaultValue === null) {
		_.defaultValue = '';
	}

	if(toString) {
		if(typeof(_.value) === 'number') {
			_.value = _.value.toString();
		}
		if(typeof(_.defaultValue) === 'number') {
			_.defaultValue = _.defaultValue.toString();
		}
	}

	const mascara = e => {
		let { value } = e.nextState;
		return {
			...e.nextState,
			value
		};
	}

	const renderType = () => {
		if(_.type === 'textarea') {
			return <textarea {..._} />
		}

		if(_.mask) {
			return <InputMask beforeMaskedStateChange={mascara} {..._} />
		}

		if(dinheiro) {
			return <CurrencyInput decimalSeparator="," thousandSeparator="." {..._} />
		}

		if(cpfCnpj) {
			return <CpfCnpj {..._} />
		}

		if(debounce) {
			var dadosDebounceDefault = {};
			if(debounce && typeof(debounce) === 'object') {
				dadosDebounceDefault = debounce;
			}
			return (
				<DebounceInput
					debounceTimeout={500}
					forceNotifyByEnter={true}
					forceNotifyOnBlur={true}
					minLength={0}
					{..._}
					{...dadosDebounceDefault}
				/>
			)
		}

		if(!_.mask && !dinheiro && _.type !== 'textarea' && !debounce) {
			return (
				<input
					{..._}
					type={stateType}
					{...(stateType === 'password' ? {
						style: {
							paddingRight: '50px'
						}
					} : {})}
				/>
			);
		}
	}

	return (
		<div className={`cpnt-input ${classNameWrap} ${flex ? 'flex' : ''}`}>
			<div className={`${className} ${flex ? 'flex' : ''}`} {...(onClick ? {onClick: onClick} : {})}>
				{tip ? (
					<div className={`cpnt-input-customlabel ${classNameCustomLabel}`}>
						<Tip
							tip={
								<>
									{label}
									<span className="q"></span>
									{req && <span className="cpnt-input-req">*</span>}
								</>
							}
							info
						>
							{tip}
						</Tip>
					</div>
				) : null}
				{!tip ? (
					<>
						{customLabel && <div className={`cpnt-input-customlabel ${classNameCustomLabel}`}>{customLabel}</div>}
						{label && <label className={classNameLabel}>{label}{req && <span className="cpnt-input-req">*</span>}</label>}
						{dropDown && <span className="cpnt-input-dropdown"></span>}
					</>
				) : null}
				<div className={`cpnt-input-field ${flex ? 'flex' : ''}`}>
					{renderType()}
					{_.type === 'password' ? (
						<span className="cpnt-input-minbtn" onClick={e => changeType('password', 'text')}>
							<div className="cpnt-input-btnpassword">
								<Icone img={imgShowPassword} />
							</div>
						</span>
					) : null}
					{after && <span className="cpnt-input-after">{after}</span>}
				</div>
			</div>
		</div>
	)
}

export default Input;