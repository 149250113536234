import { validarRetorno, config } from '../axios';
import api from 'axios';
import qs from 'qs';

const apiLineup = {
	insert: {
		post: async (dadosBody, dadosGet) => {
			var dadosEnviar = qs.stringify(dadosGet, { encode: false });
			return await api.post(`/lineup/insert?${dadosEnviar}`, dadosBody, {
				...config(),
				'Content-Type': 'multipart/form-data',
				'timeout': 600000,
			})
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	graphic: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/lineup/graphic?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
		post: async dadosObj => {
			return await api.post(`/lineup/graphic`, dadosObj, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
	all: {
		get: async dadosObj => {
			var dadosEnviar = qs.stringify(dadosObj, { encode: false });
			return await api.get(`/lineup/all?${dadosEnviar}`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		},
	},
};

export { apiLineup };