import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FormNews.module.css';

// Routes
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";
import InputFile from "@cpnt/InputFile/InputFile";

import c from './FormNews.module.css';

const FormNews = props => {
	const {
		onSubmit,
		stateDados,
		setStateDados,
		after,
		stateFoto,
		setStateFoto,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="flex flex-wrap">
						<div className="input-box box-1">
							<Input
								label="Título"
								req
								_={{
									value: stateDados.title,
									onChange: e => setStateDados({
										...stateDados,
										title: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-1">
							<Input
								label="Descrição"
								req
								_={{
									type: 'textarea',
									value: stateDados.text,
									onChange: e => setStateDados({
										...stateDados,
										text: e.target.value,
									}),
								}}
							/>
						</div>
						<div className={`input-box box-1 ${c['box-preview']}`}>
							{typeof(stateFoto) === 'string' ? (
								<div className={c['box-preview-img']}>
									<img src={stateFoto} alt="imagem" />
								</div>
							) : null}
							<InputFile
								label="Imagem de destaque"
								value={stateFoto}
								setValue={setStateFoto}
							/>
						</div>
						<div className="input-box box-1">
							<Checkbox
								label="Status"
								_={{
									checked: stateDados.active,
									onChange: e => {
										setStateDados({
											...stateDados,
											active: e.target.checked ? 1 : 0,
										});
									},
								}}
								classNameWrap="padding-t-15"
							/>
							<div className="desc">Deixe desmarcado para ocultar no App. Também recomendado deixar desmarcado caso a news ainda seja um rascunho.</div>
						</div>
					</div>
				</div>

				{after}
			</form>
		</>
	);
};

export { FormNews };
