import React from 'react';

// CSS
import './Button.css';

const Button = props => {

	const {
		_ = {},
		className = 'btn btn-default',
		classNameWrap = 'flex',
		classNameExtra = '',
		loading,
	} = props;

	return (
		<div className={`cpnt-btn ${classNameWrap ? classNameWrap : 'inline-flex'}`}>
			<button
				{..._}
				className={_.className ? _.className : className}
			>
				{loading ? <span className="cpnt-btn-loading"></span> : null}
				{_.value}
			</button>
		</div>
	)
}

export default Button;