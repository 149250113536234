// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".News_col-imagem__jcw78 {\n\twidth: 100px;\n}\n.News_img-thumb-list__9JVWI {\n\twidth: 80px;\n\theight: 80px;\n\tborder-radius: 3px;\n\toverflow: hidden;\n\tbox-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);\n}\n", "",{"version":3,"sources":["webpack://./src/pages/Logged/News/News.module.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;AACA;CACC,WAAW;CACX,YAAY;CACZ,kBAAkB;CAClB,gBAAgB;CAChB,wCAAwC;AACzC","sourcesContent":[".col-imagem {\n\twidth: 100px;\n}\n.img-thumb-list {\n\twidth: 80px;\n\theight: 80px;\n\tborder-radius: 3px;\n\toverflow: hidden;\n\tbox-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"col-imagem": "News_col-imagem__jcw78",
	"img-thumb-list": "News_img-thumb-list__9JVWI"
};
export default ___CSS_LOADER_EXPORT___;
