import React, { useState, useEffect } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalPDAsFormCadastro.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Portal from '@cpnt/Portal/Portal';

// Funções
import Button from "@cpnt/Button/Button";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import FormPDA from "@page/Logged/PDAs/FormPDA/FormPDA";
import { converterRealParaNumero } from "@func/convert";
import { dataParaApi } from "@func/date";

const PortalPDAsFormCadastro = props => {

	const {
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'portal-pda-form-overlay',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id_company: 0,
		id_port: 0,
		id_cargo: 0,
		id_berth: 0,
		operation_type: '',
		berth: '',
		id_vessel: 0,
		deadweight: 0,
		gross_register_tonnage: 0,
		length_overall: 0,
		rate_usd: 0,
		quantity: 0,
		days_inner_road: 0,
		days_alongside: 0,
		date: '',
	};

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(false);
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetPortos, setLoadingGetPortos] = useState(false);
	const [loadingGetNavios, setLoadingGetNavios] = useState(false);
	const [loadingGetTiposOperacoes, setLoadingGetTiposOperacoes] = useState(false);
	const [loadingGetCargos, setLoadingGetCargos] = useState(false);
	const [loadingGetBercos, setLoadingGetBercos] = useState(false);

	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [statePortos, setStatePortos] = useState([]);
	const [stateNavios, setStateNavios] = useState([]);
	const [stateTiposOperacoes, setStateTiposOperacoes] = useState([]);
	const [stateCargos, setStateCargos] = useState([]);
	const [stateBercos, setStateBercos] = useState([]);

	// Edição
	const [loadingCadastrar, setLoadingCadastrar] = useState(false);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [statePaises, setStatePaises] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			inicializarGets();
		}
	}

	/*
	** Funções GET
	** ================================================== */
	const apiGeralGetCompanies = async () => {
		apiGeral.companies({setLoadingGetEmpresas, setStateEmpresas});
	}
	const apiGeralGetPorts = async () => {
		apiGeral.ports({setLoadingGetPortos, setStatePortos});
	}
	const apiGeralGetVessels = async () => {
		apiGeral.vessels({setLoadingGetNavios, setStateNavios});
	}
	const apiGeralGetOperationsType = async () => {
		apiGeral.operationsType({setLoadingGetTiposOperacoes, setStateTiposOperacoes});
	}
	const apiGeralGetCargos = async () => {
		apiGeral.cargos({setLoadingGetCargos, setStateCargos});
	}
	const apiGeralGetBercos = async (idPorto) => {
		const retBercos = await apiGeral.berths({idPorto, setLoadingGetBercos, setStateBercos, showMsg: false});
		if(retBercos.status !== 200) {
			setStateBercos([]);
			setStateDados({
				...stateDados,
				id_berth: null,
				id_port: idPorto,
			});
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		apiGeralGetCompanies();
		apiGeralGetPorts();
		apiGeralGetVessels();
		apiGeralGetOperationsType();
		apiGeralGetCargos();
	}

	/*
	** Editar empresa
	** ================================================== */
	const cadastrar = async e => {
		e.preventDefault();

		if(loadingCadastrar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id_company: stateDados.id_company,
			id_port: stateDados.id_port,
			id_cargo: stateDados.id_cargo,
			id_berth: stateDados.id_berth,
			operation_type: stateDados.operation_type,
			berth: stateDados.berth,
			id_vessel: stateDados.id_vessel,
			deadweight: stateDados.deadweight,
			gross_register_tonnage: stateDados.gross_register_tonnage,
			length_overall: stateDados.length_overall,
			rate_usd: converterRealParaNumero(stateDados.rate_usd),
			quantity: stateDados.quantity,
			days_inner_road: stateDados.days_inner_road,
			days_alongside: stateDados.days_alongside,
			date: dataParaApi()
		};

		if(camposInvalidos(dadosEnviar, [
			'id_company',
			'id_port',
			'id_cargo',
			'operation_type',
			'id_vessel',
		])) return;

		setLoadingCadastrar(true);

		const ret = await api.pdas.insert.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('PDA cadastrado com sucesso.');
			onSuccess({
				ret: ret,
			});
			setStateDados(camposDefault);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingCadastrar(false);
	}

	return (
		<Portal
			titulo="Cadastrar PDA"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			<FormPDA
				onSubmit={cadastrar}
				stateDados={stateDados}
				setStateDados={setStateDados}

				loadingGetEmpresas={loadingGetEmpresas}
				loadingGetPortos={loadingGetPortos}
				loadingGetNavios={loadingGetNavios}
				loadingGetTiposOperacoes={loadingGetTiposOperacoes}
				loadingGetCargos={loadingGetCargos}
				loadingGetBercos={loadingGetBercos}

				stateEmpresas={stateEmpresas}
				statePortos={statePortos}
				stateNavios={stateNavios}
				stateTiposOperacoes={stateTiposOperacoes}
				stateCargos={stateCargos}
				stateBercos={stateBercos}

				getCompanies={apiGeralGetCompanies}
				getPorts={apiGeralGetPorts}
				getVessels={apiGeralGetVessels}
				getOperationsType={apiGeralGetOperationsType}
				getCargos={apiGeralGetCargos}
				getBercos={apiGeralGetBercos}

				after={
					<div className="form-bottom">
						<Button
							_={{
								value: 'Cancelar',
								className: 'btn btn-1',
								type: 'button',
								onClick: onClose,
							}}
						/>
						<Button
							_={{
								value: 'Cadastrar PDA',
								onClick: cadastrar
							}}
							loading={loadingCadastrar}
							classNameWrap="margin-l-15"
						/>
					</div>
				}
			/>
		</Portal>
	);
};

export default PortalPDAsFormCadastro;