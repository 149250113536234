import React, { useContext, useState, useEffect } from 'react';

// Libs
import { Link, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import './AtivarCadastro.css';

// Componentes
import Button from "@cpnt/Button/Button";
import Input from "@cpnt/Input/Input";

// Funções
import { api } from "@api/api";
import Info from "@cpnt/Info/Info";
import { msgs } from "@func/jsx";
import { msg } from "@func/utils";

import CriteriosSenha, { validarSenha } from "@cpnt/CriteriosSenha/CriteriosSenha";

const AtivarCadastro = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();

	const camposDefault = {
		new_password: '',
	}

	const [loadingValidarToken, setLoadingValidarToken] = useState(false);
	const [stateDados, setStateDados] = useState(camposDefault);
	const [stateCriteriosSenha, setStateCriteriosSenha] = useState([]);
	const [token, setToken] = useState(false);

	const [passo, setPasso] = useState(0);

	useEffect(() => {
		const filtrosAtuais = new URLSearchParams(window.location.search);
		var getToken = filtrosAtuais.get('token');
		setToken(getToken);
	}, []);

	const validarToken = async (e) => {
		e.preventDefault();

		if(loadingValidarToken) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		if(typeof(token) !== 'string') {
			msg.error('Token inválido. Verifique seu e-mail e clique no link que foi enviado para ativar seu acesso.');
			return false;
		}

		const dadosEnviar = {
			new_password: stateDados.new_password,
		};

		var senha = validarSenha(dadosEnviar.new_password);

		if(!senha.valido) {
			msg.error('A senha não atende aos critérios necessários. Insira uma senha segura.');
			return;
		}

		setLoadingValidarToken(true);

		const ret = await api.users.activate.put(token, dadosEnviar);

		if(ret.status === 200) {
			msg.success('Cadastro ativado com sucesso!');
			setPasso(1);
		} else {
			msgs([ret]);
		}

		setLoadingValidarToken(false);
	}

	const renderTelaCorreta = () => {

		if(typeof(token) !== 'string') {
			return (
				<div>
					<Info
						icone="info"
						texto={
							<>
								<h3>Token não identificado</h3>
								<p>Para ativar seu acesso, é necessário clicar no link enviado para seu e-mail.</p>
								<p>Caso você tenha clicado no link e mesmo assim esteja recebendo essa mensagem, entre em contato com o administrador.</p>
								<div className="margin-t-15 align-center"><Link to="/login">Ir para tela de login</Link></div>
							</>
						}
					/>
				</div>
			)
		}

		/*
		** Redefinir senha
		** ================================================== */
		if(passo === 0) {
			return (
				<div>
					<h3 className="titulo">Ativar cadastro</h3>
					<div className="desc">
						<p>Para ativar o seu cadastro, informe uma senha de acesso. Após cadastrar a senha você poderá utilizar suas credênciais para acessar o sistema.</p>
					</div>
					<form onSubmit={validarToken}>
						<div className="inputs-box">
							<div className="input-box">
								<Input
									label="Senha"
									req
									_={{
										type: 'password',
										value: stateDados.new_password,
										onChange: e => setStateDados({...stateDados, new_password: e.target.value}),
									}}
								/>
							</div>
							<div className="padding-5 margin-t-10">
								<CriteriosSenha
									senha={stateDados.new_password}
									stateCriterios={stateCriteriosSenha}
									setStateCriterios={setStateCriteriosSenha}
								/>
							</div>
							<div className="input-box input-box-btn">
								<Button
									_={{
										value: 'Cadastrar senha e ativar acesso',
										onClick: validarToken,
									}}
									loading={loadingValidarToken}
								/>
							</div>
							<div className="input-box input-box-btn align-center">
								<Link to="/login">Ir para login</Link>
							</div>
						</div>
					</form>
				</div>
			)
		}

		return (
			<div>
				<Info
					icone="sucesso"
					texto={
						<>
							<h3>Cadastro ativado com sucesso!</h3>
							<p>Você já pode fazer login utilizando suas credênciais.</p>
							<div className="margin-t-15 align-center"><Link to="/login">Fazer login</Link></div>
						</>
					}
				/>
			</div>
		)
	}

	return (
		<div className="pg-login">
			{renderTelaCorreta()}

			{/* <div className="flex flex-center">
				<span className="bg-1 padding-5" onClick={e => setPasso(passo - 1)}>Voltar</span> | <span className="bg-1 padding-5" onClick={e => setPasso(passo + 1)}>Próximo</span>
			</div> */}
		</div>
	)
}

export default AtivarCadastro;