import React, { useEffect, useState } from 'react';
import { toast as msg } from 'react-toastify';

// css
import './PortalPDAsFormEdicao.css';

// API
import { api, apiGeral } from "@api/api";

// Componentes
import Info from '@cpnt/Info/Info';
import Loading from '@cpnt/Loading/Loading';
import Portal from '@cpnt/Portal/Portal';

// Funções
import FormEmpresa from "@page/Logged/Empresas/FormEmpresa/FormEmpresa";
import { msgStatus } from "@func/requests";
import { msgs } from "@func/jsx";
import { camposInvalidos } from "@func/validate";
import Button from "@cpnt/Button/Button";
import PortalConfirmacao from "@cpnt/Portais/PortaisGerais/PortalConfirmacao/PortalConfirmacao";
import { _API_TIPO_CARGO_COMMODITY } from "@config/_tempSelect";
import FormPDA from "@page/Logged/PDAs/FormPDA/FormPDA";
import { converterRealParaNumero } from "@func/convert";
import { dataHoraMinSegApiParaDataApi, dataParaApi } from "@func/date";

const PortalPDAsFormEdicao = props => {

	const {
		id,
		show,
		wrap = '',
		onClose = () => null,
		closeOnEsc = false,
		className = '',
		portalClassName = 'w-900',
		onSuccess = () => null,
		onError = () => null,
	} = props;

	/*
	** Campos default
	** ================================================== */
	const camposDefault = {
		id: 0,
		id_company: 0,
		id_port: 0,
		id_cargo: 0,
		id_berth: 0,
		operation_type: '',
		berth: '',
		id_vessel: 0,
		deadweight: 0,
		gross_register_tonnage: 0,
		length_overall: 0,
		rate_usd: 0,
		quantity: 0,
		days_inner_road: 0,
		days_alongside: 0,
		date: ''
	};

	/*
	** Portais
	** ================================================== */
	const [showPortalConfirmacaoApagar, setShowPortalConfirmacaoApagar] = useState(false);

	/*
	** Loading
	** ================================================== */
	const [loading, setLoading] = useState(true);

	// Edição
	const [loadingEditar, setLoadingEditar] = useState(false);
	const [loadingApagar, setLoadingApagar] = useState(false);
	const [loadingGetPaises, setLoadingGetPaises] = useState(false);

	/*
	** States
	** ================================================== */
	const [stateDados, setStateDados] = useState(camposDefault);
	const [_stateDados, _setStateDados] = useState({});
	const [statePaises, setStatePaises] = useState([]);

	/*
	** Edição
	** ================================================== */
	const [loadingGetEmpresas, setLoadingGetEmpresas] = useState(false);
	const [loadingGetPortos, setLoadingGetPortos] = useState(false);
	const [loadingGetNavios, setLoadingGetNavios] = useState(false);
	const [loadingGetTiposOperacoes, setLoadingGetTiposOperacoes] = useState(false);
	const [loadingGetCargos, setLoadingGetCargos] = useState(false);
	const [loadingGetBercos, setLoadingGetBercos] = useState(false);

	const [stateEmpresas, setStateEmpresas] = useState([]);
	const [statePortos, setStatePortos] = useState([]);
	const [stateNavios, setStateNavios] = useState([]);
	const [stateTiposOperacoes, setStateTiposOperacoes] = useState([]);
	const [stateCargos, setStateCargos] = useState([]);
	const [stateBercos, setStateBercos] = useState([]);

	/*
	** Init
	** ================================================== */
	useEffect(() => {
		init();
	}, [show]);

	/*
	** Init - Delay
	** ================================================== */
	const init = () => {
		if(show === true) {
			setStateDados(camposDefault);
			setLoading(true);
			inicializarGets();
		}
	}

	/*
	** Funções GET
	** ================================================== */
	const apiGeralGetCompanies = async () => {
		apiGeral.companies({setLoadingGetEmpresas, setStateEmpresas});
	}
	const apiGeralGetPorts = async () => {
		apiGeral.ports({setLoadingGetPortos, setStatePortos});
	}
	const apiGeralGetVessels = async () => {
		apiGeral.vessels({setLoadingGetNavios, setStateNavios});
	}
	const apiGeralGetOperationsType = async () => {
		apiGeral.operationsType({setLoadingGetTiposOperacoes, setStateTiposOperacoes});
	}
	const apiGeralGetCargos = async () => {
		apiGeral.cargos({setLoadingGetCargos, setStateCargos});
	}
	const apiGeralGetBercos = async (idPorto) => {
		const retBercos = await apiGeral.berths({idPorto, setLoadingGetBercos, setStateBercos, showMsg: false});
		if(retBercos.status !== 200) {
			setStateBercos([]);
			setStateDados({
				...stateDados,
				id_berth: null,
				id_port: idPorto,
			});
		}
	}

	/*
	** INIT Gets
	** ================================================== */
	const inicializarGets = async () => {
		const retGetItem = await api.pdas.getOne.get(id);

		if(retGetItem.status === 200) {
			setStateDados(retGetItem.data);
			_setStateDados(msgStatus(retGetItem));

			apiGeralGetCompanies();
			apiGeralGetPorts();
			apiGeralGetVessels();
			apiGeralGetOperationsType();
			apiGeralGetCargos();
			apiGeralGetBercos(retGetItem.data.id_port);
		} else {
			_setStateDados(msgStatus(retGetItem));
			msgs([retGetItem]);
		}

		setLoading(false);
	}


	/*
	** Editar Navio
	** ================================================== */
	const editar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			...camposDefault,
			id: stateDados.id,
			id_company: stateDados.id_company,
			id_port: stateDados.id_port,
			id_cargo: stateDados.id_cargo,
			id_berth: stateDados.id_berth,
			operation_type: stateDados.operation_type,
			berth: stateDados.berth,
			id_vessel: stateDados.id_vessel,
			deadweight: stateDados.deadweight,
			gross_register_tonnage: stateDados.gross_register_tonnage,
			length_overall: stateDados.length_overall,
			rate_usd: converterRealParaNumero(stateDados.rate_usd),
			quantity: stateDados.quantity,
			days_inner_road: stateDados.days_inner_road,
			days_alongside: stateDados.days_alongside,
			date: dataHoraMinSegApiParaDataApi(stateDados.date),
		};

		if(camposInvalidos(dadosEnviar, [
			'id',
			'id_company',
			'id_port',
			'id_cargo',
			'operation_type',
			'id_vessel',
		])) return;

		setLoadingEditar(true);

		const ret = await api.pdas.update.put(dadosEnviar);

		if(ret.status === 200) {
			msg.success('PDA atualizado com sucesso.');
			onSuccess({
				ret: ret,
			})
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingEditar(false);
	}

	/*
	** Deletar Navio
	** ================================================== */
	const apagar = async e => {
		e.preventDefault();

		if(loadingEditar) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingApagar(true);

		const ret = await api.pdas.delete(id);

		if(ret.status === 200) {
			msg.success('PDA removido com sucesso.');
			onSuccess({
				ret: ret,
			});
			setShowPortalConfirmacaoApagar(false);
		} else {
			msgs([ret]);
			onError({
				ret: ret,
			});
		}

		setLoadingApagar(false);
	}

	return (
		<Portal
			titulo="Editar PDA"
			show={show}
			closeOnEsc={closeOnEsc}
			onClose={onClose}
			className={portalClassName}
		>
			{loading ? (
				<Loading
					texto="Carregando dados, aguarde..."
					wrap="padding-30"
				/>
			) : (
				<div className={wrap}>
					<div className={className}>
						{_stateDados.status === 200 ? (
							<FormPDA
								onSubmit={editar}
								stateDados={stateDados}
								setStateDados={setStateDados}

								loadingGetEmpresas={loadingGetEmpresas}
								loadingGetPortos={loadingGetPortos}
								loadingGetNavios={loadingGetNavios}
								loadingGetTiposOperacoes={loadingGetTiposOperacoes}
								loadingGetCargos={loadingGetCargos}
								loadingGetBercos={loadingGetBercos}

								stateEmpresas={stateEmpresas}
								statePortos={statePortos}
								stateNavios={stateNavios}
								stateTiposOperacoes={stateTiposOperacoes}
								stateCargos={stateCargos}
								stateBercos={stateBercos}

								getCompanies={apiGeralGetCompanies}
								getPorts={apiGeralGetPorts}
								getVessels={apiGeralGetVessels}
								getOperationsType={apiGeralGetOperationsType}
								getCargos={apiGeralGetCargos}
								getBercos={apiGeralGetBercos}

								after={
									<div className="form-bottom">
										<Button
											_={{
												value: 'Apagar',
												className: 'btn btn-red',
												type: 'button',
												onClick: e => {
													setShowPortalConfirmacaoApagar(true);
												},
											}}
										/>
										<Button
											_={{
												value: 'Cancelar',
												className: 'btn btn-1',
												type: 'button',
												onClick: onClose,
											}}
											classNameWrap="margin-l-auto"
										/>
										<Button
											_={{
												value: 'Salvar dados',
												onClick: editar
											}}
											loading={loadingEditar}
											classNameWrap="margin-l-15"
										/>
									</div>
								}
							/>
						) : (
							<Info
								texto={_stateDados.msg}
								icone="erro"
							/>
						)}
					</div>
				</div>
			)}

			<PortalConfirmacao
				show={showPortalConfirmacaoApagar}
				titulo="Deseja realmente apagar esse navio?"
				desc={
					<>
						<div>O PDA com ID "<strong>{stateDados.id}</strong>" será removido. Essa ação não poderá ser desfeita. Qualquer dado associado a esse PDA também será removido.</div>
						<div className="margin-t-10">Deseja continuar?</div>
					</>
				}
				onClose={() => setShowPortalConfirmacaoApagar(false)}
				onClickCancelar={() => setShowPortalConfirmacaoApagar(false)}
				onClickConfirmar={apagar}
				loadingConfirmar={loadingApagar}
			/>
		</Portal>
	);
};

export default PortalPDAsFormEdicao;