import ReactECharts from 'echarts-for-react';
import React, { useEffect, useRef, useState } from 'react';

// css
import './LineUp.css';

// API
import { api } from "@api/api";

// Componentes
import { FiltrosContainer } from "@cpnt/FiltrosContainer/FiltrosContainer";
import FiltrosWrap from "@cpnt/FiltrosWrap/FiltrosWrap";
import { Select } from "@cpnt/Select/Select";

// Objetos

// Func
import { msgs } from "@func/jsx";
import { msgStatus, tratarFiltrosApi } from "@func/requests";
import { initSetFiltros, setFiltrosURL } from "@func/routeRequest";
import { msg } from "@func/utils";
import { formatarDadosGraficoBar, formatarDadosGraficoPie, formatarDadosGraficoPie2, GRAFICO_BAR_CONFIG, GRAFICO_PIE2_CONFIG, GRAFICO_PIE_CONFIG } from "./graficosObj";
import Button from "@cpnt/Button/Button";
import Icone from "@cpnt/Icone/Icone";

import imgIconeExcel from './file-spreadsheet.svg';
import { dadosEnviarFormData } from "@func/files";
import Loading from "@cpnt/Loading/Loading";
import useWindowDimension from "@hook/useWindowDimensions";
import { tamanhoTela } from "@func/hooks";

const LineUp = props => {

	const screen = useWindowDimension();

	/*
	** Filtros
	** ================================================== */
	const filtrosDefault = {
		field: null,
		graphic_type: '',
	};
	const [stateFiltros, setStateFiltros] = useState(filtrosDefault);

	// Ref filtros
	const refRequestFeito = useRef(false);
	const refFiltrosAtuais = useRef(false);
	const refGetBackup = useRef(window.location.search);
	const refIniciado = useRef(false);

	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastro: {},
		edicao: {},
		detalhes: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	/*
	** States
	** ================================================== */
	const [stateGraficoChartererOptions, setStateGraficoChartererOptions] = useState(GRAFICO_BAR_CONFIG);
	const [stateGraficoCargoesOptions, setStateGraficoCargoesOptions] = useState(GRAFICO_PIE_CONFIG);
	const [stateGraficoDestinationOptions, setStateGraficoDestinationOptions] = useState(GRAFICO_PIE2_CONFIG);
	const [stateFiltroGrafico, setStateFiltroGrafico] = useState({
		type: 'grains',
		field: 'port',
	});
	const [_stateGraficos, _setStateGraficos] = useState({});

	/*
	** Loading
	** ================================================== */
	const [loadingGetGraficos, setLoadingGetGraficos] = useState(false);
	const [loadingEnviarArquivo, setLoadingEnviarArquivo] = useState(false);

	/*
	** Init - define filtros
	** ================================================== */
	useEffect(() => {
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, []);

	useEffect(() => {
		if (!refGetBackup.current !== window.location.search) {
			getGraficos('port');
		}
		refIniciado.current = true;
		initSetFiltros({filtrosDefault, setStateFiltros, refFiltrosAtuais, refGetBackup});
	}, [window.location.search]);

	/*
	** API - Get LineUp
	** ================================================== */
	const getGraficos = async (tipoObj) => {
		if(loadingGetGraficos) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		setLoadingGetGraficos(true);

		const filtro = {
			...stateFiltroGrafico,
			...tipoObj,
		};

		const retCargoes = await api.lineup.graphic.get(filtro);
		const retDestination = await api.lineup.graphic.get(filtro);
		const retCharterer = await api.lineup.graphic.get(filtro);

		_setStateGraficos(msgStatus(retCargoes));

		var dadosFormatadosPie = formatarDadosGraficoPie(retCargoes.data);
		setStateGraficoCargoesOptions(dadosFormatadosPie);

		var dadosFormatadosDestination = formatarDadosGraficoPie2(retDestination.data);
		setStateGraficoDestinationOptions(dadosFormatadosDestination);

		var dadosFormatadosBar = formatarDadosGraficoBar(retCharterer.data);
		setStateGraficoChartererOptions(dadosFormatadosBar);

		// if(retCargoes.status === 200) {
		// } else {
		// 	msgs([retCargoes]);
		// }

		setLoadingGetGraficos(false);
	}

	const validarArquivoSendoEnviado = e => {
		if(loadingEnviarArquivo) {
			msg.warning('Outro arquivo já está sendo enviado, por favor aguarde... ');
			return;
		}
	}

	const importarArquivoExcel = async (e) => {
		if(loadingEnviarArquivo) {
			msg.warning('O arquivo está sendo enviado, por favor aguarde... ');
			return;
		}

		var dadosFormData = new FormData();
		dadosFormData.append('file', e.target.files[0]);

		setLoadingEnviarArquivo(true);

		const ret = await api.lineup.insert.post(dadosFormData);

		if(ret.status === 200) {
			msg.success('LineUp atualizado com sucesso.');
			getGraficos(stateFiltroGrafico);
		} else {
			msgs([ret]);
		}

		setLoadingEnviarArquivo(false);
	}

	// const responsivo = tamanhoTela([
	// 	{
	// 		width: 1580,
	// 		dados: {
	// 			graficoBarHeight: '300px',
	// 			graficoPieHeight: '300px',
	// 			configGraficoPizza: stateGraficoCargoesOptions,
	// 		}
	// 	},
	// 	{
	// 		width: 1380,
	// 		dados: {
	// 			graficoBarHeight: '250px',
	// 			graficoPieHeight: '250px',
	// 			configGraficoPizza: stateGraficoCargoesOptions,
	// 		}
	// 	},
	// 	{
	// 		width: 1180,
	// 		dados: {
	// 			graficoBarHeight: '250px',
	// 			graficoPieHeight: '900px',
	// 			configGraficoPizza: {
	// 				...stateGraficoCargoesOptions,
	// 				series: [
	// 					{
	// 						type: 'pie',
	// 						radius: '40%',
	// 						center: ['50%', '75%'],
	// 						roseType: 'area',
	// 						data: [...stateGraficoCargoesOptions.series[0].data || []],
	// 					},
	// 					{
	// 						type: 'pie',
	// 						radius: '40%',
	// 						center: ['30%', '25%'],
	// 						data: [...stateGraficoCargoesOptions.series[1].data || []],
	// 					},
	// 				]
	// 			}
	// 		}
	// 	},
	// 	{
	// 		width: 780,
	// 		dados: {
	// 			graficoBarHeight: '250px',
	// 			graficoPieHeight: '900px',
	// 			configGraficoPizza: {
	// 				...stateGraficoCargoesOptions,
	// 				legend: {
	// 					orient: 'horizontal',
	// 					type: 'scroll',
	// 				},
	// 				series: [
	// 					{
	// 						type: 'pie',
	// 						radius: '50%',
	// 						center: ['50%', '65%'],
	// 						roseType: 'area',
	// 						data: [...stateGraficoCargoesOptions.series[0].data || []],
	// 					},
	// 					{
	// 						type: 'pie',
	// 						radius: '50%',
	// 						center: ['50%', '25%'],
	// 						data: [...stateGraficoCargoesOptions.series[1].data || []],
	// 					},
	// 				]
	// 			}
	// 		}
	// 	},
	// ], {
	// 	graficoBarHeight: '400px',
	// 	graficoPieHeight: '400px',
	// 	configGraficoPizza: stateGraficoCargoesOptions,
	// }, screen);

	return (
		<>
			<div className={`pg-graficos`}>
				<div>
					<FiltrosContainer>
						<div className="titulo cpnt-baract-titulo color-1">
							Line Up
						</div>
						<FiltrosWrap>
							<form
								onSubmit={e => e.preventDefault()}
								className="cpnt-baract-filtros"
							>
								{/* <div className="input-box flex-1 box-1-980">
									<div className="pg-lineup-btn-importar">
										<div className="cpnt-select-label"><span>&nbsp;</span></div>
										<div className="cpnt-btn">
											<label className="button btn btn-default">
												{loadingEnviarArquivo && <span className="color-black" onClick={validarArquivoSendoEnviado}><Loading absolute alt texto="Enviando, aguarde..." /></span>}
												<Icone
													img={imgIconeExcel}
													cor="#FFF"
												/>
												<span className="margin-l-10">Importar arquivo do Excel</span>
												{!loadingEnviarArquivo && <input type="file" onChange={importarArquivoExcel} className="none" />}
											</label>
										</div>
									</div>
								</div> */}
								<div className="input-box flex-1 w-300">
									<Select
										label="Tipo"
										_={{
											value: stateFiltroGrafico.type,
        									onChange: e => {
												getGraficos({
													type: e.value,
												});
												setStateFiltroGrafico({
													...stateFiltroGrafico,
													type: e.value,
												});
											},
											options: [
												{value: 'grains', label: 'Grains'},
												{value: 'fertilizers', label: 'Fertilizers'},
												{value: 'sugar', label: 'Sugar'},
											],
										}}
										loading={loadingGetGraficos}
									/>
								</div>
								<div className="input-box flex-1 w-300">
									<Select
										label="Agrupar por"
										_={{
											value: stateFiltroGrafico.field,
        									onChange: e => {
												getGraficos({
													field: e.value,
												});
												setStateFiltroGrafico({
													...stateFiltroGrafico,
													field: e.value,
												});
											},
											options: [
												{value: 'vessel', label: 'Vessel'},
												{value: 'status', label: 'Status'},
												{value: 'cargoes', label: 'Cargoes'},
												{value: 'charterer', label: 'Charterer'},
												{value: 'destination', label: 'Destination'},
												{value: 'berth', label: 'Berth'},
												{value: 'port', label: 'Port'},
											],
										}}
										loading={loadingGetGraficos}
									/>
								</div>
							</form>
						</FiltrosWrap>
					</FiltrosContainer>

					<div className="pg-graficos-graf-container">
						<div className="base">
							<div className="base-content">
								<div className="graf-boxes-container">
									<div className="graf-boxes box">
										<div className="graf-box graf-box-1">
											<div className="graf-box-inner">
												<div className="graf-box-titulo">
													Soma de QTITY por CARGOES
												</div>
												<div className="graf-box-grafico">
													<ReactECharts
														option={stateGraficoCargoesOptions}
														notMerge={true}
														lazyUpdate={true}
														style={{height: '300px', width: '100%'}}
													/>
												</div>
											</div>
										</div>
										<div className="graf-box graf-box-2">
											<div className="graf-box-inner">
												<div className="graf-box-titulo">
													Soma de QTITY por DESTINATION
												</div>
												<div className="graf-box-grafico">
													<ReactECharts
														option={stateGraficoDestinationOptions}
														notMerge={true}
														lazyUpdate={true}
														style={{height: '300px', width: '100%'}}
														// style={{height: responsivo.graficoPieHeight, width: '100%'}}
													/>
												</div>
											</div>
										</div>
										<div className="graf-box graf-box-3">
											<div className="graf-box-inner">
												<div className="graf-box-titulo">
													Soma de QTITY por CHARTERER
												</div>
												<div className="graf-box-grafico">
													<ReactECharts
														option={stateGraficoChartererOptions}
														notMerge={true}
														lazyUpdate={true}
														style={{height: '300px', width: '100%'}}
													/>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

		</>
	);
};

export { LineUp };
