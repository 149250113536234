import { components } from 'react-select';
const { Option } = components;

export const OptionDefault = (props) => {
	return (
		<Option {...props}>
			<div className="cpnt-select-wrap">
				{props.data.icon &&
					<div className="cpnt-select-icone-wrap">
						<div className={`cpnt-select-icone bg ${props.data.iconClass}`} style={{backgroundImage: `url(${props.data.icon})`}}></div>
					</div>
				}
				<div className={`cpnt-select-label ${props.data.req === true ? 'cpnt-select-label-req' : ''}`}>{props.data.label}</div>
			</div>
		</Option>
	)
};
