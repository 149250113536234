import { BYPASS_TESTE_MENU, DEV } from "@config/config";

/*
** Sidebar Admin
** ================================================== */
const MENU_USUARIO = {
	menu: [
		{
			title: 'Bem Vindo',
			link: '/',
			// icon: 'empresas',
		},
		{
			title: 'Logout',
			link: '/logout',
			// icon: 'uploads',
		},
	]
};

// Caso seja admin e em desenvolvimento, exibe página de testes
// if(BYPASS_TESTE_MENU) {
// 	MENU_USUARIO.menu.push({
// 		title: false,
// 		itens: [
// 			{
// 				title: 'Testes (dev)',
// 				link: '/testes',
// 				icon: 'pdas.svg',
// 			},
// 		]
// 	})
// }

export { MENU_USUARIO };
