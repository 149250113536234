import React, { useContext, useState } from 'react';

// Libs
import { Link, useNavigate } from 'react-router-dom';

// Store
import StoreContext from '@context/StoreContext';

// CSS
import './Login.css';

// Componentes
import Button from "@cpnt/Button/Button";
import Input from "@cpnt/Input/Input";

// Funções
import isLogado, { salvarSessaoLocal } from "@func/auth";
import { msgs } from "@func/jsx";
import { msg } from "@func/utils";
import { camposInvalidos, validarEmail } from "@func/validate";
import { api } from "@api/api";

const Login = props => {

	const [store, setStore] = useContext(StoreContext);
	const navigate = useNavigate();

	const camposDefault = {
		email: '',
		password: '',
	}

	const [loadingLogin, setLoadingLogin] = useState(false);
	const [stateDados, setStateDados] = useState(camposDefault);

	const fazerLogin = async (e) => {
		e.preventDefault();

		if(loadingLogin) {
			msg.warning('Por favor, aguarde...');
			return false;
		}

		const dadosEnviar = {
			email: stateDados.email,
			password: stateDados.password,
		};

		if(camposInvalidos(dadosEnviar)) return;
		if(!validarEmail(dadosEnviar.email)) return;

		setLoadingLogin(true);

		const ret = await api.login.post(dadosEnviar);

		if(ret.status === 200) {
			msg.success('Login realizado com sucesso.');

			const salvarSessao = salvarSessaoLocal({
				user: ret.data,
				token: ret.auth.token,
			});

			if(salvarSessao) {
				setStore({
					...store,
					...isLogado(),
				});
			}

		} else {
			msgs([ret]);
		}

		setLoadingLogin(false);
	}

	return (
		<div className="pg-login">
			<h3 className="titulo">Login</h3>
			<div className="desc">
				<p>Insira seu e-mail e senha para começar.</p>
			</div>

			<form onSubmit={fazerLogin} className="inputs-box">
				<div className="input-box">
					<Input
						label="E-mail"
						_={{
							type: 'email',
							value: stateDados.email,
							onChange: e => setStateDados({...stateDados, email: e.target.value}),
						}}
					/>
				</div>
				<div className="input-box">
					<Input
						label="Senha"
						_={{
							type: 'password',
							value: stateDados.password,
							onChange: e => setStateDados({...stateDados, password: e.target.value}),
						}}
					/>
				</div>
				<div className="input-box input-box-btn">
					<Button
						_={{
							value: 'Entrar',
							onClick: fazerLogin,
						}}
						loading={loadingLogin}
					/>
				</div>
				<div className="input-box input-box-btn align-center">
					<Link to="/recuperar-senha">Esqueci a senha</Link>
				</div>
			</form>
		</div>
	)
}

export default Login;