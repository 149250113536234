/*
** FUnções de conversão
** ================================================== */
export const converterRealParaNumero = (val, inverter) => {
	if(!isNaN(val) && val === '') {
		return (inverter === true ? '0' : 0);
	}
	if(!val) {
		return 0;
	}
	if(inverter === true) {
		return val.toString().replace('.',',');
	}
	if(typeof(val) === 'number') {
		return val;
	}
	val = val.toString();
	val = val.replaceAll('.', '');
	val = val.replaceAll(',', '.');
	val = parseFloat(val);
	return val;
}
