/*
** Funções de números
** ================================================== */

export const range = (start, stop, step) => {
    var a = [start], b = start;
    while (b < stop) {
        a.push(b += step || 1);
    }
    return (b > stop) ? a.slice(0,-1) : a;
}

export const numeroAleatorio = (max = 15, inicio = 1) => {
	return Math.floor(Math.random() * max) + inicio;
}

export const toFixed = (numero, qtdCasas = 2) => {
	if(typeof(numero) === 'number') {
		if(numero > 0) {
			return numero.toFixed(qtdCasas);
		}
	}
	return numero;
}

export const isNumeroDiferente = (antigo, novo) => {
	var retorno = false;
	if(antigo !== novo) {
		if(isNumeric(antigo) && isNumeric(novo)) {
			if(Number(antigo) !== Number(novo)) {
				retorno = true;
			} else {
				retorno = false;
			}
		} else {
			retorno = true;
		}
	}
	return retorno;
}

export const isNumeric = value => {
    return /^-?\d+$/.test(value);
}

export const converterNumerosParaNumeric = (dados = {}) => {
	var dadosConvertidosParaNumeric = {};
	Object.keys(dados).map((val, key) => {
		if(dados[val] && dados[val] !== false && dados[val] !== '' && isNumeric(dados[val])) {
			dadosConvertidosParaNumeric[val] = Number(dados[val]);
		} else {
			dadosConvertidosParaNumeric[val] = dados[val];
		}
	});

	return dadosConvertidosParaNumeric;
}
