import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FormUsuario.css';

// Routes
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";
import PortalEmpresasFormCadastro from "@cpnt/Portais/PortaisEmpresas/PortalEmpresasFormCadastro/PortalEmpresasFormCadastro";

const FormUsuario = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isCadastro,
		stateDados,
		setStateDados,
		after,
		onSuccessStatus,
		onErrorStatus,
		stateEmpresas,
		loadingGetEmpresas,
		getCompanies,
	} = props;

	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastroEmpresa: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="flex flex-wrap">
						<div className="input-box box-1">
							<Select
								label="Empresa"
								tip="Caso esse usuário pertença a alguma empresa, selecione a empresa aqui."
								req
								_={{
									value: stateDados.id_company,
									onChange: e => setStateDados({
										...stateDados,
										id_company: e.value,
									}),
									options: stateEmpresas,
								}}
								btnAdd
								onClickBtnAdd={e => {
									setPortais({
										...portais,
										cadastroEmpresa: {
											show: true,
										}
									})
								}}
								wrap="flex-1"
								loading={loadingGetEmpresas}
							/>
						</div>
						<div className="input-box box-1">
							<Input
								label="Nome do usuário"
								req
								_={{
									value: stateDados.name,
									onChange: e => setStateDados({
										...stateDados,
										name: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-1">
							<Input
								label="E-mail"
								req
								_={{
									value: stateDados.email,
									onChange: e => {
										setStateDados({
											...stateDados,
											email: e.target.value,
										});
									},
								}}
							/>
						</div>
					</div>
					{!isCadastro ? (
						<>
							{stateDados.informed_company ? (
								<div className="input-box box-1">
									<Input
										label="Empresa informada no cadastro"
										tip={
											<>
												<div>Empresa que o usuário informou no momento do cadastro. Como ele pode inserir qualquer informação, esse campo será utilizado apenas como informativo.</div>
												<div className="margin-t-10">Para melhor organização, é recomendado criar a empresa no cadastro de empresa, e associar esse usuário a empresa cadastrada, dessa forma é possível ter um controle melhor dos usuários por empresa.</div>
											</>
										}
										_={{
											value: stateDados.informed_company,
											disabled: true,
										}}
									/>
								</div>
							) : null}
							<div className="input-box box-1">
								<Checkbox
									label="Status"
									_={{
										checked: stateDados.active,
										onChange: e => {
											setStateDados({
												...stateDados,
												active: e.target.checked ? 1 : 0,
											});
										},
									}}
									classNameWrap="padding-t-15"
								/>
							</div>
						</>
					) : null}
				</div>

				{after}
			</form>

			<PortalEmpresasFormCadastro
				show={portais.cadastroEmpresa.show}
				onClose={() => {
					setPortais({...portais, cadastroEmpresa: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastroEmpresa: {}});
					getCompanies();
					setStateDados({
						...stateDados,
						id_company: e.ret.data.id,
					});
				}}
				closeOnEsc={false}
			/>
		</>
	);
};

export default FormUsuario;