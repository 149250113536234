import { validarRetorno, config } from '../axios';
import api from 'axios';

const apiLogin = {
	post: async dados => {
		return await api.post(`/login`, dados, config())
		.then(retorno => {
			return validarRetorno(retorno);
		})
		.catch(retorno => {
			return validarRetorno(retorno);
		});
	},
	logout: {
		get: async () => {
			return await api.get(`/login/logout`, config())
			.then(retorno => {
				return validarRetorno(retorno);
			})
			.catch(retorno => {
				return validarRetorno(retorno);
			});
		}
	},
};

export { apiLogin };