import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FormNavio.css';

// Routes
import { _API_SELECT_STATUS_NAVIOS } from "@config/_tempSelect";
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";

const FormNavio = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isCadastro,
		stateDados,
		setStateDados,
		after,
		statePaises,
		loadingGetPaises,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="flex flex-wrap">
						<div className="input-box box-3 box-1-780">
							<Input
								label="Nome"
								req
								_={{
									value: stateDados.name,
									onChange: e => setStateDados({
										...stateDados,
										name: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Select
								label="Status do navio"
								req
								_={{
									value: stateDados.status,
									onChange: e => setStateDados({
										...stateDados,
										status: e.value,
									}),
									options: _API_SELECT_STATUS_NAVIOS,
								}}
								wrap="flex-1"
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Select
								label="Flag"
								req
								_={{
									value: stateDados.id_country,
									onChange: e => setStateDados({
										...stateDados,
										id_country: e.value,
									}),
									options: statePaises,
								}}
								wrap="flex-1"
								loading={loadingGetPaises}
							/>
						</div>
						<div className="input-box box-1">
							<Input
								label="Descrição"
								_={{
									type: 'textarea',
									value: stateDados.description,
									onChange: e => setStateDados({
										...stateDados,
										description: e.target.value,
									}),
								}}
							/>
						</div>

						<div className="input-box box-3 box-1-780">
							<Input
								label="DWT"
								tip="Deadweight"
								req
								_={{
									value: stateDados.deadweight,
									onChange: e => setStateDados({
										...stateDados,
										deadweight: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="GRT"
								tip="Gross Register Tonnage"
								req
								_={{
									value: stateDados.gross_register_tonnage,
									onChange: e => setStateDados({
										...stateDados,
										gross_register_tonnage: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-3 box-1-780">
							<Input
								label="LOA"
								tip="Length Overall"
								req
								_={{
									value: stateDados.length_overall,
									onChange: e => setStateDados({
										...stateDados,
										length_overall: e.target.value,
									}),
								}}
							/>
						</div>
					</div>
					{!isCadastro ? (
						<div className="input-box box-1">
							<Checkbox
								label="Status"
								_={{
									checked: stateDados.active,
									onChange: e => {
										setStateDados({
											...stateDados,
											active: e.target.checked ? 1 : 0,
										});
									},
								}}
								classNameWrap="padding-t-15"
							/>
						</div>
					) : null}
				</div>

				{after}
			</form>
		</>
	);
};

export default FormNavio;