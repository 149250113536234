import React, { useContext } from 'react';

// Store
import StoreContext from '@context/StoreContext';

// Config
import { TITULO_PAGINA_DEFAULT, TITULO_PAGINA_PREFIXO } from '@config/config';
import MainMenu from "@cpnt/MainMenu/MainMenu";
import { Topbar } from "@cpnt/Topbar/Topbar";

const LayoutPainel = (props) => {

	const [store, setStore] = useContext(StoreContext);

	var titulo = TITULO_PAGINA_DEFAULT;
	if(props.meta.title) {
		titulo = `${TITULO_PAGINA_PREFIXO}${props.meta.title}`;
	}

	window.document.title = titulo;

	const Cpnt = props.component;

	return (
		<div className={`lt-painel`}>
			<Topbar {...props} />
			<MainMenu {...props} />
			<div className={`lt-painel-conteudo-container`}>
				<Cpnt {...props} />
			</div>
		</div>
	);
}

export default LayoutPainel;
