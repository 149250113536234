import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import './FormPDA.css';

// Routes
import { _API_SELECT_STATUS_NAVIOS } from "@config/_tempSelect";
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";
import PortalEmpresasFormCadastro from "@cpnt/Portais/PortaisEmpresas/PortalEmpresasFormCadastro/PortalEmpresasFormCadastro";
import PortalNaviosFormCadastro from "@cpnt/Portais/PortaisNavios/PortalNaviosFormCadastro/PortalNaviosFormCadastro";
import PortalPortosFormCadastro from "@cpnt/Portais/PortaisPortos/PortalPortosFormCadastro/PortalPortosFormCadastro";
import PortalCargosFormCadastro from "@cpnt/Portais/PortaisCargos/PortalCargosFormCadastro/PortalCargosFormCadastro";
import { apenasNumeros } from "@func/regex";
import PortalBercoFormCadastro from "@cpnt/Portais/PortaisBercos/PortalBercoFormCadastro/PortalBercoFormCadastro";

const FormPDA = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isCadastro,
		stateDados,
		setStateDados,
		after,

		loadingGetEmpresas,
		loadingGetPortos,
		loadingGetNavios,
		loadingGetTiposOperacoes,
		loadingGetCargos,
		loadingGetBercos,

		stateEmpresas,
		statePortos,
		stateNavios,
		stateTiposOperacoes,
		stateCargos,
		stateBercos,

		getCompanies,
		getPorts,
		getVessels,
		getOperationsType,
		getCargos,
		getBercos,
	} = props;

	/*
	** Portais
	** ================================================== */
	const portaisDefault = {
		cadastroEmpresa: {},
		cadastroPorto: {},
		cadastroNavio: {},
		cadastroCargo: {},
		cadastroBerco: {},
	}
	const [portais, setPortais] = useState(portaisDefault);

	return (
		<>
			<form onSubmit={onSubmit}>
				<h3 className="margin-b-10 margin-t-0">Informações básicas</h3>
				<div className="inputs-box flex flex-wrap">
					<div className="input-box box-3 box-1-980">
						<Select
							label="Nome da empresa"
							req
							_={{
								value: stateDados.id_company,
								onChange: e => setStateDados({
									...stateDados,
									id_company: e.value,
								}),
								options: stateEmpresas,
							}}
							wrap="flex-1"
							btnAdd
							onClickBtnAdd={e => {
								setPortais({
									...portais,
									cadastroEmpresa: {
										show: true,
									}
								})
							}}
							loading={loadingGetEmpresas}
						/>
					</div>
				</div>

				<h3 className="margin-b-10">Informações do navio</h3>
				<div className="inputs-box flex flex-wrap">
					<div className="input-box box-3 box-1-780">
						<Select
							label="Porto"
							req
							_={{
								value: stateDados.id_port,
								onChange: e => {
									setStateDados({
										...stateDados,
										id_port: e.value,
										_asdfg_: e.value,
									});
									getBercos(e.value);
								},
								options: statePortos,
							}}
							wrap="flex-1"
							btnAdd
							onClickBtnAdd={e => {
								setPortais({
									...portais,
									cadastroPorto: {
										show: true,
									}
								})
							}}
							loading={loadingGetPortos}
						/>
					</div>
					<div className="input-box box-3 box-1-780">
						<Select
							label={
								<>
									<span>Navio</span>
									{stateDados.flag && <span className="margin-l-5">(<strong>Flag:</strong> {stateDados.flag})</span>}
								</>
							}
							req
							_={{
								value: stateDados.id_vessel,
								onChange: e => {
									setStateDados({
										...stateDados,
										id_vessel: e.value,
										deadweight: e.deadweight,
										gross_register_tonnage: e.gross_register_tonnage,
										length_overall: e.length_overall,
										flag: e.flag,
									})
								},
								options: stateNavios,
							}}
							wrap="flex-1"
							btnAdd
							onClickBtnAdd={e => {
								setPortais({
									...portais,
									cadastroNavio: {
										show: true,
									}
								})
							}}
							loading={loadingGetNavios}
						/>
					</div>
					<div className="input-box box-3 box-1-780">
						<Input
							label="Dias atracados"
							_={{
								value: stateDados.days_inner_road,
								onChange: e => setStateDados({
									...stateDados,
									days_inner_road: apenasNumeros(e.target.value),
								}),
							}}
						/>
					</div>
					<div className="input-box w-25p box-1-780">
						<Input
							label="Dias fundeados"
							_={{
								value: stateDados.days_alongside,
								onChange: e => setStateDados({
									...stateDados,
									days_alongside: apenasNumeros(e.target.value),
								}),
							}}
						/>
					</div>
					<div className="input-box w-25p box-1-780">
						<Input
							label="DWT"
							tip="Deadweight"
							_={{
								value: stateDados.deadweight,
								onChange: e => setStateDados({
									...stateDados,
									deadweight: apenasNumeros(e.target.value),
								}),
							}}
						/>
					</div>
					<div className="input-box w-25p box-1-780">
						<Input
							label="GRT"
							tip="Gross Register Tonnage"
							_={{
								value: stateDados.gross_register_tonnage,
								onChange: e => setStateDados({
									...stateDados,
									gross_register_tonnage: apenasNumeros(e.target.value),
								}),
							}}
						/>
					</div>
					<div className="input-box w-25p box-1-780">
						<Input
							label="LOA"
							tip="Length Overall"
							_={{
								value: stateDados.length_overall,
								onChange: e => setStateDados({
									...stateDados,
									length_overall: apenasNumeros(e.target.value),
								}),
							}}
						/>
					</div>
				</div>

				<h3 className="margin-b-10">Informações da carga</h3>
				<div className="inputs-box flex flex-wrap">
					<div className="input-box w-33p box-1-780">
						<Select
							label="Berço"
							req
							tip="A lista de berço é carregada de acordo com o Porto selecionado."
							_={{
								value: stateDados.id_berth,
								onChange: e => setStateDados({
									...stateDados,
									id_berth: e.value,
								}),
								options: stateBercos,
							}}
							wrap="flex-1"
							btnAdd
							onClickBtnAdd={e => {
								setPortais({
									...portais,
									cadastroBerco: {
										show: true,
									}
								})
							}}
							loading={loadingGetBercos}
						/>
					</div>
					<div className="input-box w-66p box-1-780">
						<Select
							label={
								<>
									<span>Nome do cargo</span>
									{stateDados.tipo_cargo && <span className="margin-l-5">(<strong>Tipo de cargo/commodity:</strong> {stateDados.tipo_cargo})</span>}
								</>
							}
							req
							_={{
								value: stateDados.id_cargo,
								onChange: e => {
									setStateDados({
										...stateDados,
										id_cargo: e.value,
										tipo_cargo: e.type.code,
									})
								},
								options: stateCargos,
							}}
							wrap="flex-1"
							btnAdd
							onClickBtnAdd={e => {
								setPortais({
									...portais,
									cadastroCargo: {
										show: true,
									}
								})
							}}
							loading={loadingGetCargos}
						/>
					</div>
					<div className="input-box box-3 box-1-780">
						<Select
							label="Operação"
							req
							_={{
								value: stateDados.operation_type,
								onChange: e => setStateDados({
									...stateDados,
									operation_type: e.value,
								}),
								options: stateTiposOperacoes,
							}}
							wrap="flex-1"
							loading={loadingGetTiposOperacoes}
						/>
					</div>
					<div className="input-box box-3 box-1-780">
						<Input
							label="Quantidade (t)"
							_={{
								value: stateDados.quantity,
								onChange: e => setStateDados({
									...stateDados,
									quantity: apenasNumeros(e.target.value),
								}),
							}}
						/>
					</div>
					<div className="input-box box-3 box-1-780">
						<Input
							label="Rate USD"
							_={{
								value: stateDados.rate_usd,
								onChange: e => setStateDados({
									...stateDados,
									rate_usd: e,
								}),
							}}
							dinheiro
						/>
					</div>
				</div>

				{after}
			</form>

			<PortalEmpresasFormCadastro
				show={portais.cadastroEmpresa.show}
				onClose={() => {
					setPortais({...portais, cadastroEmpresa: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastroEmpresa: {}});
					getCompanies();
					setStateDados({
						...stateDados,
						id_company: e.ret.data.id,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalPortosFormCadastro
				show={portais.cadastroPorto.show}
				onClose={() => {
					setPortais({...portais, cadastroPorto: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastroPorto: {}});
					getPorts();
					setStateDados({
						...stateDados,
						id_port: e.ret.data.id,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalNaviosFormCadastro
				show={portais.cadastroNavio.show}
				onClose={() => {
					setPortais({...portais, cadastroNavio: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastroNavio: {}});
					getVessels();
					setStateDados({
						...stateDados,
						id_vessel: e.ret.data.id,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalCargosFormCadastro
				show={portais.cadastroCargo.show}
				onClose={() => {
					setPortais({...portais, cadastroCargo: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastroCargo: {}});
					getCargos();
					setStateDados({
						...stateDados,
						id_cargo: e.ret.data.id,
					});
				}}
				closeOnEsc={false}
			/>

			<PortalBercoFormCadastro
				show={portais.cadastroBerco.show}
				onClose={() => {
					setPortais({...portais, cadastroBerco: {}});
				}}
				onSuccess={e => {
					setPortais({...portais, cadastroBerco: {}});
					setStateDados({
						...stateDados,
						id_berth: e.ret.data.id,
					});
					getBercos(stateDados.id_port);
				}}
				closeOnEsc={false}
			/>
		</>
	);
};

export default FormPDA;