// https://echarts.apache.org/examples/en/index.html
// https://git.hust.cc/echarts-for-react/

export const GRAFICO_BAR_CONFIG = {
	tooltip: {
		trigger: 'axis',
		axisPointer: {
			type: 'cross'
		},
		formatter: e => {
			return `
			<div>
				<strong>${e[0].name}:</strong>
				<span className="margin-l-5">${e[0].value}</span>
			</div>
			`;
		}
	},
	legend: {
		data: ['Linha', 'Barras'],
		bottom: 0,
	},
	toolbox: {
		show: true,
		orient: 'vertical',
		left: 'right',
		top: 'center',
		feature: {
			mark: { show: true },
			saveAsImage: { show: true },
			restore: { show: true },
		}
	},
	grid: { // Box completo
		left: '0',
		right: '40',
		bottom: '50',
		containLabel: true // Exibe o título abaixo da barra
	},
	xAxis: { // Linha de baixo após o gráfico
		type: 'category',
		data: [],
		axisTick: {
			alignWithLabel: true
		},
		splitLine: { show: true }, // Linhas dividindo as colunas
	},
	yAxis: { // Linha da lateral antes do gráfico
		type: 'value',
		splitLine: { show: true },
	},
	series: [
		{
			name: 'Linha',
			data: [],
			type: 'line',
			smooth: true,
			lineStyle: {
				color: '#000',
				width: 1
			},
		},
		{
			name: 'Barras',
			data: [],
			type: 'bar',
			barWidth: '30px',
			barGap: 2,
			smooth: true,
			label: {
				show: true,
				position: 'insideBottom',
				distance: 10,
				rotate: 90,
				formatter: e => {
					return e.name;
				},
				fontSize: 12,
				rich: {
					name: {}
				},
				align: {
					options: {
						left: 'left',
						center: 'center',
						right: 'right'
					}
				},
				verticalAlign: {
					options: {
						top: 'top',
						middle: 'middle',
						bottom: 'bottom'
					}
				},
			},
			showBackground: true,
			backgroundStyle: {
				color: 'rgba(180, 180, 180, 0.05)'
			},
		},
	],
	dataZoom: [
		{
			type: 'slider',
			realtime: false,
			top: 5,
			left: 15,
			right: 15,
			height: 20,
			handleIcon: 'path://M10.7,11.9H9.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4h1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
			handleSize: '130%'
		},
		{
			type: 'inside',
			zoomOnMouseWheel: false,
			moveOnMouseMove: false,
		},
	],
};

export const GRAFICO_PIE2_CONFIG = {
	tooltip: {
		trigger: 'item',
	},
	legend: {
		type: 'scroll',
		orient: 'vertical',
		right: 10,
		top: 20,
		bottom: 20,
		data: [],
	},
	series: [
		{
			name: 'Access From',
			type: 'pie',
			radius: ['50%', '86%'],
			center: ['19%', '50%'],
			data: [],
			legend: {
				type: 'scroll',
				orient: 'vertical',
				left: 10,
				top: 20,
				bottom: 20,
				data: [],
			},
		},
	]
};

export const GRAFICO_PIE_CONFIG = {
	tooltip: {
		trigger: 'item',
	},
	legend: {
		type: 'scroll',
		orient: 'vertical',
		right: 10,
		top: 20,
		bottom: 20,
		data: [],
	},
	series: [
		{
			type: 'pie',
			radius: '86%',
			center: ['19%', '50%'],
			data: [],
		},
	]
};

export const formatarDadosGraficoBar = (dados = []) => {
	var dadosOk = [];
	var nomesOk = [];

	if(dados && typeof(dados) === 'object') {
		dadosOk = dados.map(item => item.total);
		nomesOk = dados.map(item => item.filed && item.filed !== '' ? item.filed : '[não informado]');
	}

	var retorno = {
		...GRAFICO_BAR_CONFIG,
		xAxis: {
			...GRAFICO_BAR_CONFIG.xAxis,
			data: nomesOk,
		},
		series: [
			{
				...GRAFICO_BAR_CONFIG.series[0],
				data: dadosOk,
			},
			{
				...GRAFICO_BAR_CONFIG.series[1],
				data: dadosOk,
			},
		]
	}

	return retorno;
}

export const formatarDadosGraficoPie = (dados = []) => {
	var dadosOk = [];
	var nomesOk = [];

	if(dados && typeof(dados) === 'object') {
		dadosOk = dados.map(item => {
			return {
				name: item.filed !== '' ? item.filed : '[não informado]',
				value: item.total,
			}
		});
		nomesOk = dados.map(item => item.filed && item.filed !== '' ? item.filed : '[não informado]');
	}

	var retorno = {
		...GRAFICO_PIE_CONFIG,
		legend: {
			...GRAFICO_PIE_CONFIG.legend,
			data: nomesOk,
		},
		series: [
			{
				...GRAFICO_PIE_CONFIG.series[0],
				data: dadosOk,
			},
		]
	}

	return retorno;
}

export const formatarDadosGraficoPie2 = (dados = []) => {
	var dadosOk = [];
	var nomesOk = [];

	if(dados && typeof(dados) === 'object') {
		dadosOk = dados.map(item => {
			return {
				name: item.filed !== '' ? item.filed : '[não informado]',
				value: item.total,
			}
		});
		nomesOk = dados.map(item => item.filed && item.filed !== '' ? item.filed : '[não informado]');
	}

	var retorno = {
		...GRAFICO_PIE2_CONFIG,
		legend: {
			...GRAFICO_PIE2_CONFIG.legend,
			data: nomesOk,
		},
		series: [
			{
				...GRAFICO_PIE2_CONFIG.series[0],
				data: dadosOk,
			},
		]
	}

	return retorno;
}