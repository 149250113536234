import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// css
import c from './FormPorto.module.css';

// Routes
import Checkbox from "@cpnt/Checkbox/Checkbox";
import Input from "@cpnt/Input/Input";
import { Select } from "@cpnt/Select/Select";
import InputFile from "@cpnt/InputFile/InputFile";

const FormPorto = props => {

	const navigate = useNavigate();
	const location = useLocation();

	const {
		onSubmit,
		isCadastro,
		stateDados,
		setStateDados,
		after,
		stateEstados,
		loadingGetEstados,
		stateFoto,
		setStateFoto,
	} = props;

	return (
		<>
			<form onSubmit={onSubmit}>
				<div className="wrap">
					<div className="flex flex-wrap">
						<div className="input-box box-1">
							<Input
								label="Nome do porto"
								req
								_={{
									value: stateDados.name,
									onChange: e => setStateDados({
										...stateDados,
										name: e.target.value,
									}),
								}}
							/>
						</div>
						<div className="input-box box-1">
							<Select
								label="Localização"
								req
								_={{
									value: stateDados.id_state,
									onChange: e => setStateDados({
										...stateDados,
										id_state: e.value,
									}),
									options: stateEstados,
								}}
								wrap="flex-1"
								loading={loadingGetEstados}
							/>
						</div>
						<div className="input-box box-1">
							<Input
								label="Descrição"
								_={{
									type: 'textarea',
									value: stateDados.description,
									onChange: e => setStateDados({
										...stateDados,
										description: e.target.value,
									}),
								}}
							/>
						</div>
						<div className={`input-box box-1 ${c['box-preview']}`}>
							{typeof(stateFoto) === 'string' ? (
								<div className={c['box-preview-img']}>
									<img src={stateFoto} alt="imagem" />
								</div>
							) : null}
							<InputFile
								label="Foto do porto"
								value={stateFoto}
								setValue={setStateFoto}
							/>
						</div>
					</div>
					{!isCadastro ? (
						<div className="input-box box-1">
							<Checkbox
								label="Status"
								_={{
									checked: stateDados.active,
									onChange: e => {
										setStateDados({
											...stateDados,
											active: e.target.checked ? 1 : 0,
										});
									},
								}}
								classNameWrap="padding-t-15"
							/>
						</div>
					) : null}
				</div>

				{after}
			</form>
		</>
	);
};

export default FormPorto;