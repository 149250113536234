import React from 'react';

// css
import './FiltrosContainer.css';

const FiltrosContainer = props => {
	const {
		className = '',
		classNameWrap = '',
		wrap = '',
		children,
	} = props;

	return (
		<>
			<div className={`cpnt-filtroscontainer`}>
				<div className="base">
					<div className="base-content">
						<div className="cpnt-filtroscontainer-inner">
							{children}
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export { FiltrosContainer };